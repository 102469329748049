const COMPONENT = 'SIGNUP';

const signupActions = {
  CHANGE_PROPERTY: `${COMPONENT}CHANGE_PROPERTY`,
  GET_TOKEN_REQUEST: `${COMPONENT}GET_TOKEN_REQUEST`,
  GET_TOKEN_SUCCESS: `${COMPONENT}GET_TOKEN_SUCCESS`,
  GET_TOKEN_ERROR: `${COMPONENT}GET_TOKEN_ERROR`,
  TOUCH: `${COMPONENT}TOUCH`,
  CLEAR_STATE: `${COMPONENT}CLEAR_STATE`,
  SWITCH_VIEW: 'SWITCH_VIEW',
  LOAD_SCREEN: 'LOAD_SCREEN',
  SIGNUP_REQUEST: 'SIGNUP_REQUEST',
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
  SIGNIN_START: 'SIGNIN_START',
  SIGNUP_ERROR: 'SIGNUP_ERROR',

  changeProperty: data => ({
    type: signupActions.CHANGE_PROPERTY,
    payload: data,
  }),
  switchView: () => ({
    type: signupActions.SWITCH_VIEW,
  }),
  loadScreen: () => ({
    type: signupActions.LOAD_SCREEN,
  }),
  touch: data => ({
    type: signupActions.TOUCH,
    payload: data,
  }),
  getToken: data => ({
    type: signupActions.GET_TOKEN_REQUEST,
    payload: data,
  }),
  getTokenSuccess: data => ({
    type: signupActions.GET_TOKEN_SUCCESS,
    payload: data,
  }),
  getTokenError: data => ({
    type: signupActions.GET_TOKEN_ERROR,
    payload: data,
  }),
  signup: data => ({
    type: signupActions.SIGNUP_REQUEST,
    payload: data,
  }),
  signupSuccess: data => ({
    type: signupActions.SIGNUP_SUCCESS,
    payload: data,
  }),
  signinStart: data => ({
    type: signupActions.SIGNIN_START,
    payload: data,
  }),
  signupError: data => ({
    type: signupActions.SIGNUP_ERROR,
    payload: data,
  }),

  clearState: () => ({
    type: signupActions.CLEAR_STATE,
  }),
};
export default signupActions;
