const COMPONENT = 'PRINT_VIEW_';

const actions = {
  LOAD_RISK_REQUEST: `${COMPONENT}LOAD_RISK_REQUEST`,
  LOAD_RISK_SUCCESS: `${COMPONENT}LOAD_RISK_SUCCESS`,
  LOAD_UCRT_REQUEST: `${COMPONENT}LOAD_UCRT_REQUEST`,
  CLEAR: `${COMPONENT}CLEAR`,

  loadRiskRequest: data => ({
    type: actions.LOAD_RISK_REQUEST,
    payload: data,
  }),

  loadUCRTRequest: data => ({
    type: actions.LOAD_UCRT_REQUEST,
    payload: data,
  }),

  loadRiskSuccess: data => ({
    type: actions.LOAD_RISK_SUCCESS,
    payload: data,
  }),

  clear: () => ({
    type: actions.CLEAR,
  }),
};
export default actions;
