export function saveAuthData(data) {
  localStorage.setItem('auth', JSON.stringify(data));
}

export function clearAuthData() {
  localStorage.clear();
}

export function getAuthData() {
  try {
    const auth = JSON.parse(localStorage.getItem('auth'));
    return auth;
  } catch (err) {
    clearAuthData();
    return new Map();
  }
}
