import { all, takeEvery, put } from 'redux-saga/effects';
import actions from './actions';
import RestApi from '../../helpers/api';

const api = new RestApi('quotes/stats');

export function* load() {
  try {
    const response = yield api.load();

    if (response.status === 'success') {
      const { data } = response;

      yield put(
        actions.loadSuccess({
          data,
        }),
      );
    } else if (response.status === 'error') {
      const { error } = response.data;

      yield put(actions.loadError(error));
    }
  } catch (error) {
    console.warn(error);
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD, load)]);
}
