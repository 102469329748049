import { all, put, takeEvery, fork } from 'redux-saga/effects';
import actions from './actions';
import RestApi, { callApi } from '../../helpers/api';

const api = new RestApi('risk-tracker/crt-item');
const apiCSG = new RestApi('risk-tracker/crt-sentiment-group');

const apiItem = new RestApi('risk-tracker/ucrt-item');
const apiUCRT = new RestApi('risk-tracker/ucrt-sentiment-group');
const apiUsersShare = new RestApi('risk-tracker/users-for-share');

export function* loadRiskRequest() {
  yield takeEvery(actions.LOAD_RISK_REQUEST, function*({ payload }) {
    const { reportId, date, companyId, isEarly = null } = payload;

    try {
      let response;
      let response1;

      if (reportId && date) {
        response = yield api.load({
          reportId,
          date,
          companyId,
        });

        response1 = yield apiCSG.load({
          reportId,
          date,
          companyId,
        });
      } else {
        response = yield apiItem.load({
          reportId,
        });

        response1 = yield apiUCRT.load({
          reportId,
        });
      }

      if (response.status === 'success' && response1.status === 'success') {
        const {
          executiveSummary,
          companyProviderName,
          userProviderId,
          companyProviderId,
          reportName,
          reportDate,
          publicationDate,
          isPublished,
          createdBy,
          isPrivate,
          scores = null,
          scores_alt = null,
        } = response.data;

        yield put(
          actions.loadRiskSuccess({
            isPublished,
            executiveSummary,
            companyProviderName,
            userProviderId,
            companyProviderId,
            reportName,
            publicationDate,
            reportDate,
            createdBy,
            isPrivate,
            dateScores: isEarly ? scores_alt : scores,
            data: Object.entries(response1.data).map(el => ({ ...el[1], id: el[0] })),
          }),
        );
      } else if (response.status === 'error') {
        console.log('loadRiskRequest response status: error');
      }
    } catch (e) {
      console.log('loadRiskRequest error', e);
    }
  });
}

export function* loadShareListRequest() {
  yield takeEvery(actions.LOAD_SHARE_LIST_REQUEST, function*({ payload }) {
    const { reportId } = payload;

    try {
      const response = yield apiUsersShare.load({
        reportId,
      });

      if (response.status === 'success') {
        yield put(
          actions.loadShareListSuccess({
            shareList: response.data,
          }),
        );
      } else if (response.status === 'error') {
        console.log('loadRiskRequest response status: error');
      }
    } catch (e) {
      console.log('loadRiskRequest error', e);
    }
  });
}

export function* updateShareListRequest() {
  yield takeEvery(actions.UPDATE_SHARE_LIST_REQUEST, function*({ payload }) {
    const { shareList, reportId } = payload;

    try {
      const response = yield callApi(`risk-tracker/share-ucrt-report?reportId=${reportId}`, 'PATCH', {
        shareList,
      });

      if (response.status === 'success') {
        yield put(actions.updateShareListSuccess());
      }
    } catch (e) {
      console.log('publishRiskRequest', e);
    }
  });
}

export function* publishRiskRequest() {
  yield takeEvery(actions.PUBLISH_RISK_REQUEST, function*({ payload }) {
    const { data, executiveSummary, reportName, reportId, isEarly = null } = payload;

    const validationFaild = yield put(function() {
      let dataValidation = false;

      if (!reportName) {
        console.log('reportName');
        dataValidation = true;
        return dataValidation;
      }

      if (!executiveSummary) {
        console.log('executiveSummary');
        dataValidation = true;
        return dataValidation;
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const item of data) {
        if (!isEarly) {
          if (!item.historicalOverview) {
            console.log('historicalOverview');
            dataValidation = true;
            break;
          }
        }

        if (!item.risk || item.risk === '') {
          console.log('risk status');
          dataValidation = true;
          break;
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const card of item.cards) {
          const empty = Object.values(card).some(el => el === '');
          if (empty) {
            console.log('cards');
            dataValidation = true;
            break;
          }
        }
        if (!isEarly) {
          // eslint-disable-next-line no-restricted-syntax
          for (const pie of item.pies) {
            if (pie.negative.comments === '' || pie.positive.comments === '' || pie.neutral.comments === '') {
              console.log('pie');
              dataValidation = true;
              break;
            }
          }
        }
      }

      return dataValidation;
    });

    if (!validationFaild) {
      try {
        yield put(actions.loading({ publishing: true }));

        const response = yield callApi(`risk-tracker/update-ucrt-item-status?reportId=${reportId}`, 'PATCH', {
          isPublished: true,
          isEarlyData: isEarly,
        });

        if (response.status === 'success') {
          yield put(actions.publishRiskSuccess({ validation: false }));
        }
      } catch (e) {
        console.log('publishRiskRequest', e);
      }
    } else if (validationFaild) {
      yield put(actions.publishRiskValidation({ validation: validationFaild }));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(loadRiskRequest),
    fork(publishRiskRequest),
    fork(loadShareListRequest),
    fork(updateShareListRequest),
  ]);
}
