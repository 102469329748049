import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { db } from '../redux/firestore';
import { getAuthData } from '../helpers/auth';

const useVisibilityCheck = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { role, companyId } = useSelector(({ Auth: { claims } }) => claims || {});

  useEffect(() => {
    const { claims } = getAuthData();
    const { companyId } = claims;

    if (!companyId) {
      setError(new Error('Company ID not found in localStorage'));
      setLoading(false);
      return;
    }

    const planDocPath = `/companies/${companyId}/license/outcomes_report`;

    const unsubscribe = db.doc(planDocPath).onSnapshot(
      doc => {
        const data = doc.data();

        if (data && data.isVisible !== undefined) {
          setIsVisible(data.isVisible);
        } else {
          setIsVisible(false);
        }
        setLoading(false);
      },
      err => {
        setError(err);
        setLoading(false);
      },
    );

    // Cleanup listener when component unmounts
    return () => unsubscribe();
  }, [role, companyId]);

  return { isVisible, loading, error };
};

export default useVisibilityCheck;
