import { useSelector } from 'react-redux';

const useCompanyRole = () => {
  const claims = useSelector(state => state.Auth.claims || {});
  const { company_role = 'user' } = claims;

  return company_role;
};

export default useCompanyRole;
