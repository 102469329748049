import profileActions from './actions';
import { createReducer } from '../../helpers/reducer';

// ------------------------------
// Initial State
// ------------------------------
const initState = {
  risk: undefined,
  executiveSummary: undefined,
  risks: null,
  customRisks: null,
  data: null,
  reportsList: null,
  provReportsList: null,
  currentVal: { value: 0, previous: 0 },
  currentCustomVal: { value: 0, previous: 0 },
  analysis: null,
  annieAnswer: '',
  annieLoading: 'wait',
  loading: false,
  analysisLoading: false,

  swotDetails: null,
  swotRecommendations: null,
  swotReadiness: null,
  condition: 'wait',
  isIndexed: false,
  creditsStay: '-',
  creditsLimit: '-',
  buyCreditsProcess: 'wait',
};

// ------------------------------
// Action Handlers
// ------------------------------
function onLoadRiskSuccess(state, { payload }) {
  const { data, executiveSummary, provReportsList } = payload;

  return {
    ...state,
    data,
    provReportsList,
    executiveSummary,
  };
}

function onLoadAnalysisRequest(state) {
  return {
    ...state,
    analysisLoading: true,
  };
}
function onLoadSwotSuccess(state, { payload }) {
  const { swotDetails, swotReadiness, swotRecommendations, condition, isIndexed, creditsStay, creditsLimit } = payload;

  return {
    ...state,
    swotDetails,
    swotReadiness,
    swotRecommendations,
    condition,
    creditsStay,
    creditsLimit,
    isIndexed,
  };
}
function onLoadSwotError(state) {
  return {
    ...state,
  };
}

function onLoadAnalysisSuccess(state, { payload }) {
  const { analysis } = payload;

  return {
    ...state,
    analysis,
    analysisLoading: false,
  };
}

function onLoadAnnieRequest(state) {
  return {
    ...state,
    annieLoading: true,
    annieAnswer: '',
  };
}

function onLoadAnnieSuccess(state, { payload }) {
  const { annieAnswer } = payload;

  return {
    ...state,
    annieAnswer,
    annieLoading: false,
  };
}

function onLoadBuyCreditRequest(state) {
  return {
    ...state,
    buyCreditsProcess: 'pending',
  };
}

function onLoadBuyCreditSuccess(state) {
  return {
    ...state,
    buyCreditsProcess: 'success',
  };
}

function onLoadTimelineSuccess(state, { payload }) {
  const { risks } = payload;

  return {
    ...state,
    risks,
  };
}

function onloadCustomListRiskSuccess(state, { payload }) {
  const { reportsList } = payload;

  return {
    ...state,
    reportsList,
  };
}

function onLoadCustomTimelineSuccess(state, { payload }) {
  const { customRisks } = payload;

  return {
    ...state,
    customRisks: customRisks || null,
  };
}

function onSetTimeLine(state, { payload }) {
  return {
    ...state,
    currentVal: payload,
  };
}

function onSetCustomTimeLine(state, { payload }) {
  return {
    ...state,
    currentCustomVal: payload,
  };
}

function onClear(state) {
  return {
    ...state,
    annieAnswer: '',
    annieLoading: 'wait',
    buyCreditsProcess: 'wait',
  };
}

// ------------------------------
// Exported reducer function.
// ------------------------------
export default createReducer(initState, {
  [profileActions.CLEAR]: onClear,

  [profileActions.LOAD_ANNIE_SUCCESS]: onLoadAnnieSuccess,
  [profileActions.LOAD_ANNIE_REQUEST]: onLoadAnnieRequest,

  [profileActions.LOAD_BUY_CREDIT_SUCCESS]: onLoadBuyCreditSuccess,
  [profileActions.LOAD_BUY_CREDIT_REQUEST]: onLoadBuyCreditRequest,

  [profileActions.LOAD_SWOT_SUCCESS]: onLoadSwotSuccess,
  [profileActions.LOAD_SWOT_ERROR]: onLoadSwotError,

  [profileActions.LOAD_ANALYSIS_REQUEST]: onLoadAnalysisRequest,
  [profileActions.LOAD_ANALYSIS_SUCCESS]: onLoadAnalysisSuccess,

  [profileActions.LOAD_RISK_SUCCESS]: onLoadRiskSuccess,
  [profileActions.LOAD_TIMELINE_SUCCESS]: onLoadTimelineSuccess,
  [profileActions.LOAD_CUSTOM_TIMELINE_SUCCESS]: onLoadCustomTimelineSuccess,

  [profileActions.SET_TIME_LINE]: onSetTimeLine,
  [profileActions.SET_CUSTOM_TIME_LINE]: onSetCustomTimeLine,
  [profileActions.LOAD_CUSTOM_LIST_RISK_SUCCESS]: onloadCustomListRiskSuccess,
});
