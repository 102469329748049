const COMPONENT = 'NEW_PRODUCT';

const actions = {
  LOAD_PRODUCTS_REQUEST: `${COMPONENT}LOAD_PRODUCTS_REQUEST`,
  LOAD_PRODUCTS_SUCCESS: `${COMPONENT}LOAD_PRODUCTS_SUCCESS`,
  LOAD_PRODUCTS_ERROR: `${COMPONENT}LOAD_PRODUCTS_ERROR`,
  CHANGE_PROPERTY: `${COMPONENT}CHANGE_PROPERTY`,
  CLEAR_SERVICE_STATE: `${COMPONENT}CLEAR_SERVICE_STATE`,
  SEND_SERVICE: `${COMPONENT}SEND_SERVICE`,
  FILE_UPLOADING: `${COMPONENT}FILE_UPLOADING`,
  FILE_UPLOAD_ERROR: `${COMPONENT}FILE_UPLOAD_ERROR`,
  FILE_UPLOAD_SUCCESS: `${COMPONENT}FILE_UPLOAD_SUCCESS`,
  SEND_LINK: `${COMPONENT}SEND_LINK`,
  LOAD_ATTACHMENTS: `${COMPONENT}LOAD_ATTACHMENTS`,
  LOAD_ATTACHMENTS_SUCCESS: `${COMPONENT}LOAD_ATTACHMENTS_SUCCESS`,
  LOAD_ATTACHMENTS_ERROR: `${COMPONENT}LOAD_ATTACHMENTS_ERROR`,
  DELETE_ATTACHMENT: `${COMPONENT}DELETE_ATTACHMENT`,

  loadProductsRequest: data => ({
    type: actions.LOAD_PRODUCTS_REQUEST,
    payload: data,
  }),
  loadProductsSuccess: data => ({
    type: actions.LOAD_PRODUCTS_SUCCESS,
    payload: data,
  }),

  loadProductsError: data => ({
    type: actions.LOAD_PRODUCTS_ERROR,
    payload: data,
  }),

  changeProperty: data => ({
    type: actions.CHANGE_PROPERTY,
    payload: data,
  }),

  clearServiceState: () => ({
    type: actions.CLEAR_SERVICE_STATE,
  }),

  sendService: data => ({
    type: actions.SEND_SERVICE,
    payload: data,
  }),
  fileIsUploading: data => ({
    type: actions.FILE_UPLOADING,
    payload: data,
  }),

  fileUploadSuccess: data => ({
    type: actions.FILE_UPLOAD_SUCCESS,
    payload: data,
  }),

  fileUploadError: data => ({
    type: actions.FILE_UPLOAD_ERROR,
    payload: data,
  }),

  sendLink: data => ({
    type: actions.SEND_LINK,
    payload: data,
  }),

  loadAttachmentsRequest: data => ({
    type: actions.LOAD_ATTACHMENTS,
    payload: data,
  }),

  loadAttachmentsSuccess: data => ({
    type: actions.LOAD_ATTACHMENTS_SUCCESS,
    payload: data,
  }),

  loadAttachmentsError: data => ({
    type: actions.LOAD_ATTACHMENTS_ERROR,
    payload: data,
  }),

  deleteAttachment: data => ({
    type: actions.DELETE_ATTACHMENT,
    payload: data,
  }),
};
export default actions;
