import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Layout } from 'antd';
import { NavLink, Link, useLocation, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { detect } from 'detect-browser';
import TopbarUser from './topbarUser';
import TopbarBarUser from './topbarUserMobile';
import TopbarWrapper from './topbar.style';
import themes from '../../settings/themes';
import { themeConfig } from '../../settings';
import actionsTopbar from './actions';
import Modal from '../../components/uielements/modal';
import useVisibilityCheck from '../../hooks/useVisibilityCheck';

const browser = detect();

const { Header } = Layout;
const customizedTheme = themes[themeConfig.theme];

const hrNavLinks = [
  { to: '/home', value: 'Home' },
  // { to: '/my-company', value: 'My Company' },
  // { to: '/providers/categories', value: 'My Providers' },
  // { to: '/requests', value: 'My Requests' },
  { to: '/content-packs', value: 'Smart Briefs' },
  { to: '/aniline-benchmark', value: 'Benchmark' },
  { to: '/market-research/companies/all', value: 'Market Research' },
];

const webexNavLinks = [{ to: '/aniline-benchmark', value: 'Benchmark' }];

const providerNavLinks = [
  { to: '/home', value: 'Home' },
  // { to: '/my-company', value: 'My Company' },
  { to: '/content-packs', value: 'Smart Briefs' },
  { to: '/aniline-benchmark', value: 'Benchmark' },
  { to: '/market-research/companies/all', value: 'Market Research' },
  // { to: '/lead-flow', value: 'Lead Flow' },
];

const Topbar = () => {
  const { role, companyId, email, name, user_id } = useSelector(({ Auth: { claims } }) => claims || {});
  const [isMobNavigOpen, setMobNavigOpen] = useState(false);
  const [isFeedbackModal, setFeedbackModal] = useState(false);
  // const { isEarly = localStorage.getItem('isEarly') } = useSelector(state => state.TopBar);
  const dispatch = useDispatch();
  const formikRef = useRef();

  const { logo } = themes.themedefault.assets;
  const history = useHistory();
  const location = useLocation();
  const { isVisible } = useVisibilityCheck();

  const { pathname } = location;
  const styling = {
    background: customizedTheme.backgroundColor,
    position: 'fixed',
    width: '100%',
    height: 70,
  };

  const chekLocation = page => {
    if (page === '/market-research/companies/all') {
      if (pathname.includes('/market-research/companies/')) {
        return 'active';
      }
    }

    return '';
  };

  const setBgColor = () => {
    let bgClass;

    switch (role) {
      case 'hr':
        bgClass = 'blue-bg main-topbar';
        break;
      case 'webex':
        bgClass = 'blue-bg main-topbar';
        break;
      default:
        bgClass = 'dark-bg main-topbar';
    }

    return bgClass;
  };

  const toggleNavig = () => {
    setMobNavigOpen(!isMobNavigOpen);
  };

  const goBack = () => {
    if (pathname.includes('company') || pathname.includes('score') || pathname.includes('webex-help')) {
      history.goBack();
    }
    if (pathname.includes('aniline-benchmark')) {
      history.push('/benchmark');
    }
  };

  let navLiks;

  switch (role) {
    case 'hr':
      navLiks = hrNavLinks;
      break;
    case 'webex':
      navLiks = webexNavLinks;
      break;
    default:
      navLiks = providerNavLinks;
  }

  useEffect(() => {
    window.Intercom('boot', {
      app_id: 'fqdtnga3',
      email,
      name,
      user_id,
      companyId,
    });
  }, []);

  useEffect(() => {
    if (isVisible) {
      const linkExists = navLiks.some(link => link.value === 'Insight Analytics');

      if (!linkExists) {
        navLiks.push({ to: '/company-report', value: 'Insight Analytics' });
      }
    } else if (!isVisible) {
      const linkExists = navLiks.some(link => link.value === 'Insight Analytics');
      if (linkExists) {
        navLiks.pop();
      }
    }
  }, [isVisible, providerNavLinks, navLiks]);

  const { isValidated } = useSelector(({ firestore: { data } }) => {
    const company = (data.company && data.company.id === companyId && data.company) || {};

    return {
      isValidated: company.isValidated,
    };
  });

  useEffect(() => {
    const linkExists = navLiks.some(link => link.value === 'My company');

    if (isValidated) {
      if (!linkExists) {
        navLiks.splice(1, 0, { to: '/my-company', value: 'My company' });
      }
    }
  }, [isValidated]);

  const onOpenModal = () => {
    // eslint-disable-next-line no-unused-expressions
    formikRef.current?.resetForm();
    setFeedbackModal(true);
  };

  const onCancelFeedback = () => {
    // eslint-disable-next-line no-unused-expressions
    setFeedbackModal(false);
  };

  return (
    <TopbarWrapper className={setBgColor()}>
      <div tabIndex={0} role="button" className="fixed-btn" onClick={() => onOpenModal()}>
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
          <path
            d="M17.5 23.75C22.214 23.75 24.5711 23.75 26.0355 22.2855C27.5 20.8211 27.5 18.464 27.5 13.75C27.5 9.03595 27.5 6.67894 26.0355 5.21446C24.5711 3.75 22.214 3.75 17.5 3.75H12.5C7.78595 3.75 5.42894 3.75 3.96446 5.21446C2.5 6.67894 2.5 9.03595 2.5 13.75C2.5 18.464 2.5 20.8211 3.96446 22.2855C4.78094 23.102 5.87486 23.4633 7.5 23.6231"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M15 9.375V14.375" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M15 18.1379L15.0125 18.124"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.5001 23.75C15.9544 23.75 14.2528 24.374 12.6983 25.1813C10.2016 26.4776 8.95318 27.1259 8.338 26.7129C7.72282 26.2998 7.83925 25.0189 8.07214 22.4571L8.12507 21.875"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <Modal className="feedback-modal" visible={isFeedbackModal} footer={false} onCancel={() => onCancelFeedback()}>
        <Formik
          initialValues={{ comment: '' }}
          innerRef={formikRef}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);

            dispatch(
              actionsTopbar.sendFeedback({
                companyId,
                url: window.location.href,
                feedbackText: values.comment,
                browser,
                email,
                role,
              }),
            );

            actions.resetForm();
            actions.setStatus(true);
            actions.setSubmitting(false);
          }}
          validationSchema={Yup.object().shape({
            comment: Yup.string()
              .min(3, 'Your comment must be at least 3 characters ')
              .max(350, 'The limit is reached 😃')
              .required('Please enter a comment'),
          })}
        >
          {props => {
            const { values, touched, errors, status, isSubmitting, handleChange, handleBlur, handleSubmit } = props;
            return (
              <form className="feedback-form" onSubmit={handleSubmit}>
                {!status ? (
                  <>
                    <p className="form-title">
                      <b>Please leave your feedback</b>
                      <svg onClick={onCancelFeedback} width="12" height="12" viewBox="0 0 12 12" fill="none">
                        <path
                          d="M6.00044 5.99996L11.243 11.2426M0.757812 11.2426L6.00044 5.99996L0.757812 11.2426ZM11.243 0.757324L6.00044 5.99996L11.243 0.757324ZM6.00044 5.99996L0.757812 0.757324L6.00044 5.99996Z"
                          stroke="#131415"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </p>

                    <label
                      className={`w-full relative mb-7 ${status && 'blur-sm'}`}
                      htmlFor="name"
                      style={{ display: 'block' }}
                    >
                      <textarea
                        id="comment"
                        maxLength={350}
                        placeholder="Please enter a comment"
                        disabled={status && true}
                        value={values.comment}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={errors.comment && touched.comment ? 'error-msg' : ''}
                      />
                      {errors.comment && touched.comment && <div className="error-text">{errors.comment}</div>}
                    </label>

                    <div className="btns-wrapper">
                      <button type="button" tabIndex={0} onClick={() => onCancelFeedback()} className="grey">
                        Cancel
                      </button>
                      <button type="submit" disabled={isSubmitting}>
                        Submit
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="feedback-sent">
                    <svg width="51" height="50" viewBox="0 0 51 50" fill="none">
                      <g clipPath="url(#clip0_2194_11834)">
                        <path
                          opacity="0.25"
                          d="M25.5 50C39.307 50 50.5 38.807 50.5 25C50.5 11.1929 39.307 0 25.5 0C11.6929 0 0.5 11.1929 0.5 25C0.5 38.807 11.6929 50 25.5 50Z"
                          fill="#4787FE"
                        />
                        <path
                          d="M19.4927 25.0001L23.4928 29.0002L31.5072 21"
                          stroke="#4787FE"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2194_11834">
                          <rect width="50" height="50" fill="white" transform="translate(0.5)" />
                        </clipPath>
                      </defs>
                    </svg>

                    <p className="form-title" style={{ justifyContent: 'center' }}>
                      <b>Your feedback was successfully sent</b>
                    </p>
                    <p>Thank you for helping us to become better.</p>

                    <div className="btns-wrapper">
                      <button
                        onClick={() => onCancelFeedback()}
                        className="w-full py-2 px-14 md:px-10 bg-white text-black rounded-lg font-roboto uppercase font-bold text-sm"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Done
                      </button>
                    </div>
                  </div>
                )}
              </form>
            );
          }}
        </Formik>
      </Modal>
      {role !== 'webex' ? (
        [
          <Header style={styling} className="isomorphicTopbar desktop">
            <div className="isoLeft">
              <div className="isoLogoWrapper">
                <Link className="logo-text" to="/my-company">
                  <img src={logo} alt="logo" />
                </Link>
              </div>
            </div>

            <div className="isoCenter">
              <div className="isoBtnWrapper">
                {navLiks.map(link => (
                  <NavLink className={`logo-text ${chekLocation(link.to)}`} to={link.to} key={link.value}>
                    {link.to.includes('content-packs') && <span className="ai-badge">AI</span>}
                    {link.value}
                  </NavLink>
                ))}
              </div>
            </div>

            <div className="isoRight">
              <li className="isoUser">
                <TopbarUser />
              </li>
            </div>
          </Header>,
          <Header style={styling} className="isomorphicTopbar mobile">
            {!pathname.includes('my-company') && !isMobNavigOpen ? (
              <div tabIndex={0} role="button" onClick={() => history.goBack()} className="mob-back-btn">
                <svg width="9" height="15" viewBox="0 0 9 15" fill="none">
                  <path
                    d="M7.5 1.5L1.5 7.5L7.5 13.5"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            ) : (
              <div />
            )}
            <div className={isMobNavigOpen ? 'mob-menu open' : 'mob-menu'}>
              {navLiks.map(link => (
                <NavLink
                  onClick={toggleNavig}
                  className={`nav-it ${chekLocation(link.to)}`}
                  to={link.to}
                  key={link.value}
                >
                  {link.value}
                </NavLink>
              ))}
              <TopbarBarUser toggleNavig={toggleNavig} />
            </div>

            <div className="isoLogoWrapper">
              <Link className="logo-text" to="/my-company">
                <img src={logo} alt="logo" />
              </Link>
            </div>

            <div
              tabIndex={0}
              role="button"
              onClick={toggleNavig}
              className={isMobNavigOpen ? 'nav-icon open' : 'nav-icon'}
            >
              <span />
              <span />
              <span />
            </div>
          </Header>,
        ]
      ) : (
        <Header style={styling} className="isomorphicTopbar webex">
          <div className="isoCenter">
            <div className="isoLogoWrapper">
              {pathname.includes('/benchmark') || pathname.includes('/webex-welcome') ? (
                <div style={{ width: '54px' }} />
              ) : (
                <div className="back-btn" role="button" tabIndex={0} onClick={goBack}>
                  <span className="icon icon-filter_arrow_down" />
                </div>
              )}
            </div>
          </div>

          <div className="isoCenter">
            <div className="isoLogoWrapper">
              <Link
                style={{ position: 'absolute', left: '50%', top: '50%', marginTop: '-32px', marginLeft: '-57px' }}
                className="logo-text"
                to="/benchmark"
              >
                <img src={logo} alt="logo" />
              </Link>
            </div>
          </div>

          <div className="isoCenter">
            {!pathname.includes('/webex-') ? (
              <div className="isoBtnWrapper">
                {navLiks.map(link => (
                  <NavLink
                    style={{ margin: 0 }}
                    className={`logo-text ${chekLocation(link.to)}`}
                    to={link.to}
                    key={link.value}
                  >
                    <svg width="60" height="37" viewBox="0 0 60 37" fill="none">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M37.18 0.349058C37.6477 0.642759 37.7888 1.26001 37.4951 1.72773L30.2939
                        13.1956C30.1057 13.4952 29.7733 13.6731 29.4197 13.6635C29.066 13.6538 28.7438 13.458 28.5723 13.1486L25.5662 7.72485L21.3367 14.1334C21.0325 14.5944 20.4122 14.7215 19.9512 14.4172C19.4903 14.113 19.3632 13.4927 19.6674 13.0318L24.8061 5.24565C24.9988 4.95376 25.3299 4.78372 25.6794 4.79723C26.0288 4.81073 26.3458 5.00583 26.5154 5.31171L29.5007 10.6979L35.8014 0.66414C36.0951 0.196424 36.7123 0.0553571 37.18 0.349058Z"
                        fill="white"
                      />
                      <path
                        opacity="0.5"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M30.9139 2.35823C31.2603 2.36249 31.5799 2.54577 31.7585 2.84265L35.038 8.29397L38.0484 3.69731C38.351 3.23528 38.9708 3.10603 39.4328
                        3.40861C39.8948 3.71119 40.0241 4.33102 39.7215 4.79304L35.8386 10.7219C35.6502 11.0098 35.327 11.1805
                        34.983 11.1739C34.639 11.1673 34.3226 10.9844 34.1452 10.6896L30.8782 5.25913L21.346 20.256C21.0498 20.7221
                        20.4318 20.8597 19.9657 20.5635C19.4996 20.2672 19.3619 19.6492 19.6581 19.1831L30.0576 2.82173C30.2435 2.52933 30.5674 2.35397 30.9139 2.35823Z"
                        fill="white"
                      />
                      <path
                        d="M1.05003 34C0.976696 34 0.913363 33.9734 0.860029 33.92C0.806696
                        33.8667 0.780029 33.8034 0.780029 33.73V27.27C0.780029 27.1967 0.806696 27.1334
                        0.860029 27.08C0.913363 27.0267 0.976696 27 1.05003 27H4.00003C4.4267 27 4.78336 27.0667
                        5.07003 27.2C5.3567 27.3267 5.5867 27.49 5.76003 27.69C5.93336 27.8834 6.0567 28.0967
                        6.13003 28.33C6.21003 28.5567 6.25003 28.77 6.25003 28.97C6.25003 29.17 6.22336 29.35 6.17003
                        29.51C6.1167 29.6634 6.05003 29.7967 5.97003 29.91C5.8967 30.0167 5.82003 30.1067 5.74003
                        30.18C5.6667 30.2534 5.6067 30.3034 5.56003 30.33C5.7667 30.47 5.9567 30.67 6.13003
                        30.93C6.30336 31.1834 6.39003 31.5067 6.39003 31.9C6.39003 32.1534 6.34336 32.4067 6.25003 32.66C6.1567 32.9067 6.02003 33.13 5.84003 33.33C5.66003 33.53 5.43336 33.6934 5.16003
                        33.82C4.89336 33.94 4.58336 34 4.23003 34H1.05003ZM4.90003 29.02C4.90003 28.7667 4.82003 28.5767 4.66003 28.45C4.50003 28.3167 4.2467 28.25 3.90003 28.25H2.13003V29.79H3.90003C4.2467 29.79 4.50003 29.7267 4.66003 29.6C4.82003 29.4667 4.90003 29.2734 4.90003 29.02ZM2.13003 32.75H3.98003C4.3467 32.75 4.61336 32.6734 4.78003 32.52C4.95336 32.36 5.04003 32.1534 5.04003 31.9C5.04003 31.6467 4.95003 31.44 4.77003 31.28C4.5967 31.12 4.33336 31.04 3.98003 31.04H2.13003V32.75Z"
                        fill="white"
                      />
                      <path
                        d="M7.7855 31.4C7.7855 31.0267 7.84216 30.6767 7.9555 30.35C8.06883 30.0167 8.23216
                        29.73 8.4455 29.49C8.65883 29.2434 8.9155 29.05 9.2155 28.91C9.52216 28.77 9.86217
                        28.7 10.2355 28.7C10.6088 28.7 10.9455 28.77 11.2455 28.91C11.5522 29.0434 11.8122
                        29.2267 12.0255 29.46C12.2388 29.6867 12.4022 29.9567 12.5155 30.27C12.6355 30.5767
                        12.6955 30.9 12.6955 31.24V31.69C12.6955 31.7634 12.6688 31.8267 12.6155 31.88C12.5622
                        31.9334 12.4988 31.96 12.4255 31.96H9.0855C9.0855 32.1134 9.1155 32.25 9.1755 32.37C9.24216
                        32.4834 9.32883 32.58 9.4355 32.66C9.54216 32.74 9.6655 32.8 9.8055 32.84C9.9455 32.88 10.0888 32.9 10.2355
                        32.9C10.4488 32.9 10.6222 32.88 10.7555 32.84C10.8888 32.7934 10.9988 32.7367 11.0855 32.67C11.1588 32.6167
                        11.2188 32.58 11.2655 32.56C11.3122 32.54 11.3755 32.53 11.4555 32.53H12.2555C12.3288 32.53 12.3922 32.5567
                        12.4455 32.61C12.5055 32.6634 12.5322 32.7267 12.5255 32.8C12.5188 32.8934 12.4655 33.0134 12.3655 33.16C12.2722
                        33.3067 12.1288 33.45 11.9355 33.59C11.7422 33.73 11.5022 33.85 11.2155 33.95C10.9355 34.05 10.6088 34.1 10.2355
                        34.1C9.86217 34.1 9.52216 34.0367 9.2155 33.91C8.9155 33.7767 8.65883 33.59 8.4455 33.35C8.23216 33.11 8.06883
                        32.8267 7.9555 32.5C7.84216 32.1667 7.7855 31.8 7.7855 31.4ZM10.2355 29.9C10.0422 29.9 9.8755 29.93 9.7355 29.99C9.60217 30.0434 9.48883 30.1167 9.3955 30.21C9.30216 30.2967 9.22883 30.3967 9.1755 30.51C9.12883 30.6167 9.09883 30.72 9.0855 30.82H11.3455C11.3322 30.72 11.3055 30.6167 11.2655 30.51C11.2322 30.3967 11.1722 30.2967 11.0855 30.21C11.0055 30.1167 10.8955 30.0434 10.7555 29.99C10.6222 29.93 10.4488 29.9 10.2355 29.9Z"
                        fill="white"
                      />
                      <path
                        d="M19.1677 33.73C19.1677 33.8034 19.141 33.8667 19.0877 33.92C19.0344 33.9734 18.971 34 18.8977
                        34H18.1377C18.0644 34 18.001 33.9734 17.9477 33.92C17.8944 33.8667 17.8677 33.8034 17.8677 33.73V31.19C17.8677
                        30.79 17.7744 30.47 17.5877 30.23C17.401 29.99 17.1144 29.87 16.7277 29.87C16.3744 29.87 16.0944 29.99 15.8877
                        30.23C15.6877 30.47 15.5877 30.79 15.5877 31.19V33.73C15.5877 33.8034 15.561 33.8667 15.5077 33.92C15.4544
                        33.9734 15.391 34 15.3177 34H14.5577C14.4844 34 14.421 33.9734 14.3677 33.92C14.3144 33.8667 14.2877
                        33.8034 14.2877 33.73V29.07C14.2877 28.9967 14.3144 28.9334 14.3677 28.88C14.421 28.8267 14.4844 28.8 14.5577
                        28.8H15.3177C15.391 28.8 15.4544 28.8267 15.5077 28.88C15.561 28.9334 15.5877 28.9967 15.5877 29.07V29.3C15.761 29.1267 15.9644 28.9834 16.1977 28.87C16.431 28.7567 16.7077 28.7 17.0277 28.7C17.4344 28.7 17.7744 28.7634 18.0477 28.89C18.321 29.0167 18.541 29.1867 18.7077 29.4C18.8744 29.6134 18.991 29.8634 19.0577 30.15C19.131 30.43 19.1677 30.7267 19.1677 31.04V33.73Z"
                        fill="white"
                      />
                      <path
                        d="M22.0486 31.05C22.0353 31.15 22.0286 31.2667 22.0286 31.4C22.0286 31.5334 22.0353 31.65 22.0486 31.75C22.0686
                        31.9567 22.1153 32.1334 22.1886 32.28C22.262 32.4267 22.352 32.5467 22.4586 32.64C22.572 32.7267 22.6953
                        32.7934 22.8286 32.84C22.962 32.88 23.0986 32.9 23.2386 32.9C23.4053 32.9 23.5453 32.8867 23.6586
                        32.86C23.772 32.8334 23.8686 32.7967 23.9486 32.75C24.0286 32.6967 24.0953 32.64 24.1486 32.58C24.2086
                        32.5134 24.2653 32.44 24.3186 32.36C24.3586 32.3 24.4053 32.24 24.4586 32.18C24.512 32.12 24.5753
                        32.09 24.6486 32.09H25.4086C25.482 32.09 25.5453 32.1167 25.5986 32.17C25.6586 32.2234 25.6853 32.2867 25.6786
                        32.36C25.672 32.5 25.622 32.67 25.5286 32.87C25.4353 33.07 25.2886 33.2634 25.0886 33.45C24.8953 33.63 24.6453
                        33.7834 24.3386 33.91C24.032 34.0367 23.6653 34.1 23.2386 34.1C22.872 34.1 22.542 34.04 22.2486 33.92C21.9553
                        33.7934 21.6986 33.6267 21.4786 33.42C21.2653 33.2067 21.0953 32.9634 20.9686 32.69C20.8486 32.41 20.7753 32.1167
                        20.7486 31.81C20.7353 31.69 20.7286 31.5567 20.7286 31.41C20.7286 31.2634 20.7353 31.1267 20.7486 31C20.7686
                        30.6934 20.842 30.4 20.9686 30.12C21.0953 29.84 21.2653 29.5967 21.4786 29.39C21.692 29.1767 21.9453 29.01
                        22.2386 28.89C22.5386 28.7634 22.872 28.7 23.2386 28.7C23.6653 28.7 24.032 28.7634 24.3386 28.89C24.6453 29.0167
                        24.8953 29.1734 25.0886 29.36C25.2886 29.54 25.4353 29.73 25.5286 29.93C25.622 30.13 25.672 30.3 25.6786
                        30.44C25.6853 30.5134 25.6586 30.5767 25.5986 30.63C25.5453 30.6834 25.482 30.71 25.4086 30.71H24.6486C24.5753
                        30.71 24.512 30.68 24.4586 30.62C24.4053 30.56 24.3586 30.5 24.3186 30.44C24.2653 30.36 24.2086 30.29 24.1486 30.23C24.0953 30.1634 24.0286 30.1067 23.9486 30.06C23.8686 30.0067 23.772 29.9667 23.6586 29.94C23.5453 29.9134 23.4053 29.9 23.2386 29.9C23.0986 29.9 22.962 29.9234 22.8286 29.97C22.6953 30.01 22.572 30.0767 22.4586 30.17C22.352 30.2567 22.262 30.3734 22.1886 30.52C22.1153 30.6667 22.0686 30.8434 22.0486 31.05Z"
                        fill="white"
                      />
                      <path
                        d="M32.1501 33.73C32.1501 33.8034 32.1234 33.8667 32.0701 33.92C32.0168 33.9734 31.9534 34 31.8801
                        34H31.1201C31.0468 34 30.9834 33.9734 30.9301 33.92C30.8768 33.8667 30.8501 33.8034 30.8501 33.73V31.19C30.8501
                        30.79 30.7568 30.47 30.5701 30.23C30.3834 29.99 30.0968 29.87 29.7101 29.87C29.3568 29.87 29.0768 29.99 28.8701
                        30.23C28.6701 30.47 28.5701 30.79 28.5701 31.19V33.73C28.5701 33.8034 28.5434 33.8667 28.4901 33.92C28.4368
                        33.9734 28.3734 34 28.3001 34H27.5401C27.4668 34 27.4034 33.9734 27.3501 33.92C27.2968 33.8667 27.2701 33.8034
                        27.2701 33.73V27.17C27.2701 27.0967 27.2968 27.0334 27.3501 26.98C27.4034 26.9267 27.4668 26.9 27.5401
                        26.9H28.3001C28.3734 26.9 28.4368 26.9267 28.4901 26.98C28.5434 27.0334 28.5701 27.0967 28.5701 27.17V29.3C28.7434 29.1267 28.9468 28.9834 29.1801 28.87C29.4134 28.7567 29.6901 28.7 30.0101 28.7C30.4168 28.7 30.7568 28.7634 31.0301 28.89C31.3034 29.0167 31.5234 29.1867 31.6901 29.4C31.8568 29.6134 31.9734 29.8634 32.0401 30.15C32.1134 30.43 32.1501 30.7267 32.1501 31.04V33.73Z"
                        fill="white"
                      />
                      <path
                        d="M35.281 29.3C35.441 29.1067 35.6344 28.96 35.861 28.86C36.0877 28.7534 36.3244 28.7 36.571 28.7C36.991 28.7
                        37.3344 28.78 37.601 28.94C37.8677 29.0934 38.0777 29.2967 38.231 29.55C38.3177 29.4167 38.4244 29.2967 38.551
                        29.19C38.6777 29.0834 38.811 28.9934 38.951 28.92C39.0977 28.8467 39.2477 28.7934 39.401 28.76C39.5544 28.72
                        39.7044 28.7 39.851 28.7C40.231 28.7 40.5477 28.7634 40.801 28.89C41.0544 29.0167 41.2577 29.1867 41.411
                        29.4C41.571 29.6134 41.681 29.8634 41.741 30.15C41.8077 30.43 41.841 30.7267 41.841 31.04V33.73C41.841 33.8034
                        41.8144 33.8667 41.761 33.92C41.7077 33.9734 41.6444 34 41.571 34H40.811C40.7377 34 40.6744 33.9734 40.621
                        33.92C40.5677 33.8667 40.541 33.8034 40.541 33.73V31.09C40.541 30.6367 40.4544 30.32 40.281 30.14C40.1077
                        29.96 39.8644 29.87 39.551 29.87C39.2844 29.87 39.0544 29.9567 38.861 30.13C38.6744 30.3034 38.5744 30.6067
                        38.561 31.04V33.73C38.561 33.8034 38.5344 33.8667 38.481 33.92C38.4277 33.9734 38.3644 34 38.291 34H37.531C37.4577
                        34 37.3944 33.9734 37.341 33.92C37.2877 33.8667 37.261 33.8034 37.261 33.73V31.09C37.261 30.6367 37.1744 30.32
                        37.001 30.14C36.8277 29.96 36.5844 29.87 36.271 29.87C35.9977 29.87 35.7644 29.9634 35.571 30.15C35.3777 30.33
                        35.281 30.6434 35.281 31.09V33.73C35.281 33.8034 35.2544 33.8667 35.201 33.92C35.1477 33.9734 35.0844 34 35.011 34H34.251C34.1777 34 34.1144 33.9734 34.061 33.92C34.0077 33.8667 33.981 33.8034 33.981 33.73V29.07C33.981 28.9967 34.0077 28.9334 34.061 28.88C34.1144 28.8267 34.1777 28.8 34.251 28.8H35.011C35.0844 28.8 35.1477 28.8267 35.201 28.88C35.2544 28.9334 35.281 28.9967 35.281 29.07V29.3Z"
                        fill="white"
                      />
                      <path
                        d="M43.6705 30.08C43.6972 29.94 43.7572 29.79 43.8505 29.63C43.9505 29.47 44.0872 29.32 44.2605
                        29.18C44.4405 29.04 44.6572 28.9267 44.9105 28.84C45.1705 28.7467 45.4705 28.7 45.8105 28.7C46.1505 28.7
                        46.4572 28.7467 46.7305 28.84C47.0038 28.9267 47.2372 29.0567 47.4305 29.23C47.6305 29.4034 47.7838 29.6234
                        47.8905 29.89C47.9972 30.15 48.0505 30.45 48.0505 30.79V33.73C48.0505 33.8034 48.0238 33.8667 47.9705
                        33.92C47.9172 33.9734 47.8538 34 47.7805 34H47.0205C46.9472 34 46.8838 33.9734 46.8305 33.92C46.7772 33.8667
                        46.7505 33.8034 46.7505 33.73V33.4C46.6105 33.5934 46.4072 33.76 46.1405 33.9C45.8805 34.0334 45.5472 34.1
                        45.1405 34.1C44.8538 34.1 44.5972 34.0634 44.3705 33.99C44.1438 33.91 43.9538 33.8 43.8005 33.66C43.6472
                        33.52 43.5272 33.3567 43.4405 33.17C43.3605 32.9767 43.3205 32.7667 43.3205 32.54C43.3205 32.08 43.4838
                        31.72 43.8105 31.46C44.1372 31.2 44.5738 31.0234 45.1205 30.93L46.7505 30.65C46.7505 30.3767 46.6605 30.1834
                        46.4805 30.07C46.3005 29.9567 46.0772 29.9 45.8105 29.9C45.6438 29.9 45.5138 29.9234 45.4205 29.97C45.3272
                        30.0167 45.2372 30.08 45.1505 30.16C45.0905 30.2134 45.0338 30.25 44.9805 30.27C44.9338 30.29 44.8805 30.3 44.8205
                        30.3H43.8905C43.8238 30.3 43.7672 30.28 43.7205 30.24C43.6738 30.2 43.6572 30.1467 43.6705 30.08ZM45.3405
                        32.9C45.5672 32.9 45.7672 32.87 45.9405 32.81C46.1205 32.7434 46.2705 32.6567 46.3905 32.55C46.5105 32.4434 46.6005 32.3234 46.6605 32.19C46.7205 32.0567 46.7505 31.92 46.7505 31.78V31.68L45.3905 31.92C45.1172 31.9667 44.9205 32.0367 44.8005 32.13C44.6805 32.2167 44.6205 32.3367 44.6205 32.49C44.6205 32.63 44.6905 32.7334 44.8305 32.8C44.9772 32.8667 45.1472 32.9 45.3405 32.9Z"
                        fill="white"
                      />
                      <path
                        d="M52.3134 30C51.9134 30 51.6234 30.09 51.4434 30.27C51.2701 30.4434 51.1834 30.73 51.1834
                        31.13V33.73C51.1834 33.8034 51.1567 33.8667 51.1034 33.92C51.0501 33.9734 50.9867 34 50.9134 34H50.1534C50.0801
                        34 50.0167 33.9734 49.9634 33.92C49.9101 33.8667 49.8834 33.8034 49.8834 33.73V29.07C49.8834 28.9967
                        49.9101 28.9334 49.9634 28.88C50.0167 28.8267 50.0801 28.8 50.1534 28.8H50.9134C50.9867 28.8 51.0501 28.8267 51.1034 28.88C51.1567 28.9334 51.1834 28.9967 51.1834 29.07V29.3C51.3567 29.14 51.5401 29.0167 51.7334 28.93C51.9334 28.8434 52.1934 28.8 52.5134 28.8H52.9634C53.0367 28.8 53.1001 28.8267 53.1534 28.88C53.2067 28.9334 53.2334 28.9967 53.2334 29.07V29.73C53.2334 29.8034 53.2067 29.8667 53.1534 29.92C53.1001 29.9734 53.0367 30 52.9634 30H52.3134Z"
                        fill="white"
                      />
                      <path
                        d="M55.8631 32.01V33.73C55.8631 33.8034 55.8364 33.8667 55.7831
                        33.92C55.7297 33.9734 55.6664 34 55.5931 34H54.8331C54.7597 34 54.6964 33.9734 54.6431 33.92C54.5897
                        33.8667 54.5631 33.8034 54.5631 33.73V27.17C54.5631 27.0967 54.5897 27.0334 54.6431 26.98C54.6964 26.9267
                        54.7597 26.9 54.8331 26.9H55.5931C55.6664 26.9 55.7297 26.9267 55.7831 26.98C55.8364 27.0334 55.8631 27.0967
                        55.8631 27.17V30.32L57.5331 28.97C57.5731 28.9367 57.6231 28.9 57.6831 28.86C57.7497 28.82 57.8297 28.8 57.9231
                        28.8H58.9431C59.0097 28.8 59.0664 28.8234 59.1131 28.87C59.1597 28.9167 59.1831 28.9734 59.1831 29.04C59.1831
                        29.0734 59.1764 29.1067 59.1631 29.14C59.1564 29.1667 59.1264 29.2 59.0731 29.24L56.7431 31.12L59.2931 33.57C59.3331 33.61 59.3597 33.6434 59.3731 33.67C59.3864 33.6967 59.3931 33.7267 59.3931 33.76C59.3931 33.8267 59.3697 33.8834 59.3231 33.93C59.2764 33.9767 59.2197 34 59.1531 34H58.1831C58.0831 34 57.9964 33.98 57.9231 33.94C57.8564 33.9 57.8064 33.8634 57.7731 33.83L55.8631 32.01Z"
                        fill="white"
                      />
                    </svg>
                  </NavLink>
                ))}
              </div>
            ) : (
              <div className="isoBtnWrapper">
                {' '}
                <div style={{ width: '35px' }} />
              </div>
            )}
          </div>
        </Header>
      )}
    </TopbarWrapper>
  );
};

export default Topbar;
