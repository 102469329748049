import { all, put, takeEvery, fork, call } from 'redux-saga/effects';
import actions from './actions';
import { reduxSagaFirebase, firebase } from '../../redux/firestore';

export function* loadBundleRequest() {
  yield takeEvery(actions.LOAD_BUNDLE_REQUEST, function*({ payload }) {
    const { id } = payload;

    const snapshot = yield call(reduxSagaFirebase.firestore.getDocument, `bundles/${id}`);
    const bundle = snapshot.data();

    yield put(actions.loadBundleSuccess({ bundle }));
  });
}

export function* loaCompaniesRequest() {
  yield takeEvery(actions.LOAD_COMPANIES_REQUEST, function*({ payload }) {
    const { id, keywords } = payload;

    let snapshot;

    if (keywords.length) {
      const colRef = firebase.firestore().collection(`bundles/${id}/products`);
      snapshot = yield call(
        reduxSagaFirebase.firestore.getCollection,
        colRef.where('keywords', 'array-contains-any', keywords),
      );
    } else {
      snapshot = yield call(reduxSagaFirebase.firestore.getCollection, `bundles/${id}/products`);
    }

    let companies;

    snapshot.forEach(el => {
      companies = {
        ...companies,
        [el.id]: el.data(),
      };
    });

    companies = companies ? Object.values(companies) : [];

    const data = companies.length ? companies : [];

    yield put(actions.loadCompaniesSuccess({ companies: data }));
  });
}

export default function* rootSaga() {
  yield all([fork(loadBundleRequest), fork(loaCompaniesRequest)]);
}
