const COMPONENT = 'SWOT_ITEM';

const actions = {
  LOAD_REQUEST: `${COMPONENT}_LOAD_REQUEST`,
  LOAD_SUCCESS: `${COMPONENT}_LOAD_SUCCESS`,
  LOAD_ERROR: `${COMPONENT}_LOAD_ERROR`,

  LOAD_ANNIE_REQUEST: `${COMPONENT}_LOAD_ANNIE_REQUEST`,
  LOAD_ANNIE_SUCCESS: `${COMPONENT}_LOAD_ANNIE_SUCCESS`,
  LOAD_ANNIE_ERROR: `${COMPONENT}_LOAD_ANNIE_ERROR`,

  DELETE_ANNIE_CHAT_REQUEST: `${COMPONENT}_DELETE_ANNIE_CHAT_REQUEST`,
  DELETE_ANNIE_CHAT_SUCCESS: `${COMPONENT}_DELETE_ANNIE_CHAT_SUCCESS`,
  DELETE_ANNIE_CHAT_ERROR: `${COMPONENT}_DELETE_ANNIE_CHAT_ERROR`,

  CLEAR: `${COMPONENT}_CLEAR`,

  loadRequest: data => ({
    type: actions.LOAD_REQUEST,
    payload: data,
  }),
  loadSuccess: data => ({
    type: actions.LOAD_SUCCESS,
    payload: data,
  }),
  loadError: data => ({
    type: actions.LOAD_ERROR,
    payload: data,
  }),

  loadAnnieRequest: data => ({
    type: actions.LOAD_ANNIE_REQUEST,
    payload: data,
  }),
  loadAnnieSuccess: data => ({
    type: actions.LOAD_ANNIE_SUCCESS,
    payload: data,
  }),
  loadAnnieError: data => ({
    type: actions.LOAD_ANNIE_ERROR,
    payload: data,
  }),

  deleteAnnieChatRequest: data => ({
    type: actions.DELETE_ANNIE_CHAT_REQUEST,
    payload: data,
  }),
  deleteAnnieChatSuccess: data => ({
    type: actions.DELETE_ANNIE_CHAT_SUCCESS,
    payload: data,
  }),
  deleteAnnieChatError: data => ({
    type: actions.DELETE_ANNIE_CHAT_ERROR,
    payload: data,
  }),

  clear: () => ({
    type: actions.CLEAR,
  }),
};
export default actions;
