const COMPONENT = 'BENCHMARK';

const actions = {
  LOAD_REQUEST: `${COMPONENT}LOAD_REQUEST`,
  LOAD_SUCCESS: `${COMPONENT}LOAD_SUCCESS`,
  LOAD_TIMELINE_REQUEST: `${COMPONENT}_LOAD_TIMELINE_REQUEST`,
  LOAD_TIMELINE_SUCCESS: `${COMPONENT}_LOAD_TIMELINE_SUCCESS`,
  SET_FILTER: `${COMPONENT}SET_FILTER`,
  SWITCH_CHART_VIEW: `${COMPONENT}SWITCH_CHART_VIEW`,
  CLEAR: `${COMPONENT}CLEAR`,
  LOAD_ERROR: `${COMPONENT}_LOAD_ERROR`,
  CHANGE_SCORE: `${COMPONENT}_CHANGE_SCORE`,
  LOAD_COMPANIES_REQUEST: `${COMPONENT}LOAD_COMPANIES_REQUEST`,
  LOAD_COMPANIES_SUCCESS: `${COMPONENT}LOAD_COMPANIES_SUCCESS`,
  LOAD_INDUSTRIES_REQUEST: `${COMPONENT}LOAD_INDUSTRIES_REQUEST`,
  LOAD_INDUSTRIES_SUCCESS: `${COMPONENT}LOAD_INDUSTRIES_SUCCESS`,
  SEARCH: `${COMPONENT}SEARCH`,
  SELECT: `${COMPONENT}SELECT`,

  loadRequest: data => ({
    type: actions.LOAD_REQUEST,
    payload: data,
  }),

  loadSuccess: data => ({
    type: actions.LOAD_SUCCESS,
    payload: data,
  }),

  loadCompaniesRequest: data => ({
    type: actions.LOAD_COMPANIES_REQUEST,
    payload: data,
  }),

  loadCompaniesSuccess: data => ({
    type: actions.LOAD_COMPANIES_SUCCESS,
    payload: data,
  }),

  loadIndustriesRequest: data => ({
    type: actions.LOAD_INDUSTRIES_REQUEST,
    payload: data,
  }),

  loadIndustriesSuccess: data => ({
    type: actions.LOAD_INDUSTRIES_SUCCESS,
    payload: data,
  }),

  loadTimelineRequest: data => ({
    type: actions.LOAD_TIMELINE_REQUEST,
    payload: data,
  }),

  loadTimelineSuccess: data => ({
    type: actions.LOAD_TIMELINE_SUCCESS,
    payload: data,
  }),

  changeScore: data => ({
    type: actions.CHANGE_SCORE,
    payload: data,
  }),

  search: data => ({
    type: actions.SEARCH,
    payload: data,
  }),

  select: data => ({
    type: actions.SELECT,
    payload: data,
  }),

  switchChartView: data => ({
    type: actions.SWITCH_CHART_VIEW,
    payload: data,
  }),

  setFilter: data => ({
    type: actions.SET_FILTER,
    payload: data,
  }),

  loadError: data => ({
    type: actions.LOAD_ERROR,
    payload: data,
  }),

  clear: () => ({
    type: actions.CLEAR,
  }),
};
export default actions;
