import { all, takeEvery, put } from 'redux-saga/effects';
import actions from './actions';
import { callApi } from '../../helpers/api';

export function* load(data) {
  try {
    const { payload } = data;

    const response = yield callApi('public/webex/setEmail', 'POST', payload);

    if (response.status === 'success') {
      localStorage.setItem('isUserExist', 'true');

      yield put(
        actions.loadSuccess({
          emailSent: true,
        }),
      );
    } else if (response.status === 'error') {
      const { error } = response.data;

      yield put(actions.loadError(error));
    }
  } catch (error) {
    console.warn(error);
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD, load)]);
}
