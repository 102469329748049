const COMPONENT = 'TOPBAR';

const actionsTopbar = {
  SWITCH_DATA_MODE: `${COMPONENT}_SWITCH_DATA_MODE`,
  SET_DATA_MODE: `${COMPONENT}_SET_DATA_MODE`,
  SEND_FEEDBACK: `${COMPONENT}_SEND_FEEDBACK`,

  setDataMode: () => ({
    type: actionsTopbar.SET_DATA_MODE,
  }),

  sendFeedback: data => ({
    type: actionsTopbar.SEND_FEEDBACK,
    payload: data,
  }),

  switchDataMode: () => ({
    type: actionsTopbar.SWITCH_DATA_MODE,
  }),
};

export default actionsTopbar;
