import { all, put, takeEvery, fork, take } from 'redux-saga/effects';
import { channel } from 'redux-saga';
import { v4 } from 'uuid';
import RestApi from '../../../helpers/api';
import actions from './actions';
import { storage } from '../../../redux/firestore';

const api = new RestApi('products');

export function* loadProductsRequest() {
  yield takeEvery(actions.LOAD_PRODUCTS_REQUEST, function*({ payload }) {
    const { providerId, page, pageSize } = payload;

    const result = yield api.load({ providerId, page, pageSize });

    if (result.status === 'success') {
      const { data, count } = result;

      yield put(actions.loadProductsSuccess({ data, count, page }));
    } else if (result.status === 'error') {
      yield put(actions.loadProductsError(result));
    }
  });
}

export function* sendService() {
  yield takeEvery(actions.SEND_SERVICE, function*({ payload }) {
    try {
      const {
        serviceName,
        category,
        subcategory,
        keywords,
        heading,
        description,
        companyId,
        categoryId,
        subcategoryId,
        attachments,
      } = payload;

      const data = {
        companyId,
        serviceName,
        category,
        subcategory,
        keywords,
        heading,
        description,
        categoryId,
        subcategoryId,
        attachments,
      };

      yield api.createItem({ data });
    } catch (e) {
      console.warn('error', e);
    }
  });
}

export function* deleteAttachment() {
  yield takeEvery(actions.DELETE_ATTACHMENT, function*({ payload }) {
    try {
      const { name, companyId, type } = payload;
      if (type === 'file') {
        yield storage.ref(`/product-attachments/${companyId}/${name}`).delete();
      } else return;
    } catch (e) {
      console.warn('error', e);
    }
  });
}

export const fileChannel = channel();

export function* uploadFile() {
  yield takeEvery(actions.FILE_UPLOADING, function*({ payload }) {
    try {
      const { file, companyId, type } = payload;
      if (type === 'file') {
        const id = v4();
        const name = `${file.name}`;
        const filename = `${companyId}-${id}`;
        Object.defineProperty(file, 'name', {
          writable: true,
          value: filename,
        });

        const uploadTask = storage.ref(`/product-attachments/${companyId}/${file.name}`).put(file);

        uploadTask.on(
          'state_changed',
          snapshot => {
            const progressPercentage = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            fileChannel.put({
              type: actions.CHANGE_PROPERTY,
              payload: { attr: 'progress', value: progressPercentage },
            });
          },
          error => console.warn(error),
          () => {
            storage
              .ref(`/product-attachments/${companyId}/`)
              .child(file.name)
              .getDownloadURL()
              .then(link => {
                fileChannel.put(actions.sendLink({ name, link, type }));
                fileChannel.put(actions.fileUploadSuccess());
              });
          },
        );
      } else if (type === 'video') {
        yield put(actions.sendLink({ name: file, link: file, type }));
        yield put(actions.fileUploadSuccess());
      }
    } catch (e) {
      console.warn('error', e);
      yield put(actions.fileUploadError(e));
    }
  });
}

export function* watchFileChannel() {
  while (true) {
    const action = yield take(fileChannel);
    yield put(action);
  }
}

export default function* rootSaga() {
  yield all([
    fork(loadProductsRequest),
    fork(sendService),
    fork(uploadFile),
    fork(watchFileChannel),
    fork(deleteAttachment),
  ]);
}
