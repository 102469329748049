import actions from './actions';
import { createReducer } from '../../helpers/reducer';

// ------------------------------
// Initial State
// ------------------------------
const initState = {
  historicalOverview: undefined,
  companyProviderName: undefined,
  userProviderId: undefined,
  companyProviderId: undefined,
  reportName: undefined,
  publicationDate: undefined,
  reportDate: undefined,
  isPublished: false,
  data: null,
  validation: false,
  publishing: false,
  shareList: null,
  isListVisible: false,
  loadingShareList: false,
  isPrivate: false,
  dateScores: undefined,
};

// ------------------------------
// Action Handlers
// ------------------------------
function onLoadRiskSuccess(state, { payload }) {
  const {
    executiveSummary,
    companyProviderName,
    userProviderId,
    companyProviderId,
    reportName,
    publicationDate,
    data,
    isPublished,
    reportDate,
    createdBy,
    isPrivate,
    dateScores,
  } = payload;

  return {
    ...state,
    data,
    isPrivate,
    executiveSummary,
    companyProviderName,
    userProviderId,
    companyProviderId,
    reportName,
    publicationDate,
    reportDate,
    isPublished,
    createdBy,
    dateScores
  };
}

function onLoadTimelineSuccess(state, { payload }) {
  const { risks } = payload;

  return {
    ...state,
    risks,
  };
}

function onPublishRiskRequest(state, { payload }) {
  const { validation } = payload;

  return {
    ...state,
    validation,
    publishing: validation ? !state.publishing : false,
  };
}

function onLoading(state, { payload }) {
  const { publishing } = payload;

  return {
    ...state,
    publishing,
  };
}

function onPublishRiskSuccess(state, { payload }) {
  const { validation } = payload;

  return {
    ...state,
    isPublished: true,
    validation,
    publishing: false,
  };
}

function onPublishRiskValidation(state, { payload }) {
  const { validation } = payload;

  return {
    ...state,
    validation,
  };
}

function onSetFilter(state, { payload }) {
  const { keywords } = payload;

  return {
    ...state,
    keywords,
  };
}

function onLoadShareListSuccess(state, { payload }) {
  const { shareList } = payload;

  return {
    ...state,
    shareList,
    isListVisible: true,
    loadingShareList: false,
  };
}

function onLoadShareListRequest(state) {
  return {
    ...state,
    loadingShareList: true,
  };
}

function onLoadError(state) {
  return {
    ...state,
    isLoading: false,
  };
}

function onSwitchListVisibility(state) {
  return {
    ...state,
    isListVisible: !state.isListVisible,
  };
}

function onUpdateSharelistSuccess(state) {
  return {
    ...state,
    isListVisible: !state.isListVisible,
  };
}

function onSwitchShareStatus(state, { payload }) {
  const { findBy } = payload;
  const { shareList } = state;

  return {
    ...state,
    shareList: shareList.map(el => {
      if (el.id === findBy) {
        return { ...el, isShared: !el.isShared };
      }

      return { ...el };
    }),
  };
}

function onClear() {
  return {
    initState,
  };
}

// ------------------------------
// Exported reducer function.
// ------------------------------
export default createReducer(initState, {
  [actions.SWITCH_SHARE_STATUS]: onSwitchShareStatus,
  [actions.SWITCH_LIST_VISIBILITY]: onSwitchListVisibility,
  [actions.LOAD_SHARE_LIST_SUCCESS]: onLoadShareListSuccess,
  [actions.LOAD_SHARE_LIST_REQUEST]: onLoadShareListRequest,
  [actions.UPDATE_SHARE_LIST_SUCCESS]: onUpdateSharelistSuccess,
  [actions.LOADING]: onLoading,
  [actions.PUBLISH_RISK_REQUEST]: onPublishRiskRequest,
  [actions.PUBLISH_RISK_VALIDATION]: onPublishRiskValidation,
  [actions.PUBLISH_RISK_SUCCESS]: onPublishRiskSuccess,
  [actions.LOAD_RISK_SUCCESS]: onLoadRiskSuccess,
  [actions.LOAD_TIMELINE_SUCCESS]: onLoadTimelineSuccess,
  [actions.SET_FILTER]: onSetFilter,
  [actions.LOAD_ERROR]: onLoadError,
  [actions.CLEAR]: onClear,
});
