import actions from './actions';
import { createReducer } from '../../helpers/reducer';

// ------------------------------
// Initial State
// ------------------------------
const initState = {
  historyChart: null,
  scoreComperison: null,
  subScoreComperison: null,
  peerComparison: null,
  distribution: null,
  distributionMaxValue: undefined,
  timeline: null,
  loading: false,
  sentimentGroupId: undefined,
  search: undefined,
  companiesList: null,
  industriesList: null,
  colorsSet: null,
  companies: {
    c1: undefined,
    c2: undefined,
    c3: undefined,
    c4: undefined,
    c5: undefined,
  },
};

// ------------------------------
// Action Handlers
// ------------------------------
function onLoadSuccess(state, { payload }) {
  const {
    history,
    scoreComperison,
    peerComparison,
    distribution,
    distributionMaxValue,
    colorsSet,
    subScoreComperison,
  } = payload;

  return {
    ...state,
    historyChart: history,
    subScoreComperison,
    scoreComperison,
    peerComparison,
    distribution,
    distributionMaxValue,
    colorsSet,
  };
}

function onLoadCompaniesSuccess(state, { payload }) {
  const { companiesList } = payload;

  return {
    ...state,
    companiesList,
  };
}

function onLoadIndustriesSuccess(state, { payload }) {
  const { industriesList } = payload;

  return {
    ...state,
    industriesList,
  };
}

function onSwitchChartView(state, { payload }) {
  const { historicalChartView } = payload;

  return {
    ...state,
    historicalChartView,
  };
}

function onLoadTimelineSuccess(state, { payload }) {
  const { timeline } = payload;

  return {
    ...state,
    timeline,
  };
}

function onSetFilter(state, { payload }) {
  const { keywords } = payload;

  return {
    ...state,
    keywords,
  };
}

function onChangeScore(state, { payload }) {
  const { sentimentGroupId } = payload;

  return {
    ...state,
    sentimentGroupId,
  };
}

function onSearch(state, { payload }) {
  const { search } = payload;

  return {
    ...state,
    search,
  };
}

function onSelect(state, { payload }) {
  const { select, attr } = payload;

  return {
    ...state,
    search: undefined,
    companies: {
      ...state.companies,
      [attr]: select,
    },
  };
}

function onLoadError(state) {
  return {
    ...state,
    historyChart: null,
    scoreComperison: null,
    peerComparison: null,
    distribution: null,
  };
}

function onClear() {
  return {
    initState,
  };
}

// ------------------------------
// Exported reducer function.
// ------------------------------
export default createReducer(initState, {
  [actions.SEARCH]: onSearch,
  [actions.SELECT]: onSelect,
  [actions.LOAD_COMPANIES_SUCCESS]: onLoadCompaniesSuccess,
  [actions.LOAD_INDUSTRIES_SUCCESS]: onLoadIndustriesSuccess,
  [actions.LOAD_SUCCESS]: onLoadSuccess,
  [actions.SWITCH_CHART_VIEW]: onSwitchChartView,
  [actions.LOAD_TIMELINE_SUCCESS]: onLoadTimelineSuccess,
  [actions.SET_FILTER]: onSetFilter,
  [actions.CHANGE_SCORE]: onChangeScore,
  [actions.LOAD_ERROR]: onLoadError,
  [actions.CLEAR]: onClear,
});
