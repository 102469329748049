import { all, call, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import { reduxSagaFirebase, firebase } from '../../redux/firestore';

export function* sendSurveyData({ payload }) {
  try {
    const { companyId, data } = payload;
    const timestamp = firebase.firestore.Timestamp.fromDate(new Date());

    yield call(reduxSagaFirebase.firestore.setDocument, `companies_user_data/${companyId}`, {
      inviteSurvey: {
        ...data,
        timestamp,
      },
    });
  } catch (error) {
    console.warn('error', error);
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.SEND_SURVEY_DATA, sendSurveyData)]);
}
