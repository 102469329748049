import { all, put, takeEvery, fork } from 'redux-saga/effects';
import moment from 'moment';
import actions from './actions';

import RestApi from '../../../helpers/api';

const api = new RestApi('risk-tracker/get-reviews-list');
const apiTimeline = new RestApi('risk-tracker/rt-timeline');

export function* loadRiskRequest() {
  yield takeEvery(actions.LOAD_RISK_REQUEST, function*({ payload }) {
    const { currentPage, currentType, date, subScoreId, companyId } = payload;

    try {
      const response = yield api.load({
        date: moment(date, 'YYYYMM').format('YYYYMM'),
        page: currentPage,
        type: currentType,
        subScoreId,
        companyId,
      });

      if (response.status === 'success') {
        const { reviews, total } = response.data;

        yield put(
          actions.loadRiskSuccess({
            reviews,
            total,
          }),
        );
      } else {
        console.log('response error', response.error);
      }
    } catch (e) {
      console.log('loadRiskRequest', e);
    }
  });
}

export function* loadTimelineRequest() {
  yield takeEvery(actions.LOAD_TIMELINE_REQUEST, function*({ payload }) {
    const { companyId } = payload;

    try {
      const response = yield apiTimeline.load({
        companyId,
      });

      if (response.status === 'success') {
        const risks = response.data;

        yield put(
          actions.loadTimelineSuccess({
            risks,
          }),
        );
      } else {
        console.log('loadRiskRequest response', response.error);
      }
    } catch (e) {
      console.log('loadTimelineRequest', e);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(loadRiskRequest), fork(loadTimelineRequest)]);
}
