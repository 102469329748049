import ReduxSagaFirebase from 'redux-saga-firebase';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/storage';
import { rollbar } from './rollbar';
import { firebaseConfig } from '../settings';
import { getAuthData, saveAuthData } from '../helpers/auth';
import actions from './auth/actions';
import welcomeActions from '../containers/Welcome/actions';
import { store } from './store';

const firebaseApp = firebase.initializeApp(firebaseConfig);
firebase.analytics();
const analytics = firebase.analytics();
const storage = firebase.storage();
const db = firebaseApp.firestore();

firebase.auth().onIdTokenChanged(function(user) {
  if (user) {
    user.getIdTokenResult(true).then(function(idTokenResult) {
      const authData = getAuthData() || {};
      authData.claims = idTokenResult.claims;
      authData.idToken = idTokenResult.token;
      saveAuthData(authData);
      const { companyId, email, locationId, role, user_id } = idTokenResult.claims;
      analytics.setUserId(user_id);
      analytics.setUserProperties({ role, email, companyId, region: locationId, companyType: role });

      if (rollbar) {
        rollbar.configure({
          payload: {
            person: {
              id: user_id,
              username: role,
              email,
            },
          },
        });
      }

      store.dispatch(actions.loginSuccess(authData));
      store.dispatch(welcomeActions.clearState());
    });
  }
});

const reduxSagaFirebase = new ReduxSagaFirebase(firebaseApp);

export { reduxSagaFirebase, firebase, analytics, storage, db };
