import actions from './actions';
import { createReducer } from '../../../helpers/reducer';

// ------------------------------
// Initial State
// ------------------------------
const initState = {
  historicalOverview: '',
  historicalChartView: 3,
  card: null,
  piesData: null,
  risk: null,
  risks: null,
  diagrams: null,
  diagrams_1: null,
  diagrams_6: null,
  loading: false,
};

// ------------------------------
// Action Handlers
// ------------------------------
function onLoadRiskSuccess(state, { payload }) {
  const { card, piesData, risk, diagrams, diagrams_1, diagrams_6, historicalChartView } = payload;

  return {
    ...state,
    card,
    piesData,
    risk,
    diagrams,
    diagrams_1,
    diagrams_6,
    historicalChartView,
  };
}

function onSwitchChartView(state, { payload }) {
  const { historicalChartView } = payload;

  return {
    ...state,
    historicalChartView,
  };
}

function onLoadTimelineSuccess(state, { payload }) {
  const { risks } = payload;

  return {
    ...state,
    risks,
  };
}

function onLoadError(state) {
  return {
    ...state,
    isLoading: false,
  };
}

function onClear() {
  return {
    initState,
  };
}

// ------------------------------
// Exported reducer function.
// ------------------------------
export default createReducer(initState, {
  [actions.LOAD_RISK_SUCCESS]: onLoadRiskSuccess,
  [actions.LOAD_TIMELINE_SUCCESS]: onLoadTimelineSuccess,
  [actions.LOAD_ERROR]: onLoadError,
  [actions.SWITCH_CHART_VIEW]: onSwitchChartView,
  [actions.CLEAR]: onClear,
});
