import actions from './actions';
import { createReducer } from '../../../helpers/reducer';

// ------------------------------
// Initial State
// ------------------------------
const initState = {
  products: null,
  count: 0,
  error: '',
  loading: false,
  addNewService: {
    logo: null,
    serviceName: null,
    category: undefined,
    subcategory: undefined,
    heading: undefined,
    keyword: null,
    keywords: [],
    defaultKeywords: [],
    description: null,
    attachments: [],
  },
  progress: 0,
  fileIsUploading: false,
  imageIsUploading: false,
  uploaded: false,
  attachmentError: null,
  videoError: null,
  videoUrl: null,
};

// ------------------------------
// Action Handlers
// ------------------------------
function onLoadProductsSuccess(state, { payload }) {
  const { count, data, page } = payload;
  const products = page === 1 ? data : [...state.products, ...data];

  return {
    ...state,
    products,
    count,
    error: '',
    loading: false,
  };
}

function onLoadProductsError(state, { payload }) {
  return {
    ...state,
    error: payload.error,
    isLoading: false,
  };
}

function onLoadProducts(state) {
  return {
    ...state,
    error: '',
    loading: true,
  };
}

function onChangeProperty(state, { payload }) {
  const { attr, value, field } = payload;

  if (field) {
    state[field][attr] = value;
  } else {
    state[attr] = value;
  }

  return { ...state };
}

function onClearServiceState(state) {
  return {
    ...state,
    addNewService: {
      logo: null,
      serviceName: null,
      category: undefined,
      subcategory: undefined,
      heading: undefined,
      keyword: null,
      keywords: [],
      description: null,
      videos: [],
      files: [],
      attachments: [],
    },
  };
}

function onFileUploading(state) {
  return {
    ...state,
    fileIsUploading: true,
    error: '',
  };
}

function onFileUploadError(state, { payload }) {
  return {
    ...state,
    error: payload.error,
    fileIsUploading: false,
  };
}

function onFileUploadSuccess(state) {
  return {
    ...state,
    fileIsUploading: false,
    uploaded: true,
    attachmentError: null,
    videoError: null,
    error: '',
  };
}

function sendLink(state, { payload }) {
  return {
    ...state,
    addNewService: {
      ...state.addNewService,
      attachments: [...state.addNewService.attachments, payload],
    },
  };
}

// ------------------------------
// Exported reducer function.
// ------------------------------
export default createReducer(initState, {
  [actions.LOAD_PRODUCTS_SUCCESS]: onLoadProductsSuccess,
  [actions.LOAD_PRODUCTS_ERROR]: onLoadProductsError,
  [actions.LOAD_PRODUCTS_REQUEST]: onLoadProducts,
  [actions.CHANGE_PROPERTY]: onChangeProperty,
  [actions.CLEAR_SERVICE_STATE]: onClearServiceState,
  [actions.FILE_UPLOADING]: onFileUploading,
  [actions.FILE_UPLOAD_ERROR]: onFileUploadError,
  [actions.FILE_UPLOAD_SUCCESS]: onFileUploadSuccess,
  [actions.SEND_LINK]: sendLink,
});
