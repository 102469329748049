import { all, put, takeEvery, fork } from 'redux-saga/effects';
import actions from './actions';
import RestApi from '../../helpers/api';
import settings from '../../settings';

const apiCompanies = new RestApi('packs/companies-for-pack');
const apiSetCompany = new RestApi('packs/set-pack-company');
const apiChangeCompany = new RestApi('packs/edit-pack-company');
const api = new RestApi('packs/delete-history');
const apiRunCompany = new RestApi('packs/run-companies');
const apiBuyCredit = new RestApi('packs/set-extra-credits');
const apiDelComp = new RestApi('packs/delete-company');

export function* runCompanyRequest() {
  yield takeEvery(actions.RUN_COMPANY_REQUEST, function*({ payload }) {
    try {
      const { userId, packId, companyId, companyDocumentId } = payload;

      const response = yield apiRunCompany.createItemCustom(`${settings.apiAiUrl}`, {
        userId,
        packId,
        companyId,
        companyDocumentId,
      });

      if (response.status === 'success') {
        console.log('run company success');
      } else if (response.status === 'error') {
        console.log('run company error');
      }
    } catch (e) {
      console.log('loadRiskRequest error', e);
    }
  });
}

export function* loadBuyCreditRequest() {
  yield takeEvery(actions.LOAD_BUY_CREDIT_REQUEST, function*({ payload }) {
    const { price = 10, count = 5 } = payload;

    try {
      const response = yield apiBuyCredit.createItemCustom(`${settings.apiAiUrl}`, {
        price,
        count,
      });

      if (response.status === 'success') {
        yield put(actions.loadBuyCreditSuccess());
      } else {
        console.log('loadBuyCreditRequest response', response.error);
      }
    } catch (e) {
      console.log('loadBuyCreditRequest', e);
    }
  });
}

export function* loadCompaniesRequest() {
  yield takeEvery(actions.LOAD_COMPANIES_REQUEST, function*({ payload }) {
    try {
      const { companyName, userId, packId, existCompanies = [], companyDocumentId } = payload;

      const response = yield apiCompanies.loadCustom(`${settings.apiAiUrl}`, {
        companyName,
        userId,
        packId,
        existCompanies,
        companyDocumentId,
      });

      if (response.status === 'success') {
        yield put(
          actions.loadCompaniesSuccess({
            companiesList: response.data.companies,
          }),
        );
      } else if (response.status === 'error') {
        actions.loadCompaniesError({
          companiesList: [],
        });
      }
    } catch (e) {
      console.log('loadRiskRequest error', e);
    }
  });
}

export function* loadSetCompanyRequest() {
  yield takeEvery(actions.LOAD_SET_COMPANY_REQUEST, function*({ payload }) {
    const {
      userId,
      packId,
      targetIds,
      targetNames,
      scheduleOption,
      schedulePeriod,
      dataPeriod,
      runNow,
      companyDocumentId,
    } = payload;

    try {
      const response = yield apiSetCompany.createItemCustom(`${settings.apiAiUrl}`, {
        companyDocumentId,
        targetIds,
        packId,
        userId,
        generatingType: scheduleOption,
        schedule: scheduleOption !== 'manually' ? schedulePeriod : 'manually',
        dataPeriod,
        targetNames,
        runNow,
      });

      if (response.status === 'success') {
        try {
          const { userId, packId, existCompanies = [] } = payload;

          const response = yield apiCompanies.loadCustom(`${settings.apiAiUrl}`, {
            companyName: '',
            userId,
            packId,
            existCompanies,
          });

          if (response.status === 'success') {
            yield put(
              actions.loadCompaniesSuccess({
                companiesList: response.data.companies,
              }),
            );
          } else if (response.status === 'error') {
            actions.loadCompaniesError({
              companiesList: [],
            });
          }
        } catch (e) {
          console.log('loadRiskRequest error', e);
        }
      } else {
        console.log('loadRiskRequest response', response.error);
      }
    } catch (e) {
      console.log('loadTimelineRequest', e);
    }
  });
}

export function* changeCompanyRequest() {
  yield takeEvery(actions.CHANGE_COMPANY_REQUEST, function*({ payload }) {
    const {
      userId,
      packId,
      scheduleOption,
      schedulePeriod,
      dataPeriod,
      runNow,
      companyDocumentId,
      companyId,
    } = payload;

    try {
      const response = yield apiChangeCompany.createItemCustom(`${settings.apiAiUrl}`, {
        companyDocumentId,
        companyId,
        packId,
        userId,
        generatingType: scheduleOption,
        schedule: scheduleOption !== 'manually' ? schedulePeriod : 'manually',
        dataPeriod,
        runNow,
      });

      if (response.status === 'success') {
        console.log(response);
      } else {
        console.log('loadRiskRequest response', response.error);
      }
    } catch (e) {
      console.log('loadTimelineRequest', e);
    }
  });
}

export function* deleteHistoryCompanyRequest() {
  yield takeEvery(actions.DELETE_HISTORY_COMPANY_REQUEST, function*({ payload }) {
    const { userId, packId, historyId, companyDocumentId } = payload;

    try {
      const response = yield api.deleteItemCustom(`${settings.apiAiUrl}`, {
        userId,
        packId,
        historyId,
        companyDocumentId,
      });

      if (response.status === 'success') {
        // yield put(
        //   actions.loadAnnieSuccess({
        //     annieLoading: false,
        //   }),
        // );
      } else {
        console.log('loadRiskRequest response', response.error);
      }
    } catch (e) {
      console.log('loadTimelineRequest', e);
    }
  });
}

export function* deleteCompanyRequest() {
  yield takeEvery(actions.DELETE_COMPANY_REQUEST, function*({ payload }) {
    const { companyId, packId, companyDocumentId } = payload;

    try {
      const response = yield apiDelComp.deleteItemCustom(`${settings.apiAiUrl}`, {
        companyId,
        packId,
        companyDocumentId,
      });

      if (response.status === 'success') {
        // yield put(
        //   actions.loadAnnieSuccess({
        //     annieLoading: false,
        //   }),
        // );
      } else {
        console.log('loadRiskRequest response', response.error);
      }
    } catch (e) {
      console.log('loadTimelineRequest', e);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(loadCompaniesRequest),
    fork(loadSetCompanyRequest),
    fork(deleteHistoryCompanyRequest),
    fork(runCompanyRequest),
    fork(changeCompanyRequest),
    fork(loadBuyCreditRequest),
    fork(deleteCompanyRequest),
  ]);
}
