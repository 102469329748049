const profileActions = {
  QUOTE_REQUEST: 'QUOTE_REQUEST',
  SEND_PROFILE_INFO_SUCCESS: 'SEND_PROFILE_INFO_SUCCESS',
  QUOTE_ERROR: 'QUOTE_ERROR',
  PROFILE_INFO_CLEAR: 'PROFILE_INFO_CLEAR',
  SET_EDITABLE: 'SET_EDITABLE',
  SET_EXISTED_VALUES: 'SET_EXISTED_VALUES',
  SEND_PROFILE_INFO: 'SEND_PROFILE_INFO',
  SEND_ATTACHMENTS: 'SEND_ATTACHMENTS',
  CHANGE_PROPERTY: 'CHANGE_PROPERTY',
  FILE_UPLOADING: 'FILE_UPLOADING',
  FILE_UPLOAD_ERROR: 'FILE_UPLOAD_ERROR',
  FILE_UPLOAD_SUCCESS: 'FILE_UPLOAD_SUCCESS',
  DELETE_ATTACHMENT: 'DELETE_ATTACHMENT',
  GET_ATTACHMENTS: 'GET_ATTACHMENTS',
  GET_ATTACHMENTS_SUCCESS: 'GET_ATTACHMENTS_SUCCESS',

  sendProfile: data => ({
    type: profileActions.QUOTE_REQUEST,
    payload: data,
  }),
  sendProfileInfoSuccess: () => ({
    type: profileActions.SEND_PROFILE_INFO_SUCCESS,
  }),
  quoteError: data => ({
    type: profileActions.QUOTE_ERROR,
    payload: data,
  }),
  profileInfoClear: () => ({
    type: profileActions.PROFILE_INFO_CLEAR,
  }),
  sendUserPreferences: data => ({
    type: profileActions.SEND_USER_PREFERENCES,
    payload: data,
  }),
  setEditable: () => ({
    type: profileActions.SET_EDITABLE,
  }),
  sendProfileInfo: data => ({
    type: profileActions.SEND_PROFILE_INFO,
    payload: data,
  }),
  sendAttachments: data => ({
    type: profileActions.SEND_ATTACHMENTS,
    payload: data,
  }),
  getAttachments: data => ({
    type: profileActions.GET_ATTACHMENTS,
    payload: data,
  }),
  getAttachmentsSuccess: data => ({
    type: profileActions.GET_ATTACHMENTS_SUCCESS,
    payload: data,
  }),
  onSetExistedValues: data => ({
    type: profileActions.SET_EXISTED_VALUES,
    payload: data,
  }),
  changeProperty: data => ({
    type: profileActions.CHANGE_PROPERTY,
    payload: data,
  }),
  fileUploadSuccess: data => ({
    type: profileActions.FILE_UPLOAD_SUCCESS,
    payload: data,
  }),
  fileUploadError: data => ({
    type: profileActions.FILE_UPLOAD_ERROR,
    payload: data,
  }),
  fileIsUploading: data => ({
    type: profileActions.FILE_UPLOADING,
    payload: data,
  }),
  deleteAttachment: data => ({
    type: profileActions.DELETE_ATTACHMENT,
    payload: data,
  }),
};
export default profileActions;
