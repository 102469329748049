const categoryViewActions = {
  CATEGORY_LOAD: 'CATEGORY_LOAD',
  CATEGORY_LOAD_SUCCESS: 'CATEGORY_LOAD_SUCCESS',
  CATEGORY_LOAD_ERROR: 'CATEGORY_LOAD_ERROR',
  CLEAR_STATE: 'CLEAR_STATE',
  CHANGE_PROPERTY: 'CHANGE_PROPERTY',
  CLEAR_FORM: 'CLEAR_FORM',
  SEND_PROVIDER: 'SEND_PROVIDER',
  SEND_PROVIDER_SUCCESS: 'SEND_PROVIDER_SUCCESS',
  SEND_PROVIDER_ERROR: 'SEND_PROVIDER_ERROR',

  load: data => ({
    type: categoryViewActions.CATEGORY_LOAD,
    payload: data,
  }),

  loadSuccess: data => ({
    type: categoryViewActions.CATEGORY_LOAD_SUCCESS,
    payload: data,
  }),

  loadError: data => ({
    type: categoryViewActions.CATEGORY_LOAD_ERROR,
    payload: data,
  }),

  clearState: () => ({
    type: categoryViewActions.CLEAR_STATE,
  }),

  changeProperty: data => ({
    type: categoryViewActions.CHANGE_PROPERTY,
    payload: data,
  }),

  clearForm: () => ({
    type: categoryViewActions.CLEAR_FORM,
  }),

  sendNewProvider: data => ({
    type: categoryViewActions.SEND_PROVIDER,
    payload: data,
  }),

  loadCompanies: data => ({
    type: categoryViewActions.LOAD_COMPANIES_REQUEST,
    payload: data,
  }),

  loadCompaniesSuccess: data => ({
    type: categoryViewActions.LOAD_COMPANIES_SUCCESS,
    payload: data,
  }),

  loadCompaniesError: data => ({
    type: categoryViewActions.LOAD_COMPANIES_ERROR,
    payload: data,
  }),
};

export default categoryViewActions;
