export default {
  apiUrl: process.env.REACT_APP_API_URL,
  apiAiUrl: process.env.REACT_APP_API_AI_URL,
  communityUrl: process.env.REACT_APP_COMMUNITY_URL,
};

const siteConfig = {
  siteName: 'Aniline',
  siteIcon: 'ion-flash',
  footerText: `Aniline ©${new Date().getFullYear() || ''}`,
  upgradeAniline: 'https://www.aniline.io/service-providers-upgrade',
  termsOfService:
    'https://firebasestorage.googleapis.com/v0/b/aniline-io/o/Aniline%20-%20Service%20Provider%20Marketplace%20Terms%20of%20Service%20FINAL.pdf?alt=media&token=f34cc571-6d5b-4c17-b1a7-876ff963f6a3',
  termsAndConditions: {
    provider:
      'https://firebasestorage.googleapis.com/v0/b/aniline-io/o/Aniline%20-%20Service%20Provider%20Marketplace%20Terms%20of%20Service%20FINAL.pdf?alt=media&token=f34cc571-6d5b-4c17-b1a7-876ff963f6a3',
    hr:
      'https://firebasestorage.googleapis.com/v0/b/aniline-io/o/Aniline%20-%20Member%20Terms%20of%20Service%20FINAL.pdf?alt=media&token=89a9dd5a-aeae-4e97-84ce-b02f9558730e',
    privacy:
      'https://firebasestorage.googleapis.com/v0/b/aniline-io/o/Aniline%20-%20Privacy%20Policy%20FINAL.pdf?alt=media&token=96b77d4b-8a0c-421a-b771-7c07d9019bbe',
  },
  catalog:
    'https://firebasestorage.googleapis.com/v0/b/aniline-io/o/Aniline%20Catalog.pdf?alt=media&token=1fa70d8d-36a1-484d-8836-e665a81786c0',
  demo: 'https://meetings.hubspot.com/james1046',
  pricing: 'https://f.hubspotusercontent30.net/hubfs/7596486/aniline%20service%20provider%20pricing%20summary-1.pdf',
};
const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'themedefault',
};
const language = 'english';

const jwtConfig = {
  fetchUrl: '/api/',
  secretKey: 'secretKey',
};

const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);

const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true,
  enableClaims: true,
  /* profileParamsToPopulate: [
    { child: 'role', root: 'roles' }, // populates user's role with matching role object from roles
  ] */
};

const signInTypes = {
  GOOGLE: 'GOOGLE',
  FACEBOOK: 'FACEBOOK',
  EMAIL: 'EMAIL',
  TOKEN: 'TOKEN',
};

const format = {
  date: 'MMM DD, YYYY',
  dateTime: 'MMM DD, YYYY, (hh:mm A z)',
  time: 'hh:mm A z',
};

const roles = ['hr', 'broker', 'provider'];

const dataListConfig = { PAGE_SIZE: 30 };

const anilineCompanyIds = ['ca12c3a9-f0cd-4dc6-a87a-331aafca94ab', '81f2cbbc-4495-45ed-bf36-c9b28e4d212d'];

const scoreDesc = [
  {
    score: 'Workplace',
    text:
      'The Workplace score reflects employee sentiment concerning the overall work environment: physical, virtual and cultural. It includes such aspects as collegiality, collaboration, teamwork, physical workspace and worker safety.',
  },
  {
    score: 'Diversity, Equity & Inclusion',
    text:
      'The Diversity, Equity and Inclusion score reflects employee sentiment in areas such as: DEI policy, execution and commitment to women, minorities, LGBTQ and other marginalized groups in providing a fair, equitable and respectful workplace based on merit.',
  },
  {
    score: 'Career',
    text:
      "The Career score is based on employee sentiment concerning how employees perceive the company's environment regarding career development, advancement opportunities, growth, learning and training.",
  },
  {
    score: 'Work Life Balance',
    text:
      'The Work Life Balance score reflects employee sentiment regarding work life balance. It measures how well the company allows for adequate time to disconnect from work responsibilities for family and personal interests, to refresh and recharge.',
  },
  {
    score: 'Benefits',
    text:
      'The Benefits score reflects employee sentiment concerning how people feel about the value and adequacy of the employee benefit programs to meet their needs throughout their life cycle.',
  },
  {
    score: 'Compensation',
    text:
      'The Compensation score reflects employee sentiment concerning the adequacy, fairness and competitiveness of the compensation programs. Do they reflect a fair value exchange for work and contribution to value?',
  },
  {
    score: 'Hiring',
    text:
      'The Hiring score reflects employee sentiment regarding the hiring process. Was it fair and efficient? Was the job ultimately reflective of the description once they were hired?',
  },
  {
    score: 'Integrity',
    text:
      "The Integrity score reflects employee sentiment about the company's cultural DNA concerning trustworthiness, honesty, and fairness in the way it treats its people, vendors, suppliers, and partners. Around 50 is neutral, and 100 is most favorable. You can see how your company compares to all others in your industry. We encourage you to explore our Integrity Portfolio - a curated collection of products that helps you navigate the market and improve your company's Integrity.\n",
  },
  {
    score: 'Leadership',
    text:
      'The Leadership score is based upon employee sentiment regarding how leaders are perceived concerning all aspects of leadership including vision, communication, execution, and professionalism for line management and C-suite executives.',
  },
];

const subScoresDesc = [
  {
    id: 'd91a6270-5753-11ec-bc36-42010aa80003',
    title: 'Administration & Communication',
    description:
      'The Administration & Communication score, a component of the Benefits metric, measures how employees perceive the processing, messaging, and delivery of employee benefits and perquisite programs. ',
    shortDescription: 'Processing, messaging, delivery of employee benefits',
    order_num: 1,
  },
  {
    id: 'd91a61e4-5753-11ec-bc36-42010aa80003',
    title: 'Perquisites',
    description:
      'The Perquisites  score, a component of the Benefits metric, measures how employees perceive the availability and quality of fringe benefits such as office snacks, employee discounts, and events. Allowances and reimbursements are included here.  ',
    shortDescription: 'Fringe benefits, allowances, discounts, events',
    order_num: 2,
  },
  {
    id: 'd91a6158-5753-11ec-bc36-42010aa80003',
    title: 'Retirement',
    description:
      'The Retirement  score, a component of the Benefits metric, measures how employees perceive the availability and level of retirement benefits such as pensions and capital accumulation programs. Welfare benefits provided during retirement are included here.',
    shortDescription: 'Defined benefit, defined contribution, savings/matching and retiree welfare programs',
    order_num: 3,
  },
  {
    id: 'd91a6040-5753-11ec-bc36-42010aa80003',
    title: 'Welfare Cost',
    description:
      'The Welfare Cost score, a component of the Benefits metric, measures how employees perceive the amounts paid by employees for benefits such as health, life, and disability insurance coverages. Premium-sharing as well as deductibles and coinsurance are included here.',
    shortDescription: 'Employee premiums, deductibles, out-of-pocket costs for welfare benefits',
    order_num: 4,
  },
  {
    id: 'd91a60c2-5753-11ec-bc36-42010aa80003',
    title: 'Welfare Design',
    description:
      'The Welfare Design score, a component of the Benefits metric, measures how employees perceive the availability, breadth, and quality of benefits such as health, life, and disability insurance coverages. Exclusions and waiting periods are included here.',
    shortDescription: 'Coverage, eligibility, flexibility of employee benefits programs',
    order_num: 5,
  },
  {
    id: 'd91a62fc-5753-11ec-bc36-42010aa80003',
    title: 'General',
    description:
      'The General score, a component of the Benefits metric, measures how employees perceive benefits programs overall where no specific context is detected.',
    shortDescription: 'Non-specific perception of employee benefits programs',
    order_num: 999,
  },

  {
    id: 'd91a68d8-5753-11ec-bc36-42010aa80003',
    title: 'Contribution',
    description:
      'The Contribution score, a component of the Career metric, measures how employees perceive their value to the organization. Topics such as recognition and encouragement are included here, together with providing input and related interactions with colleagues.',
    shortDescription: 'Recognition, impact, feeling valued, having a voice',
    order_num: 1,
  },
  {
    id: 'd91a6a86-5753-11ec-bc36-42010aa80003',
    title: 'Labor/Union',
    description:
      'The Labor/Union score, a component of the Career metric, measures how employees perceive labor relations and the impact of labor unions, works councils, and similar organizations.',
    shortDescription: 'Labor unions, works councils, collective bargaining and organization efforts',
    order_num: 2,
  },
  {
    id: 'd91a69fa-5753-11ec-bc36-42010aa80003',
    title: 'Mobility',
    description:
      'The Mobility score, a component of the Career metric, measures how employees perceive the ability to work in different physical locations through transfer programs and similar arrangements.',
    shortDescription: 'Relocation, transfer, secondment',
    order_num: 3,
  },
  {
    id: 'd91a67c0-5753-11ec-bc36-42010aa80003',
    title: 'Opportunity',
    description:
      'The Opportunity score, a component of the Career metric, measures how employees perceive career growth prospects such as promotion and advancement. Job security and related topics are included here.',
    shortDescription: 'Career growth and advancement, job security',
    order_num: 4,
  },
  {
    id: 'd91a6964-5753-11ec-bc36-42010aa80003',
    title: 'Purpose',
    description:
      'The Purpose score, a component of the Career metric, measures how employees perceive their sense of fulfillment and satisfaction.',
    shortDescription: 'Fulfillment, satisfaction, inspiration',
    order_num: 5,
  },
  {
    id: 'd91a6842-5753-11ec-bc36-42010aa80003',
    title: 'Training & Development',
    description:
      'The Training & Development score, a component of the Career metric, measures how employees perceive learning and skill development programs and opportunities, including performance appraisal processes.',
    shortDescription: 'Learning, gaining skills and experience, performance reviews',
    order_num: 6,
  },
  {
    id: 'd91a6b12-5753-11ec-bc36-42010aa80003',
    title: 'General',
    description:
      'The General score, a component of the Career metric, measures how employees perceive career prospects overall where no specific context is detected.',
    shortDescription: 'Non-specific perception of career experience',
    order_num: 999,
  },

  {
    id: 'd91a5e7e-5753-11ec-bc36-42010aa80003',
    title: 'Equity/Stock',
    description:
      'The Equity/Stock score, a component of the Compensation metric, measures how employees perceive the ability to acquire equity through share and option programs.',
    shortDescription: 'Shares, options, equity',
    order_num: 1,
  },
  {
    id: 'd91a5c4e-5753-11ec-bc36-42010aa80003',
    title: 'Internal Equity',
    description:
      'The Internal Equity score, a component of the Compensation metric, measures how employees perceive the distribution of cash rewards across the organization, including pay increases and payroll dependability. ',
    shortDescription: 'Fair distribution of cash rewards and pay increases, payroll dependability',
    order_num: 2,
  },
  {
    id: 'd91a5a96-5753-11ec-bc36-42010aa80003',
    title: 'Market Adequacy',
    description:
      'The Market Adequacy score, a component of the Compensation metric, measures how employees perceive the level of compensation provides with respect to the labor market and peer roles in other organizations.',
    shortDescription: 'Prevailing pay levels for similar roles and/or organizations',
    order_num: 3,
  },
  {
    id: 'd91a5f14-5753-11ec-bc36-42010aa80003',
    title: 'Overtime & Shift Differential',
    description:
      'The Overtime & Shift Differential score, a component of the Compensation metric, measures how employees perceive cash payments for extended hours and premium shift work, as well as the availability to access them.',
    shortDescription: 'Levels of, and access to, premium payments for working extended and/or odd hours',
    order_num: 4,
  },
  {
    id: 'd91a5df2-5753-11ec-bc36-42010aa80003',
    title: 'Pay mix',
    description:
      'The Pay Mix score, a component of the Compensation metric, measures how employees perceive the compensation package in terms of its components and structure.',
    shortDescription: 'Pay structure, fixed versus variable pay, and compensation policy',
    order_num: 5,
  },
  {
    id: 'd91a5d5c-5753-11ec-bc36-42010aa80003',
    title: 'Performance Related',
    description:
      'The Performance Related score, a component of the Compensation metric, measures how employees perceive how their cash rewards relate to personal performance. Bonus payments, sales commissions, and other incentives are included here.',
    shortDescription: 'Incentive payment levels and attainability, sales quotas, effort for pay received',
    order_num: 6,
  },
  {
    id: 'd91a5faa-5753-11ec-bc36-42010aa80003',
    title: 'General',
    description:
      'The General score, a component of the Compensation metric, measures how employees perceive compensation overall where no specific context is detected.',
    shortDescription: 'Non-specific perception of compensation programs',
    order_num: 999,
  },
  {
    id: 'd91a58de-5753-11ec-bc36-42010aa80003',
    title: 'Fairness',
    description:
      "The Fairness score, a component of the DE&I metric, measures how employees perceive equality, respect and bias in the workplace as it relates to one's identity.",
    shortDescription: "Fairness, respect, and bias based on one's identity",
    order_num: 1,
  },
  {
    id: 'd91a56ea-5753-11ec-bc36-42010aa80003',
    title: 'Gender',
    description:
      "The Gender score, a component of the DE&I metric, measures how employees perceive the work experience based on one's gender identity.",
    shortDescription: 'Sexism, gender roles and representation',
    order_num: 2,
  },
  {
    id: 'd91a5852-5753-11ec-bc36-42010aa80003',
    title: 'Inclusion',
    description:
      'The Inclusion score, a component of the DE&I metric, measures how employees perceive a sense of welcome and belonging among colleagues.',
    shortDescription: 'Welcoming, belonging, compassion in work relationships',
    order_num: 3,
  },
  {
    id: 'd91a5776-5753-11ec-bc36-42010aa80003',
    title: 'LGBTQ',
    description:
      'The LGBTQ score, a component of the DE&I metric, measures how employees perceive the acceptance of sexual preference and/or alternative lifestyles.',
    shortDescription: 'Acceptance of sexual preferences and/or alternative lifestyles',
    order_num: 4,
  },
  {
    id: 'd91a565e-5753-11ec-bc36-42010aa80003',
    title: 'Race/Origin',
    description:
      'The Race/Origin score, a component of the DE&I metric, measures how employees perceive relations among people of different racial, religious, geographic, and demographic identities and backgrounds.',
    shortDescription: 'Relations by race, religion, national origin, age',
    order_num: 5,
  },
  {
    id: 'd91a596a-5753-11ec-bc36-42010aa80003',
    title: 'Support',
    description:
      'The Support score, a component of the DE&I metric, measures how employees perceive efforts by the employer to provide the means for a diverse workforce to thrive. ',
    shortDescription: 'The promotion of values related to DE&I by the employer through focused programs and events',
    order_num: 6,
  },
  {
    id: 'd91a59f6-5753-11ec-bc36-42010aa80003',
    title: 'General',
    description:
      'The General score, a component of the DE&I metric, measures how employees perceive diversity, equity & inclusion overall where no specific context is detected.',
    shortDescription: 'Non-specific perception of DE&I topics',
    order_num: 999,
  },
  {
    id: 'd91a645a-5753-11ec-bc36-42010aa80003',
    title: 'Application Process',
    description:
      'The Application Process score, a component of the Hiring Experience metric, measures how employees perceive the earliest stages of recruitment into the organization.',
    shortDescription: 'Recruitment and employment information processes and events',
    order_num: 1,
  },
  {
    id: 'd91a6586-5753-11ec-bc36-42010aa80003',
    title: 'Follow-up',
    description:
      'The Follow-up score, a component of the Hiring Experience metric, measures how employees perceive the interactions that occur during recruitment such as feedback and management of the process.',
    shortDescription: 'Feedback, interactions between recruitment events, responsiveness',
    order_num: 2,
  },
  {
    id: 'd91a64f0-5753-11ec-bc36-42010aa80003',
    title: 'Interview Process',
    description:
      'The Interview Process score, a component of the Hiring Experience metric, measures how employees perceive the format and content of interactions with persons in the talent acquisition function, including line managers and peers.',
    shortDescription:
      'Screening and assessment interactions with talent acquisition personnel, line management, and peers.',
    order_num: 3,
  },
  {
    id: 'd91a6612-5753-11ec-bc36-42010aa80003',
    title: 'Offer Terms',
    description:
      'The Offer Terms score, a component of the Hiring Experience metric, measures how employees perceive the employment offer they receive in terms of the compensation, work schedule, and worksite offered for employment',
    shortDescription: 'Compensation, work schedule, and work site at hire',
    order_num: 4,
  },
  {
    id: 'd91a669e-5753-11ec-bc36-42010aa80003',
    title: 'Onboarding',
    description:
      'The Onboarding score, a component of the Hiring Experience metric, measures how employees perceive the actions taken by the employer to assimilate into the organization upon hire.',
    shortDescription: 'Getting started with, and assimilation into, the company at hire',
    order_num: 5,
  },
  {
    id: 'd91a63d8-5753-11ec-bc36-42010aa80003',
    title: 'Role Description',
    description:
      'The Role Description score, a component of the Hiring Experience metric, measures how employees perceive the role and responsibility of the job offered, including relevant post-hire comparison.',
    shortDescription: 'Position, role, and responsibility offered, plus post-hire comparison to offer',
    order_num: 6,
  },
  {
    id: 'd91a6734-5753-11ec-bc36-42010aa80003',
    title: 'General',
    description:
      'The General score, a component of the Hiring Experience metric, measures how employees perceive the overall hiring process where no specific context is detected.',
    shortDescription: 'Non-specific perception of hiring topics',
    order_num: 999,
  },
  {
    id: 'd91a4d3a-5753-11ec-bc36-42010aa80003',
    title: 'Commitment',
    description:
      "The Commitment score, a component of the Integrity metric, measures how employees perceive the organization's duty to provide resources that support the mission and contribute to employee well-being. ",
    shortDescription: 'Duty, resources, employee well-being',
    order_num: 1,
  },
  {
    id: 'd91a4b64-5753-11ec-bc36-42010aa80003',
    title: 'Compliance',
    description:
      "The Compliance score, a component of the Integrity metric, measures how employees perceive the organization's practices, policies, and governance as well as adherence to legal and regulatory requirements.",
    shortDescription: 'Practices, policies, governance, legal/regulatory adherence',
    order_num: 2,
  },
  {
    id: 'd91a4a10-5753-11ec-bc36-42010aa80003',
    title: 'Ethics',
    description:
      "The Ethics score, a component of the Integrity metric, measures how employees perceive the organization's business practices and ethical conduct.",
    shortDescription: 'Business practices, ethical conduct, conscience',
    order_num: 3,
  },
  {
    id: 'd91a3a8e-5753-11ec-bc36-42010aa80003',
    title: 'Honesty',
    description:
      "The Honesty score, a component of the Integrity metric, measures how employees perceive the organization's truthfulness and authenticity. ",
    shortDescription: 'Truthfulness, authenticity, credibility',
    order_num: 4,
  },
  {
    id: 'd91a4c0e-5753-11ec-bc36-42010aa80003',
    title: 'Mission',
    description:
      'The Mission score, a component of the Integrity metric, measures how employees perceive the goals of the organization, including how its strategy, structure, and performance support their execution and contribution to society.',
    shortDescription: 'Goals, strategy, structure, performance, contribution to society',
    order_num: 5,
  },
  {
    id: 'd91a4ca4-5753-11ec-bc36-42010aa80003',
    title: 'Values',
    description:
      'The Values score, a component of the Integrity metric, measures how employees perceive the beliefs and attributes of the organization. ',
    shortDescription: 'Beliefs, attributes, mores',
    order_num: 6,
  },
  {
    id: 'd91a4dda-5753-11ec-bc36-42010aa80003',
    title: 'General',
    description:
      'The General score, a component of the Integrity metric, measures how employees perceive the integrity of the organization where no specific context is detected.',
    shortDescription: 'Non-specific perception of integrity',
    order_num: 999,
  },
  {
    id: 'd91a501e-5753-11ec-bc36-42010aa80003',
    title: 'Behavior',
    description:
      'The Behavior score, a component of the Leadership metric, measures how employees perceive attitudes, decisions, and guidance from management personnel.',
    shortDescription: 'Attitudes, decisions, and guidance from management personnel',
    order_num: 1,
  },
  {
    id: 'd91a4f92-5753-11ec-bc36-42010aa80003',
    title: 'Communication',
    description:
      'The Communication score, a component of the Leadership metric, measures how employees perceive the form and content of information sharing by and with management personnel. ',
    shortDescription: 'Information sharing, messaging, listening',
    order_num: 2,
  },
  {
    id: 'd91a50b4-5753-11ec-bc36-42010aa80003',
    title: 'Executives',
    description:
      'The Executives score, a component of the Leadership metric, measures how employees perceive the Board, C-suite executives, and senior leadership across all aspects of behavior and competency. ',
    shortDescription: 'Board, CxO, senior leadership behavior and competency ',
    order_num: 3,
  },
  {
    id: 'd91a5140-5753-11ec-bc36-42010aa80003',
    title: 'Supervisors',
    description:
      'The Supervisors score, a component of the Leadership metric, measures how employees perceive line management across all aspects of behavior and competency. ',
    shortDescription: 'Line and middle management behavior and competency',
    order_num: 4,
  },
  {
    id: 'd91a4e7a-5753-11ec-bc36-42010aa80003',
    title: 'Trust',
    description:
      'The Trust score, a component of the Leadership metric, measures how employees perceive truthfulness of and connection with management personnel.',
    shortDescription: 'Truthfulness, connection, support',
    order_num: 5,
  },
  {
    id: 'd91a4efc-5753-11ec-bc36-42010aa80003',
    title: 'Vision',
    description:
      'The Vision score, a component of the Leadership metric, measures how employees perceive the future state, and pathways to it, as delivered by management personnel.  ',
    shortDescription: 'Future state, pathway to success, planning',
    order_num: 6,
  },
  {
    id: 'd91a51cc-5753-11ec-bc36-42010aa80003',
    title: 'General',
    description:
      'The General score, a component of the Leadership metric, measures how employees perceive leadership where no specific context is detected.',
    shortDescription: 'Non-specific perception of leadership',
    order_num: 999,
  },
  {
    id: 'd91a6c34-5753-11ec-bc36-42010aa80003',
    title: 'Flexibility',
    description:
      'The Flexibility score, a component of the Work Life Balance metric, measures how employees perceive the ability to vary the workday to accommodate non-work responsibilities.  ',
    shortDescription: 'Accommodating non-work responsibilities into the workday',
    order_num: 1,
  },
  {
    id: 'd91a6b9e-5753-11ec-bc36-42010aa80003',
    title: 'Remote Work',
    description:
      'The Remote Work score, a component of the Work Life Balance metric, measures how employees perceive the ability to perform work away from company sites.  ',
    shortDescription: 'Telework and hybrid worksite practices',
    order_num: 2,
  },
  {
    id: 'd91a6d7e-5753-11ec-bc36-42010aa80003',
    title: 'Scheduling',
    description:
      'The Scheduling score, a component of the Work Life Balance metric, measures how employees perceive the  timing requirements for performing the job.',
    shortDescription: 'Shift availability and assignment',
    order_num: 3,
  },
  {
    id: 'd91a6cc0-5753-11ec-bc36-42010aa80003',
    title: 'Time Off',
    description:
      'The Time Off score, a component of the Work Life Balance metric, measures how employees perceive the policy for taking, and ability to take, time off whether for holiday, health, or personal reasons. ',
    shortDescription: 'Paid time off (PTO), medical/personal leave, holidays',
    order_num: 4,
  },
  {
    id: 'd91a6e14-5753-11ec-bc36-42010aa80003',
    title: 'Travel',
    description:
      'The Travel score, a component of the Work Life Balance metric, measures how employees perceive policy and job performance related to travel away from the workplace.  ',
    shortDescription: 'Customer site visits, overnight commitments and accomodations',
    order_num: 5,
  },
  {
    id: 'd91a6ea0-5753-11ec-bc36-42010aa80003',
    title: 'General',
    description:
      'The General score, a component of the Work Life Balance metric, measures how employees perceive work life balance where no specific context is detected.',
    shortDescription: 'Non-specific perception of work life balance',
    order_num: 999,
  },
  {
    id: 'd91a5262-5753-11ec-bc36-42010aa80003',
    title: 'Culture',
    description:
      'The Culture score, a component of the Workplace metric, measures how employees perceive the atmosphere and non-physical environment of the workplace.',
    shortDescription: 'Atmosphere, non-physical environment, stress, morale',
    order_num: 1,
  },
  {
    id: 'be602939-abe1-440a-825c-ba934a295d7d',
    title: 'Duties',
    description:
      'The Duties score, a component of the Workplace metric, measures how employees perceive the tasks required for the job including physical demands, customer interactions, and responsibilities of the role performed.',
    shortDescription: 'Responsibilities for the role, physical demands, customer interactions',
    order_num: 2,
  },
  {
    id: 'd91a541a-5753-11ec-bc36-42010aa80003',
    title: 'Location',
    description:
      'The Location score, a component of the Workplace metric, measures how employees perceive the worksite proximity and its surrounding neighborhood, including transportation availability and commuting experiences.',
    shortDescription: 'Proximity, neighborhood, ease of/access to transportation',
    order_num: 3,
  },
  {
    id: 'd91a5384-5753-11ec-bc36-42010aa80003',
    title: 'Safety/Physical Environment',
    description:
      'The Safety/Physical Environment score, a component of the Workplace metric, measures how employees perceive the worksite facility together with provision for personal and environmental health & safety while on the job.',
    shortDescription: 'Facility, maintenance, environmental and personal health and safety',
    order_num: 4,
  },
  {
    id: 'd91a52f8-5753-11ec-bc36-42010aa80003',
    title: 'Teamwork',
    description:
      'The Teamwork score, a component of the Workplace metric, measures how employees perceive the willingness of colleagues to work together towards shared goals along with their skill levels. Perceived tenure and turnover levels are included here.',
    shortDescription: 'Collaboration, skill level, staff tenure and turnover',
    order_num: 5,
  },
  {
    id: 'd91a553c-5753-11ec-bc36-42010aa80003',
    title: 'Tools & productivity',
    description:
      'The Tools & productivity score, a component of the Workplace metric, measures how employees perceive the effectiveness of equipment, systems, and work flows required to perform the job.',
    shortDescription: 'Machinery, equipment, technology, systems, work flows',
    order_num: 6,
  },
  {
    id: 'd91a54b0-5753-11ec-bc36-42010aa80003',
    title: 'Work Load',
    description:
      'The Work Load score, a component of the Workplace metric, measures how employees perceive the hours and staff levels assigned to perform the job.',
    shortDescription: 'Hours, breaks, staffing levels, amount of overtime',
    order_num: 7,
  },
  {
    id: 'd91a55d2-5753-11ec-bc36-42010aa80003',
    title: 'General',
    description:
      'The General score, a component of the Workplace metric, measures how employees perceive the workplace where no specific context is detected.',
    shortDescription: 'Non-specific perception of the workplace',
    order_num: 999,
  },
];

export {
  siteConfig,
  language,
  scoreDesc,
  subScoresDesc,
  themeConfig,
  jwtConfig,
  firebaseConfig,
  rrfConfig,
  signInTypes,
  dataListConfig,
  roles,
  format,
  anilineCompanyIds,
};
