import styled from 'styled-components';
import { palette } from 'styled-theme';

const PopoverWrapper = ComponentName => styled(ComponentName)`
  &:hover {
    cursor: pointer;
  }

  .ant-popover-inner {
    width: 500px;
    background: ${palette('primary', 0)};
  }

  .ant-popover-title {
    padding: 16px 16px 0 16px;
    font-family: 'Rubik', sans-serif;
    border: none;
    color: ${palette('text', 6)};
  }

  .ant-popover-inner-content {
    padding: 11px;
    display: flex;
    flex-direction: column;
    color: ${palette('text', 6)};
    font-weight: 500;
    & > span {
      color: ${palette('text', 6)};
      justify-self: flex-end;
      cursor: pointer;
      margin-left: auto;
      text-decoration: underline;
    }
  }

  .ant-popover-arrow {
    display: none;
  }
`;

export default PopoverWrapper;
