import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import moment from 'moment';
import actions from './actions';
import { callApi } from '../../helpers/api';
import { firebase, reduxSagaFirebase } from '../../redux/firestore';

export function* sendQuote() {
  yield takeEvery(actions.QUOTE_REQUEST, function*({ payload }) {
    try {
      const result = yield callApi('quotes', 'POST', payload);

      if (result.status === 'success') {
        yield put(actions.quoteSuccess());
      } else {
        const { error } = result;

        yield put(actions.quoteError({ error }));
      }
    } catch (e) {
      yield put(actions.quoteError({ error: e }));
    }
  });
}

export function* sendUserPreferences() {
  yield takeEvery(actions.SEND_USER_PREFERENCES, function*({ payload }) {
    try {
      const { userId } = payload;
      const timestamp = moment().format('YYYY-MM-DD h:mm:ss.SSS');

      yield call(reduxSagaFirebase.firestore.setDocument, `user_preferences/${userId}`, {
        userId,
        wasGuided: timestamp,
      });
    } catch (error) {
      console.warn('error', error);
    }
  });
}

export function* sendMyProviderInfo() {
  yield takeEvery(actions.SEND_PROVIDER_INFO, function*({ payload }) {
    try {
      const { category, provider, stars, companyId } = payload;
      const timestamp = firebase.firestore.Timestamp.fromDate(new Date());

      yield call(reduxSagaFirebase.firestore.addDocument, `companies_user_data/${companyId}/quotes`, {
        [category]: {
          timestamp,
          provider,
          stars,
        },
      });
    } catch (error) {
      console.warn('error', error);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(sendQuote), fork(sendUserPreferences), fork(sendMyProviderInfo)]);
}
