import Rollbar from 'rollbar';

const rollbar =
  process.env.REACT_APP_ENVIRONMENT === 'prod' || process.env.REACT_APP_ENVIRONMENT === 'staging'
    ? new Rollbar({
        accessToken: 'f70bb88687a04dbba8656544ac960573',
        captureUncaught: true,
        captureUnhandledRejections: true,
        payload: {
          environment: process.env.REACT_APP_ENVIRONMENT,
        },
      })
    : null;

export { rollbar };
