const COMPONENT = 'BUNDLE';

const actions = {
  LOAD_BUNDLE_REQUEST: `${COMPONENT}LOAD_BUNDLE_REQUEST`,
  LOAD_BUNDLE_SUCCESS: `${COMPONENT}LOAD_BUNDLE_SUCCESS`,

  loadBundleRequest: () => ({
    type: actions.LOAD_BUNDLE_REQUEST,
  }),

  loadBundleSuccess: data => ({
    type: actions.LOAD_BUNDLE_SUCCESS,
    payload: data,
  }),
};
export default actions;
