const COMPONENT = 'SIGNIN';

const signinActions = {
  CHANGE_PROPERTY: `${COMPONENT}CHANGE_PROPERTY`,
  TOUCH: `${COMPONENT}TOUCH`,
  CLEAR_STATE: `${COMPONENT}CLEAR_STATE`,

  changeProperty: data => ({
    type: signinActions.CHANGE_PROPERTY,
    payload: data,
  }),

  touch: data => ({
    type: signinActions.TOUCH,
    payload: data,
  }),

  clearState: () => ({
    type: signinActions.CLEAR_STATE,
  }),
};
export default signinActions;
