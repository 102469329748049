const COMPONENT = 'PRODUCT_';

const actions = {
  LOAD_PRODUCT_REQUEST: `${COMPONENT}LOAD_PRODUCT_REQUEST`,
  LOAD_PRODUCT_SUCCESS: `${COMPONENT}LOAD_PRODUCT_SUCCESS`,
  LOAD_PRODUCT_ERROR: `${COMPONENT}LOAD_PRODUCT_ERROR`,
  CHANGE_PROPERTY: `${COMPONENT}CHANGE_PROPERTY`,
  CLEAR_SERVICE_STATE: `${COMPONENT}CLEAR_SERVICE_STATE`,
  UPDATE_SERVICE: `${COMPONENT}UPDATE_SERVICE`,
  DELETE_SERVICE: `${COMPONENT}DELETE_SERVICE`,
  FILE_UPLOADING: `${COMPONENT}FILE_UPLOADING`,
  FILE_UPLOAD_ERROR: `${COMPONENT}FILE_UPLOAD_ERROR`,
  FILE_UPLOAD_SUCCESS: `${COMPONENT}FILE_UPLOAD_SUCCESS`,
  LOAD_ATTACHMENTS: `${COMPONENT}LOAD_ATTACHMENTS`,
  LOAD_ATTACHMENTS_SUCCESS: `${COMPONENT}LOAD_ATTACHMENTS_SUCCESS`,
  LOAD_ATTACHMENTS_ERROR: `${COMPONENT}LOAD_ATTACHMENTS_ERROR`,
  DELETE_ATTACHMENT: `${COMPONENT}DELETE_ATTACHMENT`,
  ADD_VIDEO_LINK: `${COMPONENT}ADD_VIDEO_LINK`,

  loadProductRequest: data => ({
    type: actions.LOAD_PRODUCT_REQUEST,
    payload: data,
  }),
  loadProductSuccess: data => ({
    type: actions.LOAD_PRODUCT_SUCCESS,
    payload: data,
  }),

  loadProductError: data => ({
    type: actions.LOAD_PRODUCT_ERROR,
    payload: data,
  }),

  changeProperty: data => ({
    type: actions.CHANGE_PROPERTY,
    payload: data,
  }),

  clearServiceState: () => ({
    type: actions.CLEAR_SERVICE_STATE,
  }),

  updateService: data => ({
    type: actions.UPDATE_SERVICE,
    payload: data,
  }),

  deleteService: data => ({
    type: actions.DELETE_SERVICE,
    payload: data,
  }),

  fileIsUploading: data => ({
    type: actions.FILE_UPLOADING,
    payload: data,
  }),

  addVideoLink: data => ({
    type: actions.ADD_VIDEO_LINK,
    payload: data,
  }),

  fileUploadSuccess: data => ({
    type: actions.FILE_UPLOAD_SUCCESS,
    payload: data,
  }),

  fileUploadError: data => ({
    type: actions.FILE_UPLOAD_ERROR,
    payload: data,
  }),

  loadAttachmentsRequest: data => ({
    type: actions.LOAD_ATTACHMENTS,
    payload: data,
  }),

  loadAttachmentsSuccess: data => ({
    type: actions.LOAD_ATTACHMENTS_SUCCESS,
    payload: data,
  }),

  loadAttachmentsError: data => ({
    type: actions.LOAD_ATTACHMENTS_ERROR,
    payload: data,
  }),

  deleteAttachment: data => ({
    type: actions.DELETE_ATTACHMENT,
    payload: data,
  }),
};

export default actions;
