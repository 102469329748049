const quickQuoteActions = {
  QUOTE_REQUEST: 'QUOTE_REQUEST',
  QUOTE_SUCCESS: 'QUOTE_SUCCESS',
  QUOTE_ERROR: 'QUOTE_ERROR',
  QUOTE_CLEAR: 'QUOTE_CLEAR',
  SEND_USER_PREFERENCES: 'SEND_USER_PREFERENCES',
  SEND_PROVIDER_INFO: 'SEND_PROVIDER_INFO',
  CHANGE_PROPERTY: 'CHANGE_PROPERTY',

  sendQuote: data => ({
    type: quickQuoteActions.QUOTE_REQUEST,
    payload: data,
  }),
  quoteSuccess: () => ({
    type: quickQuoteActions.QUOTE_SUCCESS,
  }),
  quoteError: data => ({
    type: quickQuoteActions.QUOTE_ERROR,
    payload: data,
  }),
  quoteClear: () => ({
    type: quickQuoteActions.QUOTE_CLEAR,
  }),
  sendUserPreferences: data => ({
    type: quickQuoteActions.SEND_USER_PREFERENCES,
    payload: data,
  }),
  sendMyProviderInfo: data => ({
    type: quickQuoteActions.SEND_PROVIDER_INFO,
    payload: data,
  }),
  changeProperty: data => ({
    type: quickQuoteActions.CHANGE_PROPERTY,
    payload: data,
  }),
};
export default quickQuoteActions;
