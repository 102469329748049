import { all, put, takeEvery, fork, call } from 'redux-saga/effects';
import actions from './actions';
import { reduxSagaFirebase, firebase } from '../../redux/firestore';

export function* loadBundleRequest() {
  yield takeEvery(actions.LOAD_BUNDLE_REQUEST, function*() {
    const colRef = firebase.firestore().collection('bundles');
    const snapshot = yield call(reduxSagaFirebase.firestore.getCollection, colRef.where('bundleGroup', '==', 'COVID'));

    let bundleData;
    snapshot.forEach(el => {
      bundleData = {
        ...bundleData,
        [el.id]: el.data(),
      };
    });

    const bundle = Object.values(bundleData).sort((a, b) => a.orderNum - b.orderNum);

    yield put(actions.loadBundleSuccess({ bundle }));
  });
}

export default function* rootSaga() {
  yield all([fork(loadBundleRequest)]);
}
