import quickQuoteActions from './actions';
import { createReducer } from '../../helpers/reducer';

// ------------------------------
// Initial State
// ------------------------------
const initState = {
  checkedCompanies: null,
  visibility: true,
  textArea: null,
  providerName: null,
  stars: null,
  dontHaveProviderCheckbox: false,
  sent: false,
  modal: false,
  checkbox: false,
  popover: false,
  page: 0,
  error: '',
};

// ------------------------------
// Action Handlers
// ------------------------------
function onChangeProperty(state, { payload }) {
  const { attr, value } = payload;

  state[attr] = value;

  return { ...state };
}

function onQuoteRequest(state) {
  return {
    ...state,
  };
}

function onQuoteSuccess(state) {
  return {
    ...state,
    page: 4,
  };
}

function onQuoteError(state, { payload }) {
  return {
    ...state,
    error: payload.error,
  };
}

function onQuoteClearState(state) {
  return {
    ...state,
    checkedCompanies: null,
    visibility: true,
    textArea: null,
    providerName: null,
    stars: null,
    dontHaveProviderCheckbox: false,
    sent: true,
    modal: false,
    checkbox: false,
    popover: false,
  };
}

// ------------------------------
// Exported reducer function.
// ------------------------------
export default createReducer(initState, {
  [quickQuoteActions.QUOTE_REQUEST]: onQuoteRequest,
  [quickQuoteActions.QUOTE_SUCCESS]: onQuoteSuccess,
  [quickQuoteActions.QOUTE_ERROR]: onQuoteError,
  [quickQuoteActions.CHANGE_PROPERTY]: onChangeProperty,
  [quickQuoteActions.QUOTE_CLEAR]: onQuoteClearState,
});
