import queryString from 'query-string';
import settings from '../settings/index';
import request from './request';
import { getAuthData } from './auth';

function getHeaders() {
  const auth = getAuthData();
  const headers = {
    'Content-Type': 'application/json',
  };

  if (auth) {
    headers.authorization = `Bearer ${auth.idToken}`;
  }

  return headers;
}

function restApi(path) {
  this.path = path;

  this.load = data => {
    const query = queryString.stringify(data);

    const url = `${settings.apiUrl}/${this.path}${query ? `?${query}` : ''}`;

    return request(url, {
      method: 'GET',
      headers: getHeaders(),
    });
  };

  this.loadCustom = (apiUrl, data) => {
    const query = queryString.stringify(data);

    const url = `${apiUrl}/${this.path}${query ? `?${query}` : ''}`;

    return request(url, {
      method: 'GET',
      headers: getHeaders(),
    });
  };

  this.loadItem = id => {
    const url = `${settings.apiUrl}/${this.path}/${id}`;

    return request(url, {
      method: 'GET',
      headers: getHeaders(),
    });
  };

  this.createItem = ({ data }) => {
    const url = `${settings.apiUrl}/${path}`;
    return request(url, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(data),
    });
  };

  this.createItemCustom = (apiUrl, data) => {
    const url = `${apiUrl}/${path}`;
    return request(url, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(data),
    });
  };

  this.patchItem = ({ id, data }) => {
    const url = `${settings.apiUrl}/${this.path}/${id}`;

    return request(url, {
      method: 'PATCH',
      headers: getHeaders(),
      body: JSON.stringify(data),
    });
  };

  this.updateItem = ({ id, data }) => {
    const url = `${settings.apiUrl}/${this.path}/${id}`;
    return request(url, {
      method: 'PUT',
      headers: getHeaders(),
      body: JSON.stringify(data),
    });
  };

  this.deleteItem = ({ id, data }) => {
    const url = `${settings.apiUrl}/${this.path}/${id}`;

    return request(url, {
      method: 'DELETE',
      headers: getHeaders(),
      body: data ? JSON.stringify(data) : null,
    });
  };

  this.deleteItemCustom = (apiUrl, data) => {
    const url = `${apiUrl}/${path}`;
    return request(url, {
      method: 'DELETE',
      headers: getHeaders(),
      body: data ? JSON.stringify(data) : null,
    });
  };

  return {
    load: this.load,
    loadCustom: this.loadCustom,
    loadItem: this.loadItem,
    createItem: this.createItem,
    createItemCustom: this.createItemCustom,
    patchItem: this.patchItem,
    updateItem: this.updateItem,
    deleteItem: this.deleteItem,
    deleteItemCustom: this.deleteItemCustom,
  };
}

export function callApi(path, method, data) {
  const url = `${settings.apiUrl}/${path}`;

  return request(url, {
    method,
    headers: getHeaders(),
    body: JSON.stringify(data),
  });
}

export default restApi;
