import actions from './actions';
import { createReducer } from '../../helpers/reducer';

// ------------------------------
// Initial State
// ------------------------------
const initState = {
  quote: null,
  responseattachments: [],
  attachmentError: null,
  fileIsUploading: false,
  fileIsUploaded: false,
  progress: 0,
  loading: false,
  error: '',
};

// ------------------------------
// Action Handlers
// ------------------------------
function onLoadSuccess(state, { payload }) {
  const { data } = payload;
  const { responseattachments } = data;
  const responseAttachments =
    responseattachments && responseattachments.length && responseattachments.map(el => JSON.parse(el));

  return {
    ...state,
    quote: data,
    responseattachments: responseAttachments && responseAttachments.length ? responseAttachments : [],
    error: '',
    loading: false,
  };
}

function onLoadError(state, { payload }) {
  return {
    ...state,
    error: payload.error,
    isLoading: false,
  };
}

function onLoad(state) {
  return {
    ...state,
    error: '',
    loading: true,
  };
}

function onClearState(state) {
  return {
    ...state,
    quote: null,
    error: '',
    loading: false,
  };
}

function onChangeProperty(state, { payload }) {
  const { attr, value, field } = payload;

  if (field) {
    state[field][attr] = value;
  } else {
    state[attr] = value;
  }

  return { ...state };
}

function onProviderRespondSuccess(state, { payload }) {
  const { responsetext, responsesummary, responseattachments } = payload;
  const status = 'responded';
  const respondedat = new Date().toISOString();

  return {
    ...state,
    quote: {
      ...state.quote,
      status,
      providerresponsetext: responsetext,
      providerresponsesummary: responsesummary,
      respondedat,
      responseattachments,
    },
  };
}

function onProviderRejectSuccess(state, { payload }) {
  const { responsetext, responsesummary } = payload;
  const status = 'providerRejected';
  const completedat = new Date().toISOString();

  return {
    ...state,
    loading: false,
    quote: {
      ...state.quote,
      status,
      providerresponsetext: responsetext,
      providerresponsesummary: responsesummary,
      completedat,
    },
  };
}

function onMemberRejectSuccess(state, { payload }) {
  const { responsetext, responsesummary } = payload;
  const status = 'rejected';
  const completedat = new Date().toISOString();

  return {
    ...state,
    loading: false,
    quote: { ...state.quote, status, responsetext, responsesummary, completedat },
  };
}

function onMemberAcceptSuccess(state, { payload }) {
  const { responsetext, responsesummary, responseattachments } = payload;
  const status = 'accepted';
  const completedat = new Date().toISOString();

  return {
    ...state,
    loading: false,
    quote: { ...state.quote, status, responsetext, responsesummary, completedat, responseattachments },
  };
}

function onFileUploading(state) {
  return {
    ...state,
    fileIsUploading: true,
    error: '',
  };
}

function onLoading(state) {
  return {
    ...state,
    loading: true,
  };
}

function onFileUploadError(state, { payload }) {
  return {
    ...state,
    error: payload.error,
    fileIsUploading: false,
  };
}

function onFileUploadSuccess(state, { payload }) {
  const responseattachments = payload;

  return {
    ...state,
    fileIsUploading: false,
    uploaded: true,
    attachmentError: null,
    error: '',
    responseattachments: [...state.responseattachments, responseattachments],
  };
}

function onLoadAttachmentsSuccess(state, { payload }) {
  const { responseattachments } = payload;
  const filesData = responseattachments || [];

  return {
    ...state,
    responseattachments: filesData,
    uploaded: false,
    attachmentError: null,
    error: '',
  };
}

function onLoadAttachmentsError(state, { payload }) {
  return {
    ...state,
    error: payload.error,
    loading: false,
  };
}

function onLoadAttachment(state) {
  return {
    ...state,
    error: '',
    loading: true,
  };
}

function onDeleteAttachment(state, { payload }) {
  const { link } = payload;
  const arr = state.responseattachments;
  const responseattachments = arr.length ? arr.filter(el => el.link !== link) : [];

  return {
    ...state,
    responseattachments,
  };
}

// ------------------------------
// Exported reducer function.
// ------------------------------
export default createReducer(initState, {
  [actions.LOAD]: onLoad,
  [actions.LOAD_SUCCESS]: onLoadSuccess,
  [actions.LOAD_ERROR]: onLoadError,
  [actions.CLEAR_STATE]: onClearState,
  [actions.PROVIDER_RESPOND_SUCCESS]: onProviderRespondSuccess,
  [actions.MEMBER_ACCEPT_SUCCESS]: onMemberAcceptSuccess,
  [actions.MEMBER_REJECT_SUCCESS]: onMemberRejectSuccess,
  [actions.PROVIDER_REJECT_SUCCESS]: onProviderRejectSuccess,
  [actions.MEMBER_ACCEPT]: onLoading,
  [actions.MEMBER_REJECT]: onLoading,
  [actions.PROVIDER_RESPOND]: onLoading,
  [actions.PROVIDER_REJECT]: onLoading,
  [actions.FILE_UPLOADING]: onFileUploading,
  [actions.FILE_UPLOAD_ERROR]: onFileUploadError,
  [actions.FILE_UPLOAD_SUCCESS]: onFileUploadSuccess,
  [actions.LOAD_ATTACHMENTS_SUCCESS]: onLoadAttachmentsSuccess,
  [actions.LOAD_ATTACHMENTS_ERROR]: onLoadAttachmentsError,
  [actions.LOAD_ATTACHMENTS]: onLoadAttachment,
  [actions.DELETE_ATTACHMENT]: onDeleteAttachment,
  [actions.CHANGE_PROPERTY]: onChangeProperty,
});
