const COMPONENT = 'COMPANIES';

const actions = {
  LOAD: `${COMPONENT}_LOAD`,
  LOAD_SUCCESS: `${COMPONENT}_LOAD_SUCCESS`,
  LOAD_ERROR: `${COMPONENT}_LOAD_ERROR`,
  CLEAR_STATE: `${COMPONENT}_LOAD_ERROR`,
  SAVE_SEARCH_LIST: `${COMPONENT}_SAVE_SEARCH_LIST`,
  SAVE_SEARCH_LIST_SUCCESS: `${COMPONENT}_SAVE_SEARCH_LIST_SUCCESS`,
  SAVE_SEARCH_LIST_ERROR: `${COMPONENT}_SAVE_SEARCH_LIST_ERROR`,
  DELETE_SEARCH_LIST: `${COMPONENT}_DELETE_SEARCH_LIST`,

  load: data => ({
    type: actions.LOAD,
    payload: data,
  }),

  loadSuccess: data => ({
    type: actions.LOAD_SUCCESS,
    payload: data,
  }),

  loadError: data => ({
    type: actions.LOAD_ERROR,
    payload: data,
  }),

  saveSearchList: data => ({
    type: actions.SAVE_SEARCH_LIST,
    payload: data,
  }),

  saveSearchListSuccess: data => ({
    type: actions.SAVE_SEARCH_LIST_SUCCESS,
    payload: data,
  }),

  saveSearchListError: data => ({
    type: actions.SAVE_SEARCH_LIST_ERROR,
    payload: data,
  }),

  deleteSearchList: data => ({
    type: actions.DELETE_SEARCH_LIST,
    payload: data,
  }),

  clearState: () => ({
    type: actions.CLEAR_STATE,
  }),
};

export default actions;
