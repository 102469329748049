import actions from './actions';
import { createReducer } from '../../helpers/reducer';

const defaultBundleId = '5b0bcd0f-dbce-481f-84b7-4531a3ae10ce';

// ------------------------------
// Initial State
// ------------------------------
const initState = {
  bundles: [],
  bundle: {
    bundleId: defaultBundleId,
    topics: [],
  },
  products: [],
  keywords: [],
  loading: false,
};

// ------------------------------
// Action Handlers
// ------------------------------
function onLoadBundlesSuccess(state, { payload }) {
  const { bundles } = payload;
  // eslint-disable-next-line no-return-assign
  const bundle = bundles.find(item => item.bundleId === (state.bundle.bundleId || defaultBundleId));

  return {
    ...state,
    bundles,
    bundle,
  };
}

function onLoadProductsSuccess(state, { payload }) {
  const { products } = payload;

  return {
    ...state,
    products,
    loading: false,
  };
}

function onSetFilter(state, { payload }) {
  const { keywords } = payload;

  return {
    ...state,
    keywords,
  };
}

function onSetBundle(state, { payload }) {
  const { bundle } = payload;

  return {
    ...state,
    bundle,
    keywords: [],
  };
}

function onLoadError(state) {
  return {
    ...state,
    isLoading: false,
  };
}

function onLoadProductsRequest(state) {
  return {
    ...state,
    loading: true,
  };
}

function onClear(state) {
  return {
    ...state,
  };
}

// ------------------------------
// Exported reducer function.
// ------------------------------
export default createReducer(initState, {
  [actions.LOAD_BUNDLES_SUCCESS]: onLoadBundlesSuccess,
  [actions.LOAD_PRODUCTS_SUCCESS]: onLoadProductsSuccess,
  [actions.LOAD_PRODUCTS_REQUEST]: onLoadProductsRequest,
  [actions.SET_FILTER]: onSetFilter,
  [actions.LOAD_ERROR]: onLoadError,
  [actions.CLEAR]: onClear,
  [actions.SET_BUNDLE]: onSetBundle,
});
