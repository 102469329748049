import { all, takeEvery, put } from 'redux-saga/effects';
import actions from './actions';
import RestApi from '../../helpers/api';

const api = new RestApi('quotes');

export function* load(data) {
  try {
    const { payload } = data;
    const { page, pageSize, column, order, type } = payload;

    const params = {
      page,
      pageSize,
      column,
      order,
      type,
    };

    const response = yield api.load(params);

    if (response.status === 'success') {
      const { data, count } = response;
      yield put(
        actions.loadSuccess({
          data,
          count,
          page,
        }),
      );
    } else if (response.status === 'error') {
      const { error } = response.data;

      yield put(actions.loadError(error));
    }
  } catch (error) {
    console.warn(error);
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD, load)]);
}
