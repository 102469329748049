const COMPONENT = 'COMPANY';

const profileActions = {
  SEND_PROFILE_INFO: `${COMPONENT}_SEND_PROFILE_INFO`,

  LOAD_RISK_REQUEST: `${COMPONENT}_LOAD_RISK_REQUEST`,
  LOAD_RISK_SUCCESS: `${COMPONENT}_LOAD_RISK_SUCCESS`,

  LOAD_ANALYSIS_REQUEST: `${COMPONENT}_LOAD_ANALYSIS_REQUEST`,
  LOAD_ANALYSIS_SUCCESS: `${COMPONENT}_LOAD_ANALYSIS_SUCCESS`,
  LOAD_ANALYSIS_ERROR: `${COMPONENT}_LOAD_ANALYSIS_ERROR`,

  LOAD_ANNIE_REQUEST: `${COMPONENT}_LOAD_ANNIE_REQUEST`,
  LOAD_ANNIE_SUCCESS: `${COMPONENT}_LOAD_ANNIE_SUCCESS`,
  LOAD_ANNIE_ERROR: `${COMPONENT}_LOAD_ANNIE_ERROR`,

  DELETE_ANNIE_CHAT_REQUEST: `${COMPONENT}_DELETE_ANNIE_CHAT_REQUEST`,
  DELETE_ANNIE_CHAT_SUCCESS: `${COMPONENT}_DELETE_ANNIE_CHAT_SUCCESS`,
  DELETE_ANNIE_CHAT_ERROR: `${COMPONENT}_DELETE_ANNIE_CHAT_ERROR`,

  CREATE_SWOT_REQUEST: `${COMPONENT}_CREATE_SWOT_REQUEST`,
  CREATE_SWOT_SUCCESS: `${COMPONENT}_CREATE_SWOT_SUCCESS`,
  CREATE_SWOT_ERROR: `${COMPONENT}_CREATE_SWOT_ERROR`,

  LOAD_CREDIT_REQUEST: `${COMPONENT}_CREATE_CREDIT_REQUEST`,
  LOAD_CREDIT_SUCCESS: `${COMPONENT}_CREATE_CREDIT_SUCCESS`,
  LOAD_CREDIT_ERROR: `${COMPONENT}_CREATE_CREDIT_ERROR`,

  LOAD_BUY_CREDIT_REQUEST: `${COMPONENT}_LOAD_BUY_CREDIT_REQUEST`,
  LOAD_BUY_CREDIT_SUCCESS: `${COMPONENT}_LOAD_BUY_CREDIT_SUCCESS`,
  LOAD_BUY_CREDIT_ERROR: `${COMPONENT}_LOAD_BUY_CREDIT_ERROR`,

  LOAD_TIMELINE_REQUEST: `${COMPONENT}_LOAD_TIMELINE_REQUEST`,
  LOAD_TIMELINE_SUCCESS: `${COMPONENT}_LOAD_TIMELINE_SUCCESS`,

  LOAD_CUSTOM_TIMELINE_REQUEST: `${COMPONENT}_LOAD_CUSTOM_TIMELINE_REQUEST`,
  LOAD_CUSTOM_TIMELINE_SUCCESS: `${COMPONENT}LOAD_CUSTOM_TIMELINE_SUCCESS`,

  SET_TIME_LINE: `${COMPONENT}_SET_TIME_LINE`,
  SET_CUSTOM_TIME_LINE: `${COMPONENT}_SET_CUSTOM_TIME_LINE`,

  LOAD_CUSTOM_LIST_RISK_REQUEST: `${COMPONENT}LOAD_CUSTOM_LIST_RISK_REQUEST`,
  LOAD_CUSTOM_LIST_RISK_SUCCESS: `${COMPONENT}LOAD_CUSTOM_LIST_RISK_SUCCESS`,

  LOAD_SWOT_REQUEST: `${COMPONENT}_LOAD_SWOT_REQUEST`,
  LOAD_SWOT_SUCCESS: `${COMPONENT}_LOAD_SWOT_SUCCESS`,
  LOAD_SWOT_ERROR: `${COMPONENT}_LOAD_SWOT_ERROR`,

  CLEAR: `${COMPONENT}CLEAR`,

  sendProfileInfo: data => ({
    type: profileActions.SEND_PROFILE_INFO,
    payload: data,
  }),

  loadSwotRequest: data => ({
    type: profileActions.LOAD_SWOT_REQUEST,
    payload: data,
  }),
  loadSwotSuccess: data => ({
    type: profileActions.LOAD_SWOT_SUCCESS,
    payload: data,
  }),
  loadSwotError: data => ({
    type: profileActions.LOAD_SWOT_ERROR,
    payload: data,
  }),

  loadBuyCreditRequest: data => ({
    type: profileActions.LOAD_BUY_CREDIT_REQUEST,
    payload: data,
  }),
  loadBuyCreditSuccess: data => ({
    type: profileActions.LOAD_BUY_CREDIT_SUCCESS,
    payload: data,
  }),
  loadBuyCreditError: data => ({
    type: profileActions.LOAD_BUY_CREDIT_ERROR,
    payload: data,
  }),

  loadCreditRequest: data => ({
    type: profileActions.LOAD_CREDIT_REQUEST,
    payload: data,
  }),
  loadCreditSuccess: data => ({
    type: profileActions.LOAD_CREDIT_SUCCESS,
    payload: data,
  }),
  loadCreditError: data => ({
    type: profileActions.LOAD_CREDIT_ERROR,
    payload: data,
  }),

  loadRiskRequest: data => ({
    type: profileActions.LOAD_RISK_REQUEST,
    payload: data,
  }),

  loadRiskSuccess: data => ({
    type: profileActions.LOAD_RISK_SUCCESS,
    payload: data,
  }),

  loadAnalysisRequest: data => ({
    type: profileActions.LOAD_ANALYSIS_REQUEST,
    payload: data,
  }),
  loadAnalysisSuccess: data => ({
    type: profileActions.LOAD_ANALYSIS_SUCCESS,
    payload: data,
  }),
  loadAnalysisError: data => ({
    type: profileActions.LOAD_ANALYSIS_ERROR,
    payload: data,
  }),

  loadAnnieRequest: data => ({
    type: profileActions.LOAD_ANNIE_REQUEST,
    payload: data,
  }),
  loadAnnieSuccess: data => ({
    type: profileActions.LOAD_ANNIE_SUCCESS,
    payload: data,
  }),
  loadAnnieError: data => ({
    type: profileActions.LOAD_ANNIE_ERROR,
    payload: data,
  }),

  deleteAnnieChatRequest: data => ({
    type: profileActions.DELETE_ANNIE_CHAT_REQUEST,
    payload: data,
  }),
  deleteAnnieChatSuccess: data => ({
    type: profileActions.DELETE_ANNIE_CHAT_SUCCESS,
    payload: data,
  }),
  deleteAnnieChatError: data => ({
    type: profileActions.DELETE_ANNIE_CHAT_ERROR,
    payload: data,
  }),

  createSwotRequest: data => ({
    type: profileActions.CREATE_SWOT_REQUEST,
    payload: data,
  }),
  createSwotSuccess: data => ({
    type: profileActions.CREATE_SWOT_SUCCESS,
    payload: data,
  }),
  createSwotError: data => ({
    type: profileActions.CREATE_SWOT_ERROR,
    payload: data,
  }),

  loadCustomListRiskRequest: data => ({
    type: profileActions.LOAD_CUSTOM_LIST_RISK_REQUEST,
    payload: data,
  }),

  loadCustomListRiskSuccess: data => ({
    type: profileActions.LOAD_CUSTOM_LIST_RISK_SUCCESS,
    payload: data,
  }),

  loadTimelineRequest: data => ({
    type: profileActions.LOAD_TIMELINE_REQUEST,
    payload: data,
  }),

  loadTimelineSuccess: data => ({
    type: profileActions.LOAD_TIMELINE_SUCCESS,
    payload: data,
  }),

  loadCustomTimelineRequest: data => ({
    type: profileActions.LOAD_CUSTOM_TIMELINE_REQUEST,
    payload: data,
  }),

  loadCustomTimelineSuccess: data => ({
    type: profileActions.LOAD_CUSTOM_TIMELINE_SUCCESS,
    payload: data,
  }),

  setTimeLine: data => ({
    type: profileActions.SET_TIME_LINE,
    payload: data,
  }),
  setCustomTimeLine: data => ({
    type: profileActions.SET_CUSTOM_TIME_LINE,
    payload: data,
  }),

  clear: () => ({
    type: profileActions.CLEAR,
  }),
};
export default profileActions;
