import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import signupSagas from '../containers/Signup/saga';
import signupPublicSagas from '../containers/SignupPublic/saga';
import changePasswordSaga from '../containers/ChangePassword/saga';
import forgotPasswordActions from '../containers/ForgotPassword/saga';
import resetPasswordActions from '../containers/ResetPassword/saga';
import setPasswordActions from '../containers/SetPassword/saga';
import brokersActions from '../containers/Brokers/saga';
import productsAndServices from '../containers/Provider/ProductsAndServices/saga';
import myProviders from '../containers/MyProviders/saga';
import users from '../containers/Users/saga';
import welcome from '../containers/Welcome/saga';
import companies from '../containers/Companies/saga';
import invisibleCompany from '../containers/InvisibleCompany/saga';
import quotes from '../containers/Quotes/saga';
import requests from '../containers/Requests/saga';
import topBar from '../containers/Topbar/saga';
import leadFlow from '../containers/LeadFlow/saga';
import analysis from '../containers/Analysis/saga';
import swotItem from '../containers/SwotItem/saga';
import contentPack from '../containers/ContentPack/saga';
import contentPacks from '../containers/ContentPacks/saga';
import allProviders from '../containers/Provider/AllProviders/saga';
import marketResearch from '../containers/MarketResearch/saga';
import quickQuoteDetail from '../containers/QuickQuoteDetail/saga';
import quickQuote from '../containers/QuickQuote/saga';
import categoryView from '../containers/MyProviders/CategoryView/saga';
import provider from '../containers/Provider/saga';
import company from '../containers/Company/saga';
import companyProfile from '../containers/CompanyProfile/saga';
import bundles from '../containers/Bundles/saga';
import bundle from '../containers/BundleDetail/saga';
import product from '../containers/Provider/ProductsAndServices/Product/saga';
import covidBundleDetail from '../containers/CovidBundleDetail/saga';
import deiBundleDetail from '../containers/DeiBundleDetail/saga';
import intBundleDetail from '../containers/IntBundleDetail/saga';
import companyPreview from '../containers/CompanyPreview/saga';
import risk from '../containers/Risk/saga';
import riskEmpty from '../containers/RiskEmpty/saga';
import riskPreview from '../containers/RiskPreview/saga';
import riskCustom from '../containers/RiskCustom/saga';
import riskEditor from '../containers/RiskEditor/saga';
import score from '../containers/Company/Score/saga';
import subScore from '../containers/Company/SubScore/saga';
import scoreEmpty from '../containers/Company/ScoreEmpty/saga';
import scoreCustom from '../containers/Company/ScoreCustom/saga';
import printView from '../containers/PrintView/saga';
import benchmark from '../containers/Benchmark/saga';
import webex from '../containers/WebexPage/saga';
import webexSplash from '../containers/WebexSplash/saga';

export default function* rootSaga() {
  yield all([
    authSagas(),
    printView(),
    webex(),
    analysis(),
    webexSplash(),
    brokersActions(),
    covidBundleDetail(),
    riskPreview(),
    deiBundleDetail(),
    signupSagas(),
    risk(),
    swotItem(),
    benchmark(),
    riskEmpty(),
    riskEditor(),
    score(),
    subScore(),
    scoreEmpty(),
    scoreCustom(),
    signupPublicSagas(),
    riskCustom(),
    allProviders(),
    changePasswordSaga(),
    myProviders(),
    forgotPasswordActions(),
    resetPasswordActions(),
    setPasswordActions(),
    marketResearch(),
    company(),
    productsAndServices(),
    users(),
    contentPack(),
    contentPacks(),
    companyPreview(),
    provider(),
    welcome(),
    companies(),
    companyProfile(),
    invisibleCompany(),
    quotes(),
    topBar(),
    quickQuote(),
    requests(),
    leadFlow(),
    quickQuoteDetail(),
    categoryView(),
    product(),
    bundles(),
    bundle(),
    intBundleDetail(),
  ]);
}
