import categoryViewActions from './actions';
import { createReducer } from '../../../helpers/reducer';

// ------------------------------
// Initial State
// ------------------------------
const initState = {
  myProviders: [],
  topProvidersByAnilineIndex: [],
  mostUsedProvidersInYourIndustry: [],
  usedInTopCompanies: [],
  error: '',
  loading: false,
  modal: false,
  provider: null,
  contactPerson: null,
  contactEmail: null,
  stars: null,
  services: null,
};

// ------------------------------
// Action Handlers
// ------------------------------
function onLoadSuccess(state, { payload }) {
  const {
    topProvidersByAnilineIndexArr,
    myProvidersArr,
    usedInTopCompaniesArr,
    mostUsedProvidersInYourIndustryArr,
  } = payload;

  return {
    ...state,
    topProvidersByAnilineIndex: topProvidersByAnilineIndexArr,
    myProviders: myProvidersArr,
    mostUsedProvidersInYourIndustry: mostUsedProvidersInYourIndustryArr,
    usedInTopCompanies: usedInTopCompaniesArr,
    loading: false,
  };
}

function onLoadError(state, { payload }) {
  return {
    ...state,
    error: payload.error,
    isLoading: false,
  };
}

function onLoad(state) {
  return {
    ...state,
    loading: true,
  };
}

function onClearState(state) {
  return {
    ...state,
    provider: null,
  };
}

function clearForm(state) {
  return {
    ...state,
    provider: null,
    contactPerson: null,
    contactEmail: null,
    stars: null,
    services: null,
  };
}

function onChangeProperty(state, { payload }) {
  const { attr, value } = payload;

  state[attr] = value;

  return { ...state };
}

function onProviderRequest(state) {
  return {
    ...state,
  };
}

function onProviderSuccess(state) {
  return {
    ...state,
  };
}

function onProviderError(state, { payload }) {
  return {
    ...state,
    error: payload.error,
  };
}

// ------------------------------
// Exported reducer function.
// ------------------------------
export default createReducer(initState, {
  [categoryViewActions.CATEGORY_LOAD]: onLoad,
  [categoryViewActions.CATEGORY_LOAD_SUCCESS]: onLoadSuccess,
  [categoryViewActions.CATEGORY_LOAD_ERROR]: onLoadError,
  [categoryViewActions.CLEAR_STATE]: onClearState,
  [categoryViewActions.CHANGE_PROPERTY]: onChangeProperty,
  [categoryViewActions.CLEAR_FORM]: clearForm,
  [categoryViewActions.SEND_PROVIDER]: onProviderRequest,
  [categoryViewActions.SEND_PROVIDER_SUCCESS]: onProviderSuccess,
  [categoryViewActions.SEND_PROVIDER_ERROR]: onProviderError,
});
