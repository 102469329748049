import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import Auth from './auth/reducer';
import SignUp from '../containers/Signup/reducer';
import SignUpPublic from '../containers/SignupPublic/reducer';
import SignIn from '../containers/Signin/reducer';
import Analysis from '../containers/Analysis/reducer';
import SwotItem from '../containers/SwotItem/reducer';
import ContentPack from '../containers/ContentPack/reducer';
import ContentPacks from '../containers/ContentPacks/reducer';
import App from './app/reducer';
import ChangePassword from '../containers/ChangePassword/reducer';
import TopBar from '../containers/Topbar/reducer';
import Users from '../containers/Users/reducer';
import Companies from '../containers/Companies/reducer';
import InvisibleCompany from '../containers/InvisibleCompany/reducer';
import LeadFlow from '../containers/LeadFlow/reducer';
import Requests from '../containers/Requests/reducer';
import Quotes from '../containers/Quotes/reducer';
import Quote from '../containers/QuickQuoteDetail/reducer';
import ForgotPassword from '../containers/ForgotPassword/reducer';
import ResetPassword from '../containers/ResetPassword/reducer';
import SetPassword from '../containers/SetPassword/reducer';
import MyProviders from '../containers/MyProviders/reducer';
import AllProviders from '../containers/Provider/AllProviders/reducer';
import Brokers from '../containers/Brokers/reducer';
import MarketResearch from '../containers/MarketResearch/reducer';
import ProductsAndServices from '../containers/Provider/ProductsAndServices/reducer';
import welcome from '../containers/Welcome/reducer';
import LanguageSwitcher from './languageSwitcher/reducer';
import ThemeSwitcher from './themeSwitcher/reducer';
import QuickQuote from '../containers/QuickQuote/reducer';
import CategoryView from '../containers/MyProviders/CategoryView/reducer';
import CompanyProfile from '../containers/CompanyProfile/reducer';
import Company from '../containers/Company/reducer';
import Product from '../containers/Provider/ProductsAndServices/Product/reducer';
import Bundles from '../containers/Bundles/reducer';
import Bundle from '../containers/BundleDetail/reducer';
import Risk from '../containers/Risk/reducer';
import RiskEmpty from '../containers/RiskEmpty/reducer';
import RiskCustom from '../containers/RiskCustom/reducer';
import RiskPreview from '../containers/RiskPreview/reducer';
import RiskEditor from '../containers/RiskEditor/reducer';
import Score from '../containers/Company/Score/reducer';
import SubScore from '../containers/Company/SubScore/reducer';
import ScoreEmpty from '../containers/Company/ScoreEmpty/reducer';
import ScoreCustom from '../containers/Company/ScoreCustom/reducer';
import CovidBundleDetail from '../containers/CovidBundleDetail/reducer';
import DeiBundleDetail from '../containers/DeiBundleDetail/reducer';
import IntBundleDetail from '../containers/IntBundleDetail/reducer';
import CompanyPreview from '../containers/CompanyPreview/reducer';
import PrintView from '../containers/PrintView/reducer';
import Benchmark from '../containers/Benchmark/reducer';
import Webex from '../containers/WebexPage/reducer';
import WebexSplash from '../containers/WebexSplash/reducer';

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    Auth,
    Analysis,
    Brokers,
    PrintView,
    SignUp,
    WebexSplash,
    SignUpPublic,
    SwotItem,
    ContentPack,
    ContentPacks,
    Webex,
    App,
    TopBar,
    Benchmark,
    SignIn,
    Risk,
    RiskEmpty,
    RiskPreview,
    RiskEditor,
    Score,
    SubScore,
    ScoreEmpty,
    ChangePassword,
    CovidBundleDetail,
    DeiBundleDetail,
    IntBundleDetail,
    CompanyPreview,
    Company,
    Bundles,
    Users,
    RiskCustom,
    Bundle,
    AllProviders,
    MyProviders,
    Companies,
    InvisibleCompany,
    LeadFlow,
    Requests,
    Quotes,
    Quote,
    CompanyProfile,
    ForgotPassword,
    ResetPassword,
    SetPassword,
    MarketResearch,
    ProductsAndServices,
    welcome,
    ThemeSwitcher,
    LanguageSwitcher,
    QuickQuote,
    CategoryView,
    Product,
    ScoreCustom,
  });

export default createRootReducer;
