import actions from './actions';
import { createReducer } from '../../helpers/reducer';

// ------------------------------
// Initial State
// ------------------------------
const initState = {
  token: null,
  error: null,
  loading: true,
};

// ------------------------------
// Action Handlers
// ------------------------------
function onLoadSuccess(state, { payload }) {
  return {
    ...state,
    token: payload.token,
    error: payload.error ? payload.error : null,
    loading: false,
  };
}

function onClear() {
  return {
    initState,
  };
}

// ------------------------------
// Exported reducer function.
// ------------------------------
export default createReducer(initState, {
  [actions.LOAD_SUCCESS]: onLoadSuccess,
  [actions.CLEAR]: onClear,
});
