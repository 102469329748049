import { all, put, takeEvery, fork, take } from 'redux-saga/effects';
import { channel } from 'redux-saga';
import { v4 } from 'uuid';
import RestApi from '../../../../helpers/api';
import actions from './actions';
import { storage } from '../../../../redux/firestore';

const api = new RestApi('products');
const apiByProduct = new RestApi('products/attachment/by-product');

export function* loadProductRequest() {
  yield takeEvery(actions.LOAD_PRODUCT_REQUEST, function*({ payload }) {
    const { productId } = payload;

    const result = yield api.loadItem(productId);

    if (result.status === 'success') {
      const { data } = result;
      yield put(actions.loadProductSuccess({ data }));
    } else if (result.status === 'error') {
      yield put(actions.loadProductError(result));
    }
  });
}

export function* loadAttachmentsRequest() {
  yield takeEvery(actions.LOAD_ATTACHMENTS, function*({ payload }) {
    const { productId } = payload;

    const result = yield apiByProduct.loadItem(productId);

    if (result.status === 'success') {
      const { data } = result;
      const filesData = data.filter(item => item.type === 'file');
      const videosData = data.filter(item => item.type === 'video');
      const files = filesData.length > 0 ? filesData : null;
      const videos = videosData.length > 0 ? videosData : null;
      yield put(actions.loadAttachmentsSuccess({ files, videos }));
    } else if (result.status === 'error') {
      yield put(actions.loadAttachmentsSuccess(result));
    }
  });
}

export function* updateService() {
  yield takeEvery(actions.UPDATE_SERVICE, function*({ payload }) {
    try {
      const {
        categoryname,
        categoryid,
        description,
        heading,
        keywords,
        productId,
        productname,
        subcategoryname,
        subcategoryId,
        files,
        videos,
        deletedVideos,
        deletedFiles,
      } = payload;

      console.log('categoryid', categoryid);

      const data = {
        category: categoryname,
        categoryid,
        description,
        heading,
        keywords,
        serviceName: productname,
        subcategory: subcategoryname,
        subcategoryId,
        files,
        videos,
        deletedVideos,
        deletedFiles,
      };

      yield api.patchItem({ id: productId, data });
    } catch (e) {
      console.warn('error', e);
    }
  });
}

export function* deleteService() {
  yield takeEvery(actions.DELETE_SERVICE, function*({ payload }) {
    try {
      const { productId } = payload;

      yield api.deleteItem({ id: productId });
    } catch (e) {
      console.warn('error', e);
    }
  });
}

export const fileChannel = channel();

export function* uploadFile() {
  yield takeEvery(actions.FILE_UPLOADING, function*({ payload }) {
    try {
      const { file, companyId, productId, type } = payload;
      if (type === 'file') {
        const id = v4();
        const name = `${file.name}`;
        const filename = `${companyId}-${id}`;
        Object.defineProperty(file, 'name', {
          writable: true,
          value: filename,
        });

        const uploadTask = storage.ref(`/product-attachments/${companyId}/${file.name}`).put(file);

        uploadTask.on(
          'state_changed',
          snapshot => {
            const progressPercentage = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            fileChannel.put({
              type: actions.CHANGE_PROPERTY,
              payload: { attr: 'progress', value: progressPercentage },
            });
          },
          error => console.warn(error),
          () => {
            storage
              .ref(`/product-attachments/${companyId}/`)
              .child(file.name)
              .getDownloadURL()
              .then(link => {
                fileChannel.put(actions.fileUploadSuccess({ name, link, productId, companyId, type }));
              });
          },
        );
      }
    } catch (e) {
      console.warn('error', e);
      yield put(actions.fileUploadError(e));
    }
  });
}

export function* watchFileChannel() {
  while (true) {
    const action = yield take(fileChannel);
    yield put(action);
  }
}

export default function* rootSaga() {
  yield all([
    fork(loadProductRequest),
    fork(updateService),
    fork(deleteService),
    fork(uploadFile),
    fork(watchFileChannel),
    fork(loadAttachmentsRequest),
  ]);
}
