import signupActions from './actions';
import { createReducer } from '../../helpers/reducer';
import { keyBy } from '../../helpers/core';
import { analytics } from '../../redux/firestore';

// ------------------------------
// Local util functions
// ------------------------------
function validate({ attr, value = '' }) {
  let error = '';
  // const defaultErrorMessage =
  //   'Your password must be at least 7 characters and include upper and lower case, and at least 1 special character.';

  if (attr === 'email') {
    const re = /^[^\s@]+@[^\s@]+.[^\s@]+$/;
    if (!re.test(value)) {
      error = 'Please enter a valid email';
    }
  }

  if (attr === 'firstName') {
    if (!value.length) {
      error = 'Please enter first name';
    }
  }

  if (attr === 'jobTitle') {
    if (!value.length) {
      error = 'Please enter job title';
    }
  }

  if (attr === 'lastName') {
    if (!value.length) {
      error = 'Please enter last name';
    }
  }

  if (attr === 'companyName') {
    if (!value.length) {
      error = 'Please enter company name';
    }
  }

  if (attr === 'confirmConditions') {
    if (!value) {
      error = 'Please confirm to continue';
    }
  }

  // if (attr === 'password') {
  //   if (!value || value.length < 7) {
  //     error = defaultErrorMessage;
  //   }
  //
  //   if (value && !/[a-z]/.test(value)) {
  //     error = defaultErrorMessage;
  //   }
  //
  //   if (value && !/[A-Z]/.test(value)) {
  //     error = defaultErrorMessage;
  //   }
  //
  //   if (value && !/\W/.test(value)) {
  //     error = defaultErrorMessage;
  //   }
  //
  //   if (state) {
  //     if (state.confirmPassword.value !== value) {
  //       state.confirmPassword.error = 'Passwords are not equal.';
  //     } else {
  //       state.confirmPassword.error = '';
  //     }
  //   }
  // }

  // if (state && attr === 'confirmPassword') {
  //   if (state.password.value !== value) {
  //     error = 'Passwords are not equal.';
  //   }
  // }

  return error;
}

// ------------------------------
// Initial State
// ------------------------------
const initState = {
  companyData: null,
  signupView: false,
  loadScreen: false,
  companyType: 'member_provider',
  signType: '',
  suggestions: [],
  role: '',
  alreadySignedUp: false,
  email: {
    value: '',
    error: validate({ attr: 'email' }),
    name: 'email',
    touched: false,
  },
  uid: {
    value: '',
    error: validate({ attr: 'uid' }),
    name: 'uid',
    touched: false,
  },
  // password: {
  //   value: '',
  //   error: validate({ attr: 'password' }),
  //   name: 'password',
  //   touched: false,
  //   type: 'password',
  // },
  // confirmPassword: {
  //   value: '',
  //   error: validate({ attr: 'confirmPassword' }),
  //   name: 'confirmPassword',
  //   touched: false,
  //   type: 'password',
  // },
  companyName: {
    value: '',
    error: validate({ attr: 'companyName' }),
    name: 'companyName',
    touched: false,
  },
  firstName: {
    value: '',
    error: validate({ attr: 'firstName' }),
    name: 'firstName',
    touched: false,
  },
  lastName: {
    value: '',
    error: validate({ attr: 'lastName' }),
    name: 'lastName',
    touched: false,
  },
  jobTitle: {
    value: 'no',
    error: validate({ attr: 'jobTitle' }),
    name: 'jobTitle',
    touched: false,
  },
  confirmConditions: {
    value: false,
    error: validate({ attr: 'confirmConditions' }),
    name: 'confirmConditions',
    touched: false,
  },
  token: '',
  created: false,
  signinStart: false,
  tokenExpired: false,
  isSaveloader: false,
  error: '',
};

// ------------------------------
// Action Handlers
// ------------------------------
function onChangeProperty(state, { payload }) {
  const { attr, value } = payload;

  state[attr] = {
    value,
    error: validate({ attr, value, state }),
    name: attr,
    touched: true,
  };

  return { ...state };
}

function onLoadSuggestionsSuccess(state, { payload }) {
  const { suggestions } = payload;

  return {
    ...state,
    suggestions,
  };
}

function onTouch(state, { payload }) {
  const { props } = payload;
  const propsArray = props.map(prop => state[prop]);

  const updatedState = keyBy(
    propsArray,
    prop => prop.name,
    prop => ({ ...prop, touched: true }),
  );

  return { ...state, ...updatedState };
}

function onClearState() {
  return { ...initState };
}

function onSignupRequest(state) {
  return {
    ...state,
    isSaveloader: true,
  };
}

function onSignupSuccess(state, { payload }) {
  analytics.logEvent('sign_up', {
    method: 'email',
  });

  return {
    ...state,
    signType: payload.signType,
    isSaveloader: false,
    created: true,
    error: '',
  };
}

function onSignupError(state, { payload }) {
  return {
    ...state,
    isSaveloader: false,
    // created: true,
    error: payload.error,
  };
}

function onSigninStart(state) {
  return { ...state, signinStart: true };
}

function onGetTokenRequest(state) {
  analytics.logEvent('sign_up_page_load');
  return {
    ...state,
  };
}

function onGetTokenSuccess(state, { payload }) {
  const { formData, companyData, role, alreadySignedUp } = payload;

  formData.forEach(el => {
    const key = Object.keys(el);
    const val = Object.values(el);
    const [attr] = key;
    const [value] = val;

    state[attr] = {
      value,
      error: validate({ attr, value }),
      name: attr,
      touched: true,
    };
  });

  return { ...state, companyData, role, alreadySignedUp };
}

function onGetTokenError(state, { payload }) {
  analytics.logEvent('sign_up_page_expired_token');
  return {
    ...state,
    isSaveloader: false,
    tokenExpired: true,
    error: payload.error,
  };
}

function onSwitchView(state) {
  analytics.logEvent('begin_checkout', {
    value: 'sign-up',
  });

  return {
    ...state,
    signupView: !state.signupView,
  };
}

function onSetCompanyType(state, { payload }) {
  const { companyType } = payload;

  return {
    ...state,
    companyType,
  };
}

function onLoadScreen(state) {
  return {
    ...state,
    loadScreen: !state.loadScreen,
  };
}

// ------------------------------
// Exported reducer function.
// ------------------------------
export default createReducer(initState, {
  [signupActions.CHANGE_PROPERTY]: onChangeProperty,
  [signupActions.TOUCH]: onTouch,
  [signupActions.SET_COMPANY_TYPE]: onSetCompanyType,
  [signupActions.CLEAR_STATE]: onClearState,
  [signupActions.SIGNUP_SUCCESS]: onSignupSuccess,
  [signupActions.SIGNUP_ERROR]: onSignupError,
  [signupActions.SIGNIN_START]: onSigninStart,
  [signupActions.SIGNUP_REQUEST]: onSignupRequest,
  [signupActions.SWITCH_VIEW]: onSwitchView,
  [signupActions.LOAD_SCREEN]: onLoadScreen,
  [signupActions.GET_TOKEN_REQUEST]: onGetTokenRequest,
  [signupActions.GET_TOKEN_SUCCESS]: onGetTokenSuccess,
  [signupActions.GET_TOKEN_ERROR]: onGetTokenError,
  [signupActions.AUTO_SUGGESTIONS_SUCCESS]: onLoadSuggestionsSuccess,
});
