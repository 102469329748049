import styled from 'styled-components';
import { palette, font } from 'styled-theme';

const DashAppHolder = styled.div`
  font-family: ${font('primary', 0)};
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  li,
  input,
  textarea,
  span,
  div,
  img,
  svg {
    &::selection {
      background: ${palette('primary', 0)};
      color: ${palette('text', 6)};
    }
  }

  .ant-row {
    ${'' /* margin-left: -8px;
    margin-right: -8px; */};
  }

  .ant-row > div {
    padding: 0;
  }

  .isoLeftRightComponent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .isoCenterComponent {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .title.skeleton,
  .value.skeleton {
    position: relative;
    width: 200px;
    height: 36px;
    border-radius: 5px;
    background-color: ${palette('backgrounds', 3)};
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 0.2) 20%,
        rgba(255, 255, 255, 0.5) 60%,
        rgba(255, 255, 255, 0)
      );
      animation: shimmer 1.5s infinite;
      content: '';
    }

    @keyframes shimmer {
      100% {
        transform: translateX(100%);
      }
    }
  }

  .timeline-custom {
    .timeline--wrapper {
      padding: 0;
      width: 100%;
      .timeline {
        margin: 0;
        padding-top: 0;
        & > .entry {
          & > .body {
            margin-bottom: 25px;
          }
          &:last-of-type > .body {
            margin-bottom: 10px;
          }
        }
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 100px;
          bottom: 0;
          width: 1px;
          border: 1px solid ${palette('text', 0)};
        }
        .body {
          width: calc(100% - 100px);

          .message-wrapper {
            border-radius: 3px;
            padding: 15px 11px;
            color: ${palette('text', 6)};

            & > .row {
              display: flex;
              justify-content: space-between;

              & > .from {
                font-family: 'Rubik';
                font-size: 14px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.5px;
                color: ${palette('text', 0)};
                margin-bottom: 8px;
              }

              & > .internal {
                font-family: 'Rubik';
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.5px;
                text-align: right;
                color: ${palette('text', 18)};
              }
            }

            .text-row {
              display: flex;
              justify-content: space-between;
              .text {
                font-family: 'Rubik';
                font-size: 14px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.5px;
                color: ${palette('text', 13)};
              }

              .icon {
                font-size: 16px;
              }
            }
          }

          .reject {
            background-color: ${palette('error', 0)};
            & > .text-row > .text {
              font-weight: 500;
              color: ${palette('text', 6)};
            }
          }
          .accept {
            background-color: ${palette('text', 7)};
            & > .text-row > .text {
              font-weight: 500;
              color: ${palette('text', 6)};
            }
          }

          .respond {
            box-shadow: none;
            background-color: ${palette('text', 0)};

            & > .text-row > .text {
              font-weight: 500;
              color: ${palette('text', 6)};
            }
          }

          .message {
            box-shadow: 0 10px 24px 0 rgba(29, 42, 68, 0.12);
            color: ${palette('text', 18)};

            &.internal {
              background-color: ${palette('backgrounds', 0)};
              box-shadow: none;
            }
          }
        }
        .title {
          width: 100px;
          &:before {
            content: '';
            position: absolute;
            width: 8px;
            height: 8px;
            border: 3px solid ${palette('text', 6)};
            background-color: ${palette('text', 0)};
            border-radius: 100%;
            top: 50%;
            right: -8px;
            z-index: 1;
            box-sizing: content-box;
          }

          .bounce-in {
            margin-top: 15px;

            .date {
              & > div {
                font-family: Rubik, sans-serif;
                font-size: 10px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.36px;
                text-align: center;
                color: ${palette('text', 5)};
              }
            }
          }
        }
      }
    }
  }
`;

export default DashAppHolder;
