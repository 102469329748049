import { generateMedia } from 'styled-media-query';

const rowStyle = {
  width: '100%',
  display: 'flex',
  flexFlow: 'row wrap',
};
const colStyle = {
  marginBottom: '16px',
};
const gutter = 16;
const basicStyle = {
  rowStyle,
  colStyle,
  gutter,
};

export const mediaSettings = generateMedia({
  desktop: '1269px',
  tablet: '1023px',
  mobile: '767px',
});

export const generalMediaSettings = generateMedia({
  xs: '576px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1600px',
});

export default basicStyle;
