import { all, put, takeEvery, fork } from 'redux-saga/effects';
import qs from 'query-string';
import actions from './actions';
import RestApi from '../../helpers/api';

const api = new RestApi('benchmark/companies');
const apiTimeline = new RestApi('benchmark/timeline');
const apiCompanies = new RestApi('benchmark/companies-for-select');
const apiIndustries = new RestApi('benchmark/industries-for-select');

export function* loadRequest() {
  yield takeEvery(actions.LOAD_REQUEST, function*({ payload }) {
    try {
      const {
        sentimentGroupId,
        subSentimentGroupId,
        date,
        companyIds,
        industryId,
        isAverage,
        companies,
        isEarlyData,
      } = payload;

      const response = yield api.load({
        companyIds,
        date,
        isEarlyData,
        industryId,
        isAvarage: isAverage,
        scoreItemId: sentimentGroupId,
        subScoreItemId: subSentimentGroupId,
      });

      if (response.status === 'success') {
        const {
          history,
          scoreComperison,
          peerComparison,
          distribution,
          distributionMaxValue,
          subScoreComperison,
        } = response.data;

        yield put(
          actions.loadSuccess({
            history,
            scoreComperison,
            subScoreComperison,
            peerComparison,
            distribution,
            distributionMaxValue,
            colorsSet:
              companies &&
              Object.keys(qs.parse(companies))
                .map(el => {
                  switch (el) {
                    case 'c1':
                      return '#4787FE';
                    case 'c2':
                      return '#36AE68';
                    case 'c3':
                      return '#F88C33';
                    case 'c4':
                      return '#8459FB';
                    case 'c5':
                      return '#98DDFF';
                    default:
                      return '#4787FE';
                  }
                })
                .concat(
                  // eslint-disable-next-line no-nested-ternary
                  isAverage === 'true' && industryId
                    ? ['#1A5172', '#E3CC00']
                    : // eslint-disable-next-line no-nested-ternary
                    isAverage === 'true'
                    ? ['#1A5172']
                    : industryId
                    ? ['#E3CC00']
                    : [],
                ),
          }),
        );
      } else if (response.status === 'error') {
        console.log('loadRiskRequest response status: error');
        yield put(actions.loadError());
      }
    } catch (e) {
      console.log('loadRiskRequest error', e);
    }
  });
}

export function* loadCompaniesRequest() {
  yield takeEvery(actions.LOAD_COMPANIES_REQUEST, function*({ payload }) {
    try {
      const { search, selectedCompanyIds, isProviders } = payload;

      const response = yield apiCompanies.load({
        search,
        selectedCompanyIds,
        isProviders,
      });

      if (response.status === 'success') {
        yield put(
          actions.loadCompaniesSuccess({
            companiesList: response.data,
          }),
        );
      } else if (response.status === 'error') {
        console.log('loadRiskRequest response status: error');
      }
    } catch (e) {
      console.log('loadRiskRequest error', e);
    }
  });
}

export function* loadIndustriesRequest() {
  yield takeEvery(actions.LOAD_INDUSTRIES_REQUEST, function*({ payload }) {
    try {
      const { search } = payload;

      const response = yield apiIndustries.load({
        search,
      });

      if (response.status === 'success') {
        yield put(
          actions.loadIndustriesSuccess({
            industriesList: response.data,
          }),
        );
      } else if (response.status === 'error') {
        console.log('loadRiskRequest response status: error');
      }
    } catch (e) {
      console.log('loadRiskRequest error', e);
    }
  });
}

export function* loadTimelineRequest() {
  yield takeEvery(actions.LOAD_TIMELINE_REQUEST, function*() {
    try {
      const response = yield apiTimeline.load();

      if (response.status === 'success') {
        const timeline = response.data;

        yield put(
          actions.loadTimelineSuccess({
            timeline,
          }),
        );
      } else {
        console.log('loadRiskRequest response', response.error);
      }
    } catch (e) {
      console.log('loadTimelineRequest', e);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(loadRequest), fork(loadTimelineRequest), fork(loadCompaniesRequest), fork(loadIndustriesRequest)]);
}
