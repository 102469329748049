import { all, call, fork, put, take, takeEvery } from 'redux-saga/effects';
import { v4 } from 'uuid';
import { channel } from 'redux-saga';
import profileActions from './actions';
import { reduxSagaFirebase, storage } from '../../redux/firestore';
import { callApi } from '../../helpers/api';

export function* sendAttachments() {
  yield takeEvery(profileActions.SEND_ATTACHMENTS, function*({ payload }) {
    try {
      const { companyId, files, videos } = payload;
      yield call(
        reduxSagaFirebase.firestore.setDocument,
        `companies_user_data/${companyId}`,
        {
          videos,
          files,
        },
        { merge: true },
      );

      yield put(profileActions.sendProfileInfoSuccess());
    } catch (error) {
      console.error('error', error);
    }
  });
}

export function* getAttachments() {
  yield takeEvery(profileActions.GET_ATTACHMENTS, function*({ payload }) {
    try {
      const { companyId } = payload;
      const snapshot = yield call(reduxSagaFirebase.firestore.getDocument, `companies_user_data/${companyId}`);
      const companies_user_data = snapshot.data();
      const { videos, files } = companies_user_data;

      yield put(profileActions.getAttachmentsSuccess({ videos, files }));
    } catch (error) {
      console.error('error', error);
    }
  });
}

export function* sendMyProfileInfo() {
  yield takeEvery(profileActions.SEND_PROFILE_INFO, function*({ payload }) {
    try {
      const { companyId, name, shortDescription, description, site, logo } = payload;
      const data = {
        companyname: name,
        heading: shortDescription,
        description,
        website: site,
        companylogo: logo,
      };

      yield callApi(`companies/${companyId}`, 'PATCH', data);
      yield put(profileActions.sendProfileInfoSuccess());
    } catch (error) {
      console.error('error', error);
    }
  });
}

export function* deleteAttachment() {
  yield takeEvery(profileActions.DELETE_ATTACHMENT, function*({ payload }) {
    try {
      const { name, companyId, type, array } = payload;

      if (type === 'file') {
        storage.ref(`/profile-attachments/${companyId}/${name}`).delete();
        yield call(
          reduxSagaFirebase.firestore.setDocument,
          `companies_user_data/${companyId}`,
          {
            files: array,
          },
          { merge: true },
        );
      } else {
        yield call(
          reduxSagaFirebase.firestore.setDocument,
          `companies_user_data/${companyId}`,
          {
            videos: array,
          },
          { merge: true },
        );
      }
    } catch (e) {
      console.warn('error', e);
    }
  });
}

export const fileChannel = channel();

export function* uploadFile() {
  yield takeEvery(profileActions.FILE_UPLOADING, function*({ payload }) {
    try {
      const { file, companyId, type } = payload;
      if (type === 'file') {
        const id = v4();
        const name = `${file.name}`;
        const filename = `${companyId}-${id}`;
        Object.defineProperty(file, 'name', {
          writable: true,
          value: filename,
        });

        const uploadTask = storage.ref(`/profile-attachments/${companyId}/${file.name}`).put(file);

        uploadTask.on(
          'state_changed',
          snapshot => {
            const progressPercentage = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            fileChannel.put({
              type: profileActions.CHANGE_PROPERTY,
              payload: { attr: 'progress', value: progressPercentage },
            });
          },
          error => console.warn(error),
          () => {
            storage
              .ref(`/profile-attachments/${companyId}/`)
              .child(file.name)
              .getDownloadURL()
              .then(link => {
                fileChannel.put(profileActions.fileUploadSuccess({ name, link, type: 'file' }));
              });
          },
        );
      } else if (type === 'video') {
        yield put(profileActions.fileUploadSuccess({ name: file, link: file, type: 'video' }));
      } else if (type === 'logo') {
        const filename = `${companyId}`;
        Object.defineProperty(file, 'name', {
          writable: true,
          value: filename,
        });

        const uploadTask = storage.ref(`/profile-logo/${companyId}/${file.name}`).put(file);

        uploadTask.on(
          'state_changed',
          snapshot => {
            const progressPercentage = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            fileChannel.put({
              type: profileActions.CHANGE_PROPERTY,
              payload: { attr: 'progress', value: progressPercentage },
            });
          },
          error => console.warn(error),
          () => {
            storage
              .ref(`/profile-logo/${companyId}/`)
              .child(file.name)
              .getDownloadURL()
              .then(link => {
                fileChannel.put(profileActions.fileUploadSuccess({ link, type: 'logo' }));
              });
          },
        );
      }
    } catch (e) {
      console.warn('error', e);
      yield put(profileActions.fileUploadError(e));
    }
  });
}

export function* watchFileChannel() {
  while (true) {
    const action = yield take(fileChannel);
    yield put(action);
  }
}

export default function* rootSaga() {
  yield all([
    fork(sendMyProfileInfo),
    fork(uploadFile),
    fork(watchFileChannel),
    fork(deleteAttachment),
    fork(sendAttachments),
    fork(getAttachments),
  ]);
}
