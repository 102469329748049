import actions from './actions';
import { createReducer } from '../../helpers/reducer';

// ------------------------------
// Initial State
// ------------------------------
const initState = {
  accepted: undefined,
  totalRejected: undefined,
  sent: undefined,
  total: undefined,
  loading: false,
  error: '',
};

// ------------------------------
// Action Handlers
// ------------------------------
function onLoadSuccess(state, { payload }) {
  const { data } = payload;

  return {
    ...state,
    ...data,
    loading: false,
  };
}

function onLoadError(state, { payload }) {
  return {
    ...state,
    error: payload.error,
    isLoading: false,
  };
}

function onLoad(state) {
  return {
    ...state,
    error: '',
    loading: true,
  };
}

function onClearState() {
  return {
    ...initState,
  };
}

// ------------------------------
// Exported reducer function.
// ------------------------------
export default createReducer(initState, {
  [actions.LOAD]: onLoad,
  [actions.LOAD_SUCCESS]: onLoadSuccess,
  [actions.LOAD_ERROR]: onLoadError,
  [actions.CLEAR_STATE]: onClearState,
});
