import actions from './actions';
import { createReducer } from '../../helpers/reducer';

// ------------------------------
// Initial State
// ------------------------------
const initState = {
  categories: null,
  count: 0,
  loading: false,
  error: '',
};

// ------------------------------
// Action Handlers
// ------------------------------
function onLoadSuccess(state, { payload }) {
  const { count, data } = payload;

  return {
    ...state,
    categories: data,
    count,
    error: '',
    loading: false,
  };
}

function onLoadError(state, { payload }) {
  return {
    ...state,
    error: payload.error,
    isLoading: false,
  };
}

function onLoad(state) {
  return {
    ...state,
    error: '',
    loading: true,
  };
}

function onClearState(state) {
  return {
    ...state,
    categories: null,
    count: 0,
    error: '',
    loading: false,
  };
}

// ------------------------------
// Exported reducer function.
// ------------------------------
export default createReducer(initState, {
  [actions.LOAD]: onLoad,
  [actions.LOAD_SUCCESS]: onLoadSuccess,
  [actions.LOAD_ERROR]: onLoadError,
  [actions.CLEAR_STATE]: onClearState,
});
