const COMPONENT = 'PACK';

const actions = {
  LOAD_COMPANIES_REQUEST: `${COMPONENT}_LOAD_COMPANIES_REQUEST`,
  LOAD_COMPANIES_SUCCESS: `${COMPONENT}_LOAD_COMPANIES_SUCCESS`,
  LOAD_COMPANIES_ERROR: `${COMPONENT}_LOAD_COMPANIES_ERROR`,

  LOAD_SET_COMPANY_REQUEST: `${COMPONENT}_LOAD_SET_COMPANY_REQUEST`,
  LOAD_SET_COMPANY_SUCCESS: `${COMPONENT}_LOAD_SET_COMPANY_SUCCESS`,
  LOAD_SET_COMPANY_ERROR: `${COMPONENT}_LOAD_SET_COMPANY_ERROR`,

  CHANGE_COMPANY_REQUEST: `${COMPONENT}_CHANGE_COMPANY_REQUEST`,
  CHANGE_COMPANY_SUCCESS: `${COMPONENT}_CHANGE_COMPANY_SUCCESS`,
  CHANGE_COMPANY_ERROR: `${COMPONENT}_CHANGE_COMPANY_ERROR`,

  DELETE_ANNIE_CHAT_REQUEST: `${COMPONENT}_DELETE_ANNIE_CHAT_REQUEST`,
  DELETE_ANNIE_CHAT_SUCCESS: `${COMPONENT}_DELETE_ANNIE_CHAT_SUCCESS`,
  DELETE_ANNIE_CHAT_ERROR: `${COMPONENT}_DELETE_ANNIE_CHAT_ERROR`,

  DELETE_HISTORY_COMPANY_REQUEST: `${COMPONENT}_DELETE_HISTORY_COMPANY_REQUEST`,
  DELETE_HISTORY_COMPANY_SUCCESS: `${COMPONENT}_DELETE_HISTORY_COMPANY_SUCCESS`,
  DELETE_HISTORY_COMPANY_ERROR: `${COMPONENT}_DELETE_HISTORY_COMPANY_ERROR`,

  DELETE_COMPANY_REQUEST: `${COMPONENT}_DELETE_COMPANY_REQUEST`,
  DELETE_COMPANY_SUCCESS: `${COMPONENT}_DELETE_COMPANY_SUCCESS`,
  DELETE_COMPANY_ERROR: `${COMPONENT}_DELETE_COMPANY_ERROR`,

  RUN_COMPANY_REQUEST: `${COMPONENT}_RUN_COMPANY_REQUEST`,
  RUN_COMPANY_SUCCESS: `${COMPONENT}_RUN_COMPANY_SUCCESS`,
  RUN_COMPANY_ERROR: `${COMPONENT}_RUN_COMPANY_ERROR`,

  LOAD_BUY_CREDIT_REQUEST: `${COMPONENT}_LOAD_BUY_CREDIT_REQUEST`,
  LOAD_BUY_CREDIT_SUCCESS: `${COMPONENT}_LOAD_BUY_CREDIT_SUCCESS`,
  LOAD_BUY_CREDIT_ERROR: `${COMPONENT}_LOAD_BUY_CREDIT_ERROR`,

  CHANGE_CREDITS_PROCESS: `${COMPONENT}_CHANGE_CREDITS_PROCESS`,

  CLEAR: `${COMPONENT}_CLEAR`,

  onChangeCreditsProcess: data => ({
    type: actions.CHANGE_CREDITS_PROCESS,
    payload: data,
  }),

  loadBuyCreditRequest: data => ({
    type: actions.LOAD_BUY_CREDIT_REQUEST,
    payload: data,
  }),
  loadBuyCreditSuccess: data => ({
    type: actions.LOAD_BUY_CREDIT_SUCCESS,
    payload: data,
  }),
  loadBuyCreditError: data => ({
    type: actions.LOAD_BUY_CREDIT_ERROR,
    payload: data,
  }),

  deleteHistoryCompanyRequest: data => ({
    type: actions.DELETE_HISTORY_COMPANY_REQUEST,
    payload: data,
  }),
  deleteHistoryCompanySuccess: data => ({
    type: actions.DELETE_HISTORY_COMPANY_SUCCESS,
    payload: data,
  }),
  deleteHistoryCompanyError: data => ({
    type: actions.DELETE_HISTORY_COMPANY_ERROR,
    payload: data,
  }),

  deleteCompanyRequest: data => ({
    type: actions.DELETE_COMPANY_REQUEST,
    payload: data,
  }),
  deleteCompanySuccess: data => ({
    type: actions.DELETE_COMPANY_SUCCESS,
    payload: data,
  }),
  deleteCompanyError: data => ({
    type: actions.DELETE_COMPANY_ERROR,
    payload: data,
  }),

  runCompanyRequest: data => ({
    type: actions.RUN_COMPANY_REQUEST,
    payload: data,
  }),
  runCompanySuccess: data => ({
    type: actions.RUN_COMPANY_SUCCESS,
    payload: data,
  }),
  runCompanyError: data => ({
    type: actions.RUN_COMPANY_ERROR,
    payload: data,
  }),

  loadCompaniesRequest: data => ({
    type: actions.LOAD_COMPANIES_REQUEST,
    payload: data,
  }),
  loadCompaniesSuccess: data => ({
    type: actions.LOAD_COMPANIES_SUCCESS,
    payload: data,
  }),
  loadCompaniesError: data => ({
    type: actions.LOAD_COMPANIES_ERROR,
    payload: data,
  }),

  loadSetCompanyRequest: data => ({
    type: actions.LOAD_SET_COMPANY_REQUEST,
    payload: data,
  }),
  loadSetCompanySuccess: data => ({
    type: actions.LOAD_SET_COMPANY_SUCCESS,
    payload: data,
  }),
  loadSetCompanyError: data => ({
    type: actions.LOAD_SET_COMPANY_ERROR,
    payload: data,
  }),

  changeCompanyRequest: data => ({
    type: actions.CHANGE_COMPANY_REQUEST,
    payload: data,
  }),
  changeCompanySuccess: data => ({
    type: actions.CHANGE_COMPANY_SUCCESS,
    payload: data,
  }),
  changeCompanyError: data => ({
    type: actions.CHANGE_COMPANY_ERROR,
    payload: data,
  }),

  deleteAnnieChatRequest: data => ({
    type: actions.DELETE_ANNIE_CHAT_REQUEST,
    payload: data,
  }),
  deleteAnnieChatSuccess: data => ({
    type: actions.DELETE_ANNIE_CHAT_SUCCESS,
    payload: data,
  }),
  deleteAnnieChatError: data => ({
    type: actions.DELETE_ANNIE_CHAT_ERROR,
    payload: data,
  }),

  clear: () => ({
    type: actions.CLEAR,
  }),
};
export default actions;
