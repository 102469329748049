const actions = {
  UPDATE_SSO_LINK: 'UPDATE_SSO_LINK',
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT: 'LOGOUT',
  SLIDE_IN: 'SLIDE_IN',
  SLIDE_OUT: 'SLIDE_OUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  LOG_REQUEST: 'LOG_REQUEST',

  log: data => ({
    type: actions.LOG_REQUEST,
    payload: data
  }),
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: data => ({
    type: actions.LOGIN_REQUEST,
    payload: data,
  }),
  loginSuccess: data => ({
    type: actions.LOGIN_SUCCESS,
    payload: data,
  }),
  loginError: data => ({
    type: actions.LOGIN_ERROR,
    payload: data,
  }),
  updateSsoLink: data => ({
    type: actions.UPDATE_SSO_LINK,
    payload: data,
  }),
  logout: () => ({
    type: actions.LOGOUT,
  }),
  slideIn: data => ({
    type: actions.SLIDE_IN,
    payload: data,
  }),
  slideOut: () => ({
    type: actions.SLIDE_OUT,
  }),
};
export default actions;
