import actions from './actions';
import { createReducer } from '../../helpers/reducer';
import { getAuthData } from '../../helpers/auth';

const authData = getAuthData();
// ------------------------------
// Initial State
// ------------------------------
const initState = {
  idToken: authData ? authData.idToken : null,
  claims: authData ? authData.claims : null,
  // profile: authData ? authData.profile : null,
  error: '',
  ssoToken: '',
  isSaveloader: false,
};

// ------------------------------
// Action Handlers
// ------------------------------

function onLoginSuccess(state, { payload }) {
  return {
    ...state,
    idToken: payload.idToken,
    claims: payload.claims,
    isSaveloader: false,
    error: '',
  };
}

function onLoginError(state, { payload }) {
  return {
    ...state,
    idToken: null,
    // profile: null,
    isSaveloader: false,
    error: payload.error,
  };
}

function onLoginRequest(state) {
  return {
    ...state,
    isSaveloader: true,
  };
}
function updateSsoToken(state, { payload }) {
  return {
    ...state,
    ssoToken: payload,
  };
}

function onLogout(state) {
  return {
    ...state,
    idToken: null,
    profile: null,
    error: '',
  };
}

// ------------------------------
// Exported reducer function.
// ------------------------------
export default createReducer(initState, {
  [actions.LOGIN_SUCCESS]: onLoginSuccess,
  [actions.LOGIN_ERROR]: onLoginError,
  [actions.LOGOUT]: onLogout,
  [actions.LOGIN_REQUEST]: onLoginRequest,
  [actions.UPDATE_SSO_LINK]: updateSsoToken,
});
