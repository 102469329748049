import changePasswordActions from './actions';
import { createReducer } from '../../helpers/reducer';
import { keyBy } from '../../helpers/core';

// ------------------------------
// Local util functions
// ------------------------------
function validate({ attr, value = '', newPasswordValue = '' }) {
  let error = '';
  const defaultErrorMessage =
    'Your password must be at least 7 characters and include upper and lower case, and at least 1 special character.';
  const notEqualPassErrorMessage = 'Your passwords are not equal';

  if (attr === 'oldPassword' || attr === 'confirmPassword' || attr === 'newPassword') {
    if (!value || value.length < 7) {
      error = defaultErrorMessage;
    }
    if (value && !/[a-z]/.test(value)) {
      error = defaultErrorMessage;
    }
    if (value && !/[A-Z]/.test(value)) {
      error = defaultErrorMessage;
    }
    if (value && !/\W/.test(value)) {
      error = defaultErrorMessage;
    }
  }

  if (attr === 'confirmPassword') {
    if (value !== newPasswordValue) {
      error = notEqualPassErrorMessage;
    }
  }

  return error;
}

// ------------------------------
// Initial State
// ------------------------------
const initState = {
  oldPassword: {
    value: '',
    error: validate({ attr: 'oldPassword' }),
    name: 'oldPassword',
    touched: false,
  },
  newPassword: {
    value: '',
    error: validate({ attr: 'newPassword' }),
    name: 'newPassword',
    touched: false,
  },
  confirmPassword: {
    value: '',
    error: validate({ attr: 'confirmPassword' }),
    name: 'confirmPassword',
    touched: false,
  },
  isSaveloader: false,
};

// ------------------------------
// Action Handlers
// ------------------------------
function onChangeProperty(state, { payload }) {
  const { attr, value } = payload;
  const newPasswordValue = state.newPassword.value;

  state[attr] = {
    value,
    error: validate({ attr, value, newPasswordValue }),
    name: attr,
    touched: true,
  };

  return { ...state };
}

function onTouch(state, { payload }) {
  const { props } = payload;
  const propsArray = props.map(prop => state[prop]);

  const updatedState = keyBy(
    propsArray,
    prop => prop.name,
    prop => ({ ...prop, touched: true }),
  );

  return { ...state, ...updatedState };
}

function onChangePasswordSuccess(state) {
  return {
    ...state,
    isSaveloader: false,
  };
}

function onChangePasswordRequest(state) {
  return {
    ...state,
    isSaveloader: true,
  };
}

function onChangePasswordError(state, { payload }) {
  const { errors } = payload;
  const { oldPassword } = state;
  state[errors[0].field] = {
    value: oldPassword.value,
    error: errors[0].message,
    name: errors[0].field,
    touched: true,
  };
  return { ...state, isSaveloader: false };
}

function onClearState() {
  return { ...initState };
}

// ------------------------------
// Exported reducer function.
// ------------------------------
export default createReducer(initState, {
  [changePasswordActions.CHANGE_PROPERTY]: onChangeProperty,
  [changePasswordActions.TOUCH]: onTouch,
  [changePasswordActions.CLEAR_STATE]: onClearState,
  [changePasswordActions.CHANGE_PASSWORD_SUCCESS]: onChangePasswordSuccess,
  [changePasswordActions.CHANGE_PASSWORD_ERROR]: onChangePasswordError,
  [changePasswordActions.CHANGE_PASSWORD_REQUEST]: onChangePasswordRequest,
});
