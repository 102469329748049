import actions from './actions';
import { createReducer } from '../../helpers/reducer';

// ------------------------------
// Initial State
// ------------------------------
const initState = {
  allcompanies: '',
  mycompetitorscustomers: '',
  companieslikemycustomers: '',
  mycustomers: '',
  distributors: '',
  providers: '',
  providerscategory: '',
};

// ------------------------------
// Action Handlers
// ------------------------------
function onLoadSuccess(state, { payload }) {
  return {
    ...payload
  };
}

function onLoadError(state, { payload }) {
  return {
    ...state,
    error: payload.error,
    isLoading: false,
  };
}

function onLoad(state) {
  return {
    ...state,
    error: '',
    loading: true,
  };
}

// ------------------------------
// Exported reducer function.
// ------------------------------
export default createReducer(initState, {
  [actions.LOAD]: onLoad,
  [actions.LOAD_SUCCESS]: onLoadSuccess,
  [actions.LOAD_ERROR]: onLoadError,
});
