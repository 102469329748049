import actions from './actions';
import { createReducer } from '../../helpers/reducer';

// ------------------------------
// Initial State
// ------------------------------
const initState = {
  bundle: {},
  companies: [],
  keywords: [],
  loading: false,
};

// ------------------------------
// Action Handlers
// ------------------------------
function onLoadBundleSuccess(state, { payload }) {
  const { bundle } = payload;

  return {
    ...state,
    bundle,
  };
}

function onLoadCompaniesSuccess(state, { payload }) {
  const { companies } = payload;

  return {
    ...state,
    companies,
    loading: false,
  };
}

function onSetFilter(state, { payload }) {
  const { keywords } = payload;

  return {
    ...state,
    keywords,
  };
}

function onLoadError(state) {
  return {
    ...state,
    isLoading: false,
  };
}

function onloadCompaniesRequest(state) {
  return {
    ...state,
    loading: true,
  };
}

function onClear(state) {
  return {
    ...state,
    keywords: [],
  };
}

// ------------------------------
// Exported reducer function.
// ------------------------------
export default createReducer(initState, {
  [actions.LOAD_BUNDLE_SUCCESS]: onLoadBundleSuccess,
  [actions.LOAD_COMPANIES_SUCCESS]: onLoadCompaniesSuccess,
  [actions.LOAD_COMPANIES_REQUEST]: onloadCompaniesRequest,
  [actions.SET_FILTER]: onSetFilter,
  [actions.LOAD_ERROR]: onLoadError,
  [actions.CLEAR]: onClear,
});
