import actions from './actions';
import { createReducer } from '../../helpers/reducer';

// ------------------------------
// Initial State
// ------------------------------
const initState = {
  isEarly: true,
  isEarlyDataPopup: false,
  loading: false,
  error: '',
};

// ------------------------------
// Action Handlers
// ------------------------------
function onSwitchDataMode(state) {
  return { ...state, isEarly: !state.isEarly };
}

function onSetDataModeSuccess(state) {
  return { ...state, isEarlyDataPopup: !state.isEarlyDataPopup };
}

// ------------------------------
// Exported reducer function.
// ------------------------------
export default createReducer(initState, {
  [actions.SWITCH_DATA_MODE]: onSwitchDataMode,
  [actions.SET_DATA_MODE]: onSetDataModeSuccess,
});
