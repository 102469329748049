const COMPONENT = 'QUOTE';

const actions = {
  LOAD: `${COMPONENT}_LOAD`,
  LOAD_SUCCESS: `${COMPONENT}_LOAD_SUCCESS`,
  LOAD_ERROR: `${COMPONENT}_LOAD_ERROR`,
  SEND_MESSAGE: `${COMPONENT}_SEND_MESSAGE`,
  CLEAR_STATE: `${COMPONENT}_LOAD_ERROR`,
  PROVIDER_REJECT: `${COMPONENT}_PROVIDER_REJECT`,
  PROVIDER_REJECT_SUCCESS: `${COMPONENT}_PROVIDER_REJECT_SUCCESS`,
  PROVIDER_RESPOND: `${COMPONENT}_PROVIDER_RESPOND`,
  PROVIDER_RESPOND_SUCCESS: `${COMPONENT}_PROVIDER_RESPOND_SUCCESS`,
  MEMBER_REJECT: `${COMPONENT}_MEMBER_REJECT`,
  MEMBER_REJECT_SUCCESS: `${COMPONENT}_MEMBER_REJECT_SUCCESS`,
  MEMBER_ACCEPT: `${COMPONENT}_MEMBER_ACCEPT`,
  MEMBER_ACCEPT_SUCCESS: `${COMPONENT}_MEMBER_ACCEPT_SUCCESS`,
  FILE_UPLOADING: `${COMPONENT}FILE_UPLOADING`,
  FILE_UPLOAD_ERROR: `${COMPONENT}FILE_UPLOAD_ERROR`,
  FILE_UPLOAD_SUCCESS: `${COMPONENT}FILE_UPLOAD_SUCCESS`,
  LOAD_ATTACHMENTS: `${COMPONENT}LOAD_ATTACHMENTS`,
  LOAD_ATTACHMENTS_SUCCESS: `${COMPONENT}LOAD_ATTACHMENTS_SUCCESS`,
  LOAD_ATTACHMENTS_ERROR: `${COMPONENT}LOAD_ATTACHMENTS_ERROR`,
  DELETE_ATTACHMENT: `${COMPONENT}DELETE_ATTACHMENT`,
  CHANGE_PROPERTY: `${COMPONENT}CHANGE_PROPERTY`,

  load: data => ({
    type: actions.LOAD,
    payload: data,
  }),

  loadSuccess: data => ({
    type: actions.LOAD_SUCCESS,
    payload: data,
  }),

  loadError: data => ({
    type: actions.LOAD_ERROR,
    payload: data,
  }),

  sendMessage: data => ({
    type: actions.SEND_MESSAGE,
    payload: data,
  }),

  providerReject: data => ({
    type: actions.PROVIDER_REJECT,
    payload: data,
  }),

  providerRejectSuccess: data => ({
    type: actions.PROVIDER_REJECT_SUCCESS,
    payload: data,
  }),

  providerRespond: data => ({
    type: actions.PROVIDER_RESPOND,
    payload: data,
  }),

  providerRespondSuccess: data => ({
    type: actions.PROVIDER_RESPOND_SUCCESS,
    payload: data,
  }),

  memberAccept: data => ({
    type: actions.MEMBER_ACCEPT,
    payload: data,
  }),

  memberAcceptSuccess: data => ({
    type: actions.MEMBER_ACCEPT_SUCCESS,
    payload: data,
  }),

  memberReject: data => ({
    type: actions.MEMBER_REJECT,
    payload: data,
  }),

  memberRejectSuccess: data => ({
    type: actions.MEMBER_REJECT_SUCCESS,
    payload: data,
  }),

  clearState: () => ({
    type: actions.CLEAR_STATE,
  }),

  fileIsUploading: data => ({
    type: actions.FILE_UPLOADING,
    payload: data,
  }),

  fileUploadSuccess: data => ({
    type: actions.FILE_UPLOAD_SUCCESS,
    payload: data,
  }),

  fileUploadError: data => ({
    type: actions.FILE_UPLOAD_ERROR,
    payload: data,
  }),

  loadAttachmentsRequest: data => ({
    type: actions.LOAD_ATTACHMENTS,
    payload: data,
  }),

  loadAttachmentsSuccess: data => ({
    type: actions.LOAD_ATTACHMENTS_SUCCESS,
    payload: data,
  }),

  loadAttachmentsError: data => ({
    type: actions.LOAD_ATTACHMENTS_ERROR,
    payload: data,
  }),

  deleteAttachment: data => ({
    type: actions.DELETE_ATTACHMENT,
    payload: data,
  }),

  changeProperty: data => ({
    type: actions.CHANGE_PROPERTY,
    payload: data,
  }),
};

export default actions;
