const COMPONENT = 'WEBEX';

const actions = {
  LOAD_REQUEST: `${COMPONENT}LOAD_REQUEST`,
  LOAD_SUCCESS: `${COMPONENT}LOAD_SUCCESS`,
  CLEAR: `${COMPONENT}CLEAR`,

  loadRequest: data => ({
    type: actions.LOAD_REQUEST,
    payload: data,
  }),

  loadSuccess: data => ({
    type: actions.LOAD_SUCCESS,
    payload: data,
  }),

  clear: () => ({
    type: actions.CLEAR,
  }),
};
export default actions;
