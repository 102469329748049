import actions from './actions';
import { createReducer } from '../../helpers/reducer';

// ------------------------------
// Initial State
// ------------------------------
const initState = {
  swotDetails: null,
  swotRecommendations: null,
  swotReadiness: null,
};

// ------------------------------
// Action Handlers
// ------------------------------
function onLoadSuccess(state, { payload }) {
  const { swotDetails, swotReadiness, swotRecommendations } = payload;

  return {
    ...state,
    swotDetails,
    swotReadiness,
    swotRecommendations,
  };
}

function onLoadError(state) {
  return {
    ...state,
  };
}

function onClear() {
  return {
    initState,
  };
}

// ------------------------------
// Exported reducer function.
// ------------------------------
export default createReducer(initState, {
  [actions.LOAD_SUCCESS]: onLoadSuccess,
  [actions.LOAD_ERROR]: onLoadError,
  [actions.CLEAR]: onClear,
});
