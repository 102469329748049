import { all, put, takeEvery, fork } from 'redux-saga/effects';
import actions from './actions';
import RestApi from '../../helpers/api';

const api = new RestApi('chat-gpt/company-swot');

export function* loadRequest() {
  yield takeEvery(actions.LOAD_REQUEST, function*({ payload }) {
    const { companyId, userId } = payload;

    try {
      const response = yield api.loadCustom('https://aniline-cloud-run-test-2km27py7ha-wl.a.run.app/', {
        companyId,
        userId,
      });

      if (response.status === 'success') {
        const { swot, swotRecomendations, swotReadiness, condition } = response.data;

        yield put(
          actions.loadSuccess({
            swotDetails: swot,
            swotRecommendations: swotRecomendations,
            swotReadiness,
            condition,
          }),
        );
      } else {
        console.log('loadRiskRequest response', response.error);
      }
    } catch (e) {
      console.log('loadRiskRequest', e);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(loadRequest)]);
}
