import profileActions from './actions';
import { createReducer } from '../../helpers/reducer';

// ------------------------------
// Initial State
// ------------------------------
const initState = {
  isEditable: false,
  name: '',
  site: '',
  shortDescription: '',
  description: '',
  error: '',
  files: [],
  videos: [],
  progress: 0,
  fileIsUploading: false,
  imageIsUploading: false,
  uploaded: false,
  attachmentError: null,
  videoError: null,
  videoUrl: null,
  logo: null,
  isUserDataLoaded: false,
};

// ------------------------------
// Action Handlers
// ------------------------------
function onChangeProperty(state, { payload }) {
  const { attr, value } = payload;

  state[attr] = value;

  return { ...state };
}

function onProfileRequest(state) {
  return {
    ...state,
  };
}

function onSentProfileInfoSuccess(state) {
  return {
    ...state,
    uploaded: false,
  };
}

function onQuoteError(state, { payload }) {
  return {
    ...state,
    error: payload.error,
  };
}

function onProfileInfoClearState() {
  return {
    ...initState,
    /* ...state,
    isEditable: false,
    uploaded: false,
    attachmentError: null,
    videoError: null,
    error: '',
    videoUrl: null,
    files: [],
    videos: [],
    logo: null,
    progress: 0,
    isUserDataLoaded: false, */
  };
}

function onSetEditable(state) {
  return {
    ...state,
    isEditable: true,
  };
}

function onSetExistedValues(state, { payload }) {
  const { name, shortDescription, description, site, files, videos, logo } = payload;

  return {
    ...state,
    name,
    shortDescription,
    description,
    site,
    files,
    videos,
    logo,
    isUserDataLoaded: true,
  };
}

function onFileUploading(state, { payload }) {
  const { type } = payload;
  return {
    ...state,
    fileIsUploading: type === 'file' && true,
    imageIsUploading: type === 'logo' && true,
    error: '',
  };
}

function onFileUploadError(state, { payload }) {
  return {
    ...state,
    error: payload.error,
    fileIsUploading: false,
    imageIsUploading: false,
    progress: 0,
  };
}

function onFileUploadSuccess(state, { payload }) {
  const { name, link, type } = payload;

  return {
    ...state,
    fileIsUploading: false,
    imageIsUploading: false,
    uploaded: true,
    videoUrl: null,
    error: '',
    progress: 0,
    videos: type === 'video' ? [...state.videos, { name, link, type }] : state.videos,
    files: type === 'file' ? [...state.files, { name, link, type }] : state.files,
    logo: type === 'logo' ? link : state.logo,
  };
}

function onGetAttachmentsSuccess(state, { payload }) {
  const { videos, files } = payload;

  return {
    ...state,
    videos: videos || [],
    files: files || [],
  };
}

// ------------------------------
// Exported reducer function.
// ------------------------------
export default createReducer(initState, {
  [profileActions.QUOTE_REQUEST]: onProfileRequest,
  [profileActions.GET_ATTACHMENTS_SUCCESS]: onGetAttachmentsSuccess,
  [profileActions.SET_EDITABLE]: onSetEditable,
  [profileActions.SET_EXISTED_VALUES]: onSetExistedValues,
  [profileActions.SEND_PROFILE_INFO_SUCCESS]: onSentProfileInfoSuccess,
  [profileActions.QUOTE_ERROR]: onQuoteError,
  [profileActions.CHANGE_PROPERTY]: onChangeProperty,
  [profileActions.PROFILE_INFO_CLEAR]: onProfileInfoClearState,
  [profileActions.FILE_UPLOAD_ERROR]: onFileUploadError,
  [profileActions.FILE_UPLOAD_SUCCESS]: onFileUploadSuccess,
  [profileActions.FILE_UPLOADING]: onFileUploading,
});
