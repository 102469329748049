import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import RestApi from '../../helpers/api';
import { reduxSagaFirebase } from '../../redux/firestore';

const api = new RestApi('companies');

export function* load(data) {
  try {
    const { payload } = data;
    const { page, pageSize, query, column, order, type, customerOf, isEarlyData = true } = payload;

    const params = {
      page,
      pageSize,
      query,
      column,
      order,
      type,
      customerOf,
      isEarlyData,
      products: true,
      // products: type !== 'company',
    };

    if (order) {
      params.order = order;
    }

    const response = yield api.load(params);

    if (response.status === 'success') {
      const { data, count } = response;

      yield put(
        actions.loadSuccess({
          data,
          count,
          page,
        }),
      );
    } else if (response.status === 'error') {
      const { error } = response.data;

      yield put(actions.loadError(error));
    }
  } catch (error) {
    console.warn(error);
  }
}

export function* saveSearchList({ payload }) {
  try {
    const { id, companyId } = payload;
    yield call(
      reduxSagaFirebase.firestore.setDocument,
      `companies_user_data/${companyId}/market-research/${id}`,
      {
        ...payload,
      },
      { merge: true },
    );
  } catch (error) {
    console.warn(error);
  }
}

export function* deleteSearchList({ payload }) {
  try {
    const { id, companyId } = payload;
    yield call(reduxSagaFirebase.firestore.deleteDocument, `companies_user_data/${companyId}/market-research/${id}`, {
      merge: true,
    });
  } catch (error) {
    console.warn(error);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD, load),
    takeEvery(actions.SAVE_SEARCH_LIST, saveSearchList),
    takeEvery(actions.DELETE_SEARCH_LIST, deleteSearchList),
  ]);
}
