const COMPONENT = 'FORGOT_PASSWORD';

const actions = {
  CHANGE_PROPERTY: `${COMPONENT}CHANGE_PROPERTY`,
  SET_PROPERTY: `${COMPONENT}SET_PROPERTY`,
  TOUCH: `${COMPONENT}TOUCH`,
  CLEAR_STATE: `${COMPONENT}CLEAR_STATE`,
  FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_ERROR: 'FORGOT_PASSWORD_ERROR',

  changeProperty: data => ({
    type: actions.CHANGE_PROPERTY,
    payload: data,
  }),

  touch: data => ({
    type: actions.TOUCH,
    payload: data,
  }),

  forgotPassword: data => ({
    type: actions.FORGOT_PASSWORD_REQUEST,
    payload: data,
  }),
  forgotPasswordSuccess: data => ({
    type: actions.FORGOT_PASSWORD_SUCCESS,
    payload: data,
  }),
  forgotPasswordError: data => ({
    type: actions.FORGOT_PASSWORD_ERROR,
    payload: data,
  }),

  clearState: () => ({
    type: actions.CLEAR_STATE,
  }),
};
export default actions;
