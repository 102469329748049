import actions from './actions';
import { createReducer } from '../../helpers/reducer';

// ------------------------------
// Initial State
// ------------------------------
const initState = {
  data: null,
  isPublished: false,
  executiveSummary: undefined,
  loading: false,
  historicalChartView: 3,
  updating: false,
  validation: false,
  publishing: false,
  saveActive: true,
  created: undefined,
  updated: undefined,
  rangeErrorId: undefined,
  isVisible: false,
  confirm: false,
  isPrivate: false,
  reportId: undefined,
  reportName: undefined,
  reportDate: undefined,
  isDefTour: false,
  confirmLoading: false,
  dateValues: {
    startDate: '',
    endDate: '',
    description: '',
  },

  corporate: {
    startDate: '',
    endDate: '',
    description: '',
    validation: false,
  },

  talent: {
    startDate: '',
    endDate: '',
    description: '',
    validation: false,
  },

  program: {
    startDate: '',
    endDate: '',
    description: '',
    validation: false,
  },
};

// ------------------------------
// Action Handlers
// ------------------------------
function onLoadRiskSuccess(state, { payload }) {
  const { data, executiveSummary, reportName, isPublished, updated, created, reportDate, isPrivate } = payload;

  return {
    ...state,
    data,
    executiveSummary,
    isPublished,
    reportName,
    reportDate,
    updated,
    created,
    isPrivate,
  };
}

function onChangeRangeProperty(state, { payload }) {
  const { attr, subAttr, value } = payload;

  return {
    ...state,
    saveActive: false,
    [attr]: {
      ...state[`${attr}`],
      [subAttr]: value,
    },
  };
}

function onRangeFormError(state, { payload }) {
  const { sentimentGroupId } = payload;

  return {
    ...state,
    rangeErrorId: sentimentGroupId,
  };
}

function onUpdateRiskRequest(state) {
  return {
    ...state,
    validation: false,
    updating: !state.updating,
  };
}

function onPublishRiskRequest(state, { payload }) {
  const { validation } = payload;

  return {
    ...state,
    validation,
    publishing: validation ? !state.publishing : false,
  };
}

function onPublishRiskSuccess(state, { payload }) {
  const { validation } = payload;

  return {
    ...state,
    isPublished: true,
    validation,
    publishing: false,
  };
}

function onPublishRiskValidation(state, { payload }) {
  const { validation } = payload;

  return {
    ...state,
    validation,
  };
}

function onUpdateRiskSuccess(state, { payload }) {
  const { updated } = payload;
  return {
    ...state,
    updated,
    validation: false,
    updating: !state.updating,
    saveActive: true,
  };
}

function onEditCard(state, { payload }) {
  const { sentimentGroupId, cardId, attr, value } = payload;

  const group = state.data.map(el => {
    if (el.id === sentimentGroupId) {
      const cards = el.cards.map(el => {
        if (el.id === cardId) {
          return { ...el, [attr]: value };
        }
        return el;
      });
      return { ...el, cards };
    }
    return el;
  });

  return {
    ...state,
    data: group,
    validation: false,
    saveActive: false,
  };
}

function onEditPieComent(state, { payload }) {
  const { sentimentGroupId, cardId, attr, value } = payload;

  const group = state.data.map(el => {
    if (el.id === sentimentGroupId) {
      const pies = el.pies.map(el => {
        if (el.id === cardId) {
          return { ...el, [attr]: { ...el[attr], comments: value } };
        }
        return el;
      });
      return { ...el, pies };
    }
    return el;
  });

  return {
    ...state,
    data: group,
    validation: false,
    saveActive: false,
  };
}

function onAddRange(state, { payload }) {
  const { data } = state;
  const { sentimentGroupId, dateValues, validation, sentimentGroupName } = payload;

  if (dateValues && !validation) {
    const mapedData = data.map(el => {
      if (sentimentGroupId === el.id) {
        const diagrams = el.diagrams.map(el => {
          let dateRanges = [];

          if (el.dateRanges && el.dateRanges.length) {
            el.dateRanges.push(dateValues);

            return { ...el };
          }
          dateRanges = [dateValues];

          return { ...el, dateRanges };
        });

        return { ...el, diagrams };
      }

      return el;
    });

    return {
      ...state,
      data: mapedData,
      validation: false,
      saveActive: false,
      rangeErrorId: undefined,
      corporate: {
        startDate: '',
        endDate: '',
        description: '',
      },

      talent: {
        startDate: '',
        endDate: '',
        description: '',
      },

      program: {
        startDate: '',
        endDate: '',
        description: '',
      },
    };
  }
  return {
    ...state,
    [sentimentGroupName]: {
      ...state[sentimentGroupName],
      validation,
    },
  };
}

function onRemoveRange(state, { payload }) {
  const { data } = state;
  const { sentimentGroupId, startDate } = payload;

  const mapedData = data.map(el => {
    if (sentimentGroupId === el.id) {
      const diagrams = el.diagrams.map(el => {
        return { ...el, dateRanges: el.dateRanges.filter(el => el.startDate !== startDate) };
      });

      return { ...el, diagrams };
    }

    return el;
  });

  return {
    ...state,
    data: mapedData,
    validation: false,
    saveActive: false,
  };
}

function onChangeValue(state, { payload }) {
  const { data } = state;
  const { sentimentGroupId, attr, value } = payload;

  const mapedData = data.map(el => {
    if (sentimentGroupId === el.id) {
      return { ...el, [attr]: value };
    }

    return el;
  });

  return {
    ...state,
    data: mapedData,
    validation: false,
    saveActive: false,
  };
}

function onChangeSummary(state, { payload }) {
  const { attr, value } = payload;

  return {
    ...state,
    [attr]: value,
    validation: false,
    saveActive: false,
  };
}

function onSwitchModalState(state, { payload }) {
  const { isVisible } = payload;

  return {
    ...state,
    isVisible,
  };
}

function onSwitchChartView(state, { payload }) {
  const { data } = state;
  const { historicalChartView, id } = payload;
  const mapedData = data.map(el => {
    if (id === el.id) {
      return { ...el, historicalChartView };
    }

    return el;
  });

  return {
    ...state,
    data: mapedData,
    validation: false,
    saveActive: false,
  };
}

function onSetFilter(state, { payload }) {
  const { keywords } = payload;

  return {
    ...state,
    keywords,
  };
}

function onCreateCustomReportSuccess(state, { payload }) {
  const { reportId } = payload;

  return {
    ...state,
    reportId,
    isDefTour: true,
    confirmLoading: false,
  };
}

function onCreateCustomReportRequest(state) {
  return {
    ...state,
    confirmLoading: true,
  };
}

function onLoadError(state) {
  return {
    ...state,
    isLoading: false,
  };
}

function onSwitchDefTourState(state, { payload }) {
  const { isDefTour, isVisible } = payload;

  return {
    ...state,
    isDefTour,
    isVisible,
  };
}

function onClear(state) {
  return {
    ...state,
    data: null,
    isPublished: false,
    executiveSummary: undefined,
    loading: false,
    historicalChartView: 3,
    updating: false,
    validation: false,
    publishing: false,
    saveActive: true,
    created: undefined,
    updated: undefined,
    rangeErrorId: undefined,
    isVisible: false,
    confirm: false,
    isPrivate: false,
    reportId: undefined,
    reportName: undefined,
    reportDate: undefined,
    isDefTour: false,
    confirmLoading: false,
    dateValues: {
      startDate: '',
      endDate: '',
      description: '',
    },
  };
}

// ------------------------------
// Exported reducer function.
// ------------------------------
export default createReducer(initState, {
  [actions.SWITCH_DEF_TOUR_STATE]: onSwitchDefTourState,
  [actions.CREATE_CUSTOM_REPORT_SUCCESS]: onCreateCustomReportSuccess,
  [actions.CREATE_CUSTOM_REPORT_REQUEST]: onCreateCustomReportRequest,
  [actions.SWITCH_MODAL_STATE]: onSwitchModalState,
  [actions.SWITCH_CHART_VIEW]: onSwitchChartView,
  [actions.REMOVE_RANGE]: onRemoveRange,
  [actions.RANGE_FORM_ERROR]: onRangeFormError,
  [actions.ADD_RANGE]: onAddRange,
  [actions.CHANGE_RANGE_PROPERTY]: onChangeRangeProperty,
  [actions.LOAD_RISK_SUCCESS]: onLoadRiskSuccess,
  [actions.PUBLISH_RISK_REQUEST]: onPublishRiskRequest,
  [actions.PUBLISH_RISK_SUCCESS]: onPublishRiskSuccess,
  [actions.UPDATE_RISK_REQUEST]: onUpdateRiskRequest,
  [actions.UPDATE_RISK_SUCCESS]: onUpdateRiskSuccess,
  [actions.PUBLISH_RISK_VALIDATION]: onPublishRiskValidation,
  [actions.EDIT_CARD]: onEditCard,
  [actions.EDIT_PIE_COMMENT]: onEditPieComent,
  [actions.CHANGE_VALUE]: onChangeValue,
  [actions.CHANGE_SUMMARY]: onChangeSummary,
  [actions.SET_FILTER]: onSetFilter,
  [actions.LOAD_ERROR]: onLoadError,
  [actions.CLEAR]: onClear,
});
