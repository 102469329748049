import { all, put, takeEvery, fork, call } from 'redux-saga/effects';
import actions from './actions';

import { reduxSagaFirebase, firebase } from '../../redux/firestore';

export function* loadBundlesRequest() {
  yield takeEvery(actions.LOAD_BUNDLES_REQUEST, function*() {
    const colRef = firebase.firestore().collection('bundles');
    const snapshot = yield call(reduxSagaFirebase.firestore.getCollection, colRef.where('bundleGroup', '==', 'DEI'));

    let bundleData;
    snapshot.forEach(el => {
      bundleData = {
        ...bundleData,
        [el.id]: el.data(),
      };
    });

    const bundles = Object.values(bundleData).sort((a, b) => a.orderNum - b.orderNum);
    yield put(actions.loadBundlesSuccess({ bundles }));
  });
}

export function* loadProductsRequest() {
  yield takeEvery(actions.LOAD_PRODUCTS_REQUEST, function*({ payload }) {
    const { id, keywords } = payload;

    let snapshot;

    if (keywords.length) {
      const colRef = firebase.firestore().collection(`bundles/${id}/products`);
      snapshot = yield call(
        reduxSagaFirebase.firestore.getCollection,
        colRef.where('keywords', 'array-contains-any', keywords),
      );
    } else {
      snapshot = yield call(reduxSagaFirebase.firestore.getCollection, `bundles/${id}/products`);
    }

    let products;

    snapshot.forEach(el => {
      products = {
        ...products,
        [el.id]: el.data(),
      };
    });

    products = products ? Object.values(products) : [];

    const data = products.length ? products : [];

    yield put(actions.loadProductsSuccess({ products: data }));
  });
}

export default function* rootSaga() {
  yield all([fork(loadBundlesRequest), fork(loadProductsRequest)]);
}
