const COMPONENT = 'RISK_EDITOR_';

const actions = {
  LOAD_RISK_REQUEST: `${COMPONENT}LOAD_RISK_REQUEST`,
  LOAD_RISK_SUCCESS: `${COMPONENT}LOAD_RISK_SUCCESS`,
  UPDATE_RISK_REQUEST: `${COMPONENT}UPDATE_RISK_REQUEST`,
  UPDATE_RISK_SUCCESS: `${COMPONENT}UPDATE_RISK_SUCCESS`,
  PUBLISH_RISK_VALIDATION: `${COMPONENT}PUBLISH_RISK_VALIDATION`,
  CHANGE_VALUE: `${COMPONENT}CHANGE_VALUE`,
  CHANGE_SUMMARY: `${COMPONENT}_CHANGE_SUMMARY`,
  EDIT_CARD: `${COMPONENT}EDIT_CARD`,
  EDIT_PIE_COMMENT: `${COMPONENT}EDIT_PIE_COMMENT`,
  SET_FILTER: `${COMPONENT}SET_FILTER`,
  PUBLISH_RISK_REQUEST: `${COMPONENT}PUBLISH_RISK_REQUEST`,
  PUBLISH_RISK_SUCCESS: `${COMPONENT}PUBLISH_RISK_SUCCESS`,
  CHANGE_RANGE_PROPERTY: `${COMPONENT}CHANGE_RANGE_PROPERTY`,
  CLEAR: `${COMPONENT}CLEAR`,
  LOAD_ERROR: `${COMPONENT}_LOAD_ERROR`,
  ADD_RANGE: `${COMPONENT}ADD_RANGE`,
  REMOVE_RANGE: `${COMPONENT}REMOVE_RANGE`,
  RANGE_FORM_ERROR: `${COMPONENT}RANGE_FORM_ERROR`,
  SWITCH_MODAL_STATE: `${COMPONENT}SWITCH_MODAL_STATE`,
  SWITCH_CHART_VIEW: `${COMPONENT}SWITCH_CHART_VIEW`,

  CREATE_CUSTOM_REPORT_REQUEST: `${COMPONENT}CREATE_CUSTOM_REPORT_REQUEST`,
  CREATE_CUSTOM_REPORT_SUCCESS: `${COMPONENT}CREATE_CUSTOM_REPORT_SUCCESS`,

  LOAD_UCRT_REQUEST: `${COMPONENT}LOAD_UCRT_REQUEST`,
  LOAD_UCRT_SUCCESS: `${COMPONENT}LOAD_UCRT_SUCCESS`,

  SWITCH_DEF_TOUR_STATE: `${COMPONENT}SWITCH_DEF_TOUR_STATE`,

  switchDefTourState: data => ({
    type: actions.SWITCH_DEF_TOUR_STATE,
    payload: data,
  }),

  loadUCRTRequest: data => ({
    type: actions.LOAD_UCRT_REQUEST,
    payload: data,
  }),

  loadUCRTSuccess: data => ({
    type: actions.LOAD_UCRT_SUCCESS,
    payload: data,
  }),

  createCustomReport: data => ({
    type: actions.CREATE_CUSTOM_REPORT_REQUEST,
    payload: data,
  }),

  createCustomReportSuccess: data => ({
    type: actions.CREATE_CUSTOM_REPORT_SUCCESS,
    payload: data,
  }),

  changeRangeProperty: data => ({
    type: actions.CHANGE_RANGE_PROPERTY,
    payload: data,
  }),

  addRange: data => ({
    type: actions.ADD_RANGE,
    payload: data,
  }),

  switchModalState: data => ({
    type: actions.SWITCH_MODAL_STATE,
    payload: data,
  }),

  switchChartView: data => ({
    type: actions.SWITCH_CHART_VIEW,
    payload: data,
  }),

  rangeFormError: data => ({
    type: actions.RANGE_FORM_ERROR,
    payload: data,
  }),

  removeRange: data => ({
    type: actions.REMOVE_RANGE,
    payload: data,
  }),

  loadRiskRequest: data => ({
    type: actions.LOAD_RISK_REQUEST,
    payload: data,
  }),

  loadRiskSuccess: data => ({
    type: actions.LOAD_RISK_SUCCESS,
    payload: data,
  }),

  editCard: data => ({
    type: actions.EDIT_CARD,
    payload: data,
  }),

  editPieComment: data => ({
    type: actions.EDIT_PIE_COMMENT,
    payload: data,
  }),

  publishRiskRequest: data => ({
    type: actions.PUBLISH_RISK_REQUEST,
    payload: data,
  }),

  publishRiskSuccess: data => ({
    type: actions.PUBLISH_RISK_SUCCESS,
    payload: data,
  }),

  publishRiskValidation: data => ({
    type: actions.PUBLISH_RISK_VALIDATION,
    payload: data,
  }),

  updateRiskRequest: data => ({
    type: actions.UPDATE_RISK_REQUEST,
    payload: data,
  }),

  updateRiskSuccess: data => ({
    type: actions.UPDATE_RISK_SUCCESS,
    payload: data,
  }),

  changeValue: data => ({
    type: actions.CHANGE_VALUE,
    payload: data,
  }),

  changeSummary: data => ({
    type: actions.CHANGE_SUMMARY,
    payload: data,
  }),

  setFilter: data => ({
    type: actions.SET_FILTER,
    payload: data,
  }),

  loadError: data => ({
    type: actions.LOAD_ERROR,
    payload: data,
  }),

  clear: () => ({
    type: actions.CLEAR,
  }),
};
export default actions;
