const actions = {
  USERS_REQUEST: 'USERS_REQUEST',
  LOAD_USERS_SUCCESS: 'LOAD_USERS_SUCCESS',
  LOAD_USERS_ERROR: 'LOAD_USERS_ERROR',

  loadUsers: (data = {}) => ({
    type: actions.USERS_REQUEST,
    payload: data,
  }),
  loadUsersSuccess: data => ({
    type: actions.LOAD_USERS_SUCCESS,
    payload: data,
  }),
  loadUsersError: data => ({
    type: actions.LOAD_USERS_ERROR,
    payload: data,
  }),
};

export default actions;
