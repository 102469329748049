import { all, takeEvery, fork, put, call } from 'redux-saga/effects';
import actions from './actions';
import { callApi } from '../../../helpers/api';
import { firebase, reduxSagaFirebase } from '../../../redux/firestore';

export function* load() {
  yield takeEvery(actions.CATEGORY_LOAD, function*({ payload }) {
    try {
      const { rules, type } = payload;
      const {
        myProviders: myProv,
        topProvidersByAnilineIndex: topProviders,
        mostUsedProvidersInYourIndustry: mostUsed,
        usedInTopCompanies: usedInTop,
      } = rules;

      const decodeRules = rules => {
        const decodedRules = encodeURIComponent(JSON.stringify(rules));
        return decodedRules;
      };

      if (type === 'top') {
        const response = yield all([
          callApi(
            `companies?column=anilineindex&order=desc&type=category&page=1&pageSize=7&query=${decodeRules(
              topProviders.rules,
            )}`,
            'GET',
          ),
        ]);

        const [topProvidersByAnilineIndex] = response;

        if (topProvidersByAnilineIndex.status === 'success') {
          const { data: topProvidersByAnilineIndexArr } = topProvidersByAnilineIndex;

          yield put(
            actions.loadSuccess({
              topProvidersByAnilineIndexArr,
            }),
          );
        } else if (topProvidersByAnilineIndex.status === 'error') {
          const { error } = topProvidersByAnilineIndex.data;
          yield put(actions.loadError(error));
        }
      } else {
        const response = yield all([
          callApi(
            `companies?column=anilineindex&order=desc&type=category&page=1&pageSize=7&query=${decodeRules(
              topProviders.rules,
            )}`,
            'GET',
          ),
          callApi(`companies/related?column=relatedcompanynumber&order=desc&categoryId=${myProv.rules}`, 'GET'),
          callApi(
            `companies?column=relatedcompanynumber&order=DESC&type=category&page=1&pageSize=7&products=true&query=${decodeRules(
              usedInTop.rules,
            )}`,
            'GET',
          ),
          callApi(
            `companies?column=relatedcompanynumber&order=desc&type=category&page=1&pageSize=7&query=${decodeRules(
              mostUsed.rules,
            )}`,
            'GET',
          ),
        ]);

        const [topProvidersByAnilineIndex, myProviders, usedInTopCompanies, mostUsedProvidersInYourIndustry] = response;

        if (
          topProvidersByAnilineIndex.status === 'success' &&
          myProviders.status === 'success' &&
          usedInTopCompanies.status === 'success' &&
          mostUsedProvidersInYourIndustry.status === 'success'
        ) {
          const { data: topProvidersByAnilineIndexArr } = topProvidersByAnilineIndex;
          const { data: myProvidersArr } = myProviders;
          const { data: usedInTopCompaniesArr } = usedInTopCompanies;
          const { data: mostUsedProvidersInYourIndustryArr } = mostUsedProvidersInYourIndustry;

          yield put(
            actions.loadSuccess({
              topProvidersByAnilineIndexArr,
              myProvidersArr,
              usedInTopCompaniesArr,
              mostUsedProvidersInYourIndustryArr,
            }),
          );
        } else if (
          topProvidersByAnilineIndex.status === 'error' ||
          myProviders.status === 'error' ||
          usedInTopCompanies.status === 'error' ||
          mostUsedProvidersInYourIndustry.status === 'error'
        ) {
          const { error } = topProvidersByAnilineIndex.data;
          yield put(actions.loadError(error));
        }
      }
    } catch (error) {
      console.warn(error);
    }
  });
}

export function* sendMyProviderInfo() {
  yield takeEvery(actions.SEND_PROVIDER, function*({ payload }) {
    try {
      const { companyId, category, provider, contactPerson, contactEmail, stars, services } = payload;
      const timestamp = firebase.firestore.Timestamp.fromDate(new Date());

      yield call(reduxSagaFirebase.firestore.addDocument, `companies_user_data/${companyId}/newProvider`, {
        [category]: {
          timestamp,
          provider,
          stars,
          contactPerson,
          contactEmail,
          services,
        },
      });
    } catch (error) {
      console.warn('error', error);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(load), fork(sendMyProviderInfo)]);
}
