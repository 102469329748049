import { all, put, takeEvery, fork } from 'redux-saga/effects';
import actions from './actions';
import RestApi, { callApi } from '../../helpers/api';

const api = new RestApi('risk-tracker/rt-sentiment-group');
const apiRiskDate = new RestApi('risk-tracker/rt-item');

const apiGroup = new RestApi('risk-tracker/ucrt-sentiment-group');
const apiItem = new RestApi('risk-tracker/ucrt-item');

export function* loadRiskRequest() {
  yield takeEvery(actions.LOAD_RISK_REQUEST, function*({ payload }) {
    const { date, companyId, isEarly = false } = payload;

    try {
      const response = yield api.load({
        date,
        companyId,
        isEarlyData: isEarly,
      });

      const response1 = yield apiRiskDate.load({
        date,
        companyId,
        isEarlyData: isEarly,
      });

      if (response.status === 'success' && response1.status === 'success') {
        const { executiveSummary, isPublished, updated, created } = response1.data;

        const dataValues = Object.values(response.data);
        const dataIds = Object.keys(response.data);

        const data = dataValues.map((el, i) => {
          return {
            ...el,
            cards: el.cards.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1)),
            id: dataIds[i],
          };
        });

        yield put(
          actions.loadRiskSuccess({
            data,
            executiveSummary,
            isPublished,
            updated,
            created,
            isPrivate: false,
          }),
        );
      } else {
        console.log('loadRiskRequest response', response.error);
        console.log('loadRiskRequest response1', response1.error);
      }
    } catch (e) {
      console.log('loadRiskRequest', e);
    }
  });
}

export function* loadUCRTRequest() {
  yield takeEvery(actions.LOAD_UCRT_REQUEST, function*({ payload }) {
    const { reportId } = payload;

    try {
      const response = yield apiGroup.load({
        reportId,
      });

      const response1 = yield apiItem.load({
        reportId,
      });

      if (response.status === 'success' && response1.status === 'success') {
        const { executiveSummary, isPublished, updated, created, isPrivate, reportName, reportDate } = response1.data;

        const dataValues = Object.values(response.data);
        const dataIds = Object.keys(response.data);

        const data = dataValues.map((el, i) => {
          return {
            ...el,
            cards: el.cards.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1)),
            id: dataIds[i],
          };
        });

        yield put(
          actions.loadRiskSuccess({
            data,
            executiveSummary,
            isPublished,
            updated,
            created,
            isPrivate,
            reportName,
            reportDate,
          }),
        );
      } else {
        console.log('loadRiskRequest response', response.error);
        console.log('loadRiskRequest response1', response1.error);
      }
    } catch (e) {
      console.log('loadRiskRequest', e);
    }
  });
}

export function* createCustomReportRequest() {
  yield takeEvery(actions.CREATE_CUSTOM_REPORT_REQUEST, function*({ payload }) {
    const { companyMemberId, reportDate, isPrivate, data, isEarly } = payload;

    const response = yield callApi('risk-tracker/create-ucrt-item', 'POST', {
      companyMemberId,
      reportDate,
      isPrivate,
      isEarlyData: isEarly || false,
    });

    if (response.status === 'success') {
      const { reportId } = response.data;

      // eslint-disable-next-line no-restricted-syntax
      for (const item of data) {
        console.log('createCustomReportRequest ====>>>', item);
        yield callApi(
          `risk-tracker/create-ucrt-sentiment-group-item?itemId=${item.id}&reportId=${reportId}`,
          'POST',
          item,
        );
      }

      yield put(actions.createCustomReportSuccess({ reportId }));
    }
  });
}

export function* updateRiskRequest() {
  yield takeEvery(actions.UPDATE_RISK_REQUEST, function*({ payload }) {
    const { data, executiveSummary, reportName, isPrivate, updated, reportId } = payload;

    // eslint-disable-next-line no-restricted-syntax
    for (const item of data) {
      yield callApi(
        `risk-tracker/update-ucrt-sentiment-group-item?itemId=${item.id}&reportId=${reportId}`,
        'PATCH',
        item,
      );
    }

    yield callApi(`risk-tracker/update-ucrt-item?reportId=${reportId}`, 'PATCH', {
      executiveSummary,
      reportName,
      isPrivate,
    });

    yield put(actions.updateRiskSuccess({ updated }));
  });
}

export function* publishRiskRequest() {
  yield takeEvery(actions.PUBLISH_RISK_REQUEST, function*({ payload }) {
    const { data, executiveSummary, reportName, reportId, isEarly = null } = payload;

    const validationFaild = yield put(function() {
      let dataValidation = false;

      if (!reportName) {
        console.log('reportName');
        dataValidation = true;
        return dataValidation;
      }

      if (!executiveSummary) {
        console.log('executiveSummary');
        dataValidation = true;
        return dataValidation;
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const item of data) {
        if (!isEarly) {
          if (!item.historicalOverview) {
            console.log('historicalOverview');
            dataValidation = true;
            break;
          }
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const card of item.cards) {
          const empty = Object.values(card).some(el => el === '');
          if (empty) {
            console.log('cards');
            dataValidation = true;
            break;
          }
        }

        if (!isEarly) {
          // eslint-disable-next-line no-restricted-syntax
          for (const pie of item.pies) {
            if (pie.negative.comments === '' || pie.positive.comments === '' || pie.neutral.comments === '') {
              console.log('pie');
              dataValidation = true;
              break;
            }
          }
        }
      }

      return dataValidation;
    });

    if (!validationFaild) {
      try {
        const response = yield callApi(`risk-tracker/update-ucrt-item-status?reportId=${reportId}`, 'PATCH', {
          isPublished: true,
        });

        if (response.status === 'success') {
          yield put(actions.publishRiskSuccess({ validation: false }));
        }
      } catch (e) {
        console.log('publishRiskRequest', e);
      }
    } else if (validationFaild) {
      yield put(actions.publishRiskValidation({ validation: validationFaild }));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(loadRiskRequest),
    fork(updateRiskRequest),
    fork(publishRiskRequest),
    fork(createCustomReportRequest),
    fork(loadUCRTRequest),
  ]);
}
