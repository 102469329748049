import styled from 'styled-components';
import { palette } from 'styled-theme';
import { generalMediaSettings } from '../../../settings/basicStyle';

const ModalWrapper = ComponentName => styled(ComponentName)`
  .feedback-modal {
    .ant-modal-content {
      padding: 32px;
    }
  }
  .swot-modal-close {
    width: 100%;
    display: flex;
    justify-content: center;
    p {
      font-family: 'Rubik', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 0.5px;
      color: #888888;
      margin-bottom: 0;
      cursor: pointer;
      padding: 0 4px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .swot-items-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.strengths {
      color: ${palette('text', 0)};
    }
    &.opportunities {
      color: #38ad6a;
    }
    &.weaknesses {
      color: #de350a;
    }
    &.threats {
      color: #f78e40;
    }
    p {
      font-family: 'Rubik', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: 0.6px;
    }
  }
  .swot-items-list {
    width: 414px;
    background: #ffffff;
    position: relative;
    &:after {
      content: '';
      display: block;
      height: 40px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 96%);
    }
    ul {
      padding-right: 20px;
      overflow-y: auto;
      height: 200px;
      padding-left: 0;
      padding-bottom: 28px;
      li {
        font-family: 'Rubik', sans-serif;
        list-style: none;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.5px;
        color: ${palette('text', 3)};
        position: relative;
        margin-bottom: 9px;
        padding-left: 14px;
        padding-right: 25px;
        span {
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }

        svg {
          position: absolute;
          right: 0;
          display: block;
          width: 18px;
          height: 19px;
          top: 50%;
          transform: translateY(-50%);
        }

        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          border-radius: 50%;
          display: block;
          background: #333333;
          width: 6px;
          height: 6px;
        }
      }
    }
  }

  .ant-modal-content {
    width: 500px;
    border-radius: 4px;
    box-shadow: 0 10px 24px 0 rgba(29, 42, 68, 0.12);
    background-color: ${palette('secondary', 1)};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 32px 48px;

    .feedback-form {
      width: calc(500px - 64px);
      .btns-wrapper {
        display: flex;
        justify-content: left;
        margin-top: 13px;
        button {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 8.5px 20px;
          cursor: pointer;
          border: 0;
          min-width: 92px;
          height: 34px;
          background: ${palette('text', 0)};
          border-radius: 5px;

          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.5px;
          color: #ffffff;
          &.grey {
            margin-right: 10px;
            color: #888888;
            background: #f2f2f2;
          }
        }
      }
      .form-title {
        display: flex;
        text-align: left;
        color: ${palette('text', 3)};
        font-family: 'Rubik', sans-serif;
        margin-bottom: 16px;
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 20px;
        letter-spacing: 0.607143px;
        svg {
          margin-left: auto;
          cursor: pointer;
        }
      }
      textarea {
        resize: none;
        width: 100%;
        padding: 16px;
        background: #ffffff;
        border: 2px solid ${palette('text', 0)};
        border-radius: 10px;
        height: 70px;
        outline: none;
        &.error-msg {
          border-color: ${palette('error', 0)};
          &:focus {
            border-color: ${palette('error', 0)};
          }
          &:active {
            border-color: ${palette('error', 0)};
          }
        }
      }
      .error-text {
        color: ${palette('error', 0)};
      }
      .feedback-sent {
        text-align: center;
        > svg {
          margin-bottom: 12px;
        }
        .form-title {
          text-align: left;
          color: ${palette('text', 3)};
          font-family: 'Rubik', sans-serif;
          margin-bottom: 16px;
          font-style: normal;
          font-weight: 500;
          font-size: 17px;
          line-height: 20px;
          letter-spacing: 0.607143px;
        }
        p {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          text-align: center;
          letter-spacing: 0.5px;
          color: ${palette('text', 3)};
        }
        .form-title {
          text-align: center;
          margin-bottom: 10px;
        }
        .btns-wrapper {
          justify-content: center;
        }
      }
    }

    .add-company-form {
      width: calc(500px - 64px);
      > div {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        margin-bottom: 25px;
        .select-company {
          margin-top: 8px;
          display: flex;
          justify-content: flex-start;
          min-height: 32px;
          .target-item {
            padding: 4px 8px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            min-width: 110px;
            border-radius: 5px 0 0 5px;
            border: 1px solid #4787fe;
            border-right: none;
            background: rgba(71, 135, 254, 0.1);
            font-family: 'Rubik', sans-serif;
            color: ${palette('text', 3)};
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
          }
          .ant-select {
            margin-top: 0;
            .ant-select-selection {
              border-radius: 0 5px 5px 0;
            }
            .ant-select-selection--single {
              height: 100%;
              .ant-select-selection__rendered {
                height: 100%;
                display: flex;
                align-items: center;
              }
            }
          }
        }
        .ant-select {
          margin-top: 8px;
          &.ant-select-focused,
          &.ant-select-open {
            box-shadow: none;
          }
          .ant-select-selection {
            border-radius: 5px;
            border: 1px solid #4787fe;
            background: #fff;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
        .ant-switch {
          margin-top: 8px;
        }
        > label {
          width: 100%;
          font-family: 'Rubik', sans-serif;
          color: ${palette('text', 3)};
          font-size: 17px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          span {
            display: flex;
            width: 100%;
            color: #888888;
            font-family: 'Rubik', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
            margin-top: 4px;
          }
        }
        .ant-radio-group-solid {
          margin-top: 8px;
          width: 100%;
          label {
            width: 50%;
            border: 1px solid #4787fe;
            text-align: center;
            span {
              font-family: 'Rubik', sans-serif;
              color: palette('text', 3);
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              letter-spacing: 0.5px;
            }
            &:hover {
              span {
                color: #4787fe;
              }
            }

            &.ant-radio-button-wrapper-checked {
              background: #4787fe;
              span {
                color: #fff;
              }
              &:hover {
                span {
                  color: #fff;
                }
              }
            }
          }
        }
      }
      .btns-wrapper {
        display: flex;
        justify-content: left;
        margin-top: 13px;
        button {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 8.5px 20px;
          cursor: pointer;
          border: 0;
          min-width: 92px;
          height: 34px;
          background: ${palette('text', 0)};
          border-radius: 5px;

          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.5px;
          color: #ffffff;
          &.grey {
            margin-right: 10px;
            color: #888888;
            background: #f2f2f2;
          }
          &:disabled {
            opacity: 0.8;
            cursor: not-allowed;
          }
        }
      }
      .form-title {
        position: relative;
        color: ${palette('text', 3)};
        font-family: 'Rubik', sans-serif;
        margin-bottom: 16px;
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 20px;
        letter-spacing: 0.607143px;
        svg {
          position: absolute;
          top: 0;
          right: 0;
          cursor: pointer;
        }
      }
      textarea {
        resize: none;
        width: 100%;
        padding: 16px;
        background: #ffffff;
        border: 2px solid ${palette('text', 0)};
        border-radius: 10px;
        height: 70px;
        outline: none;
        &.error-msg {
          border-color: ${palette('error', 0)};
          &:focus {
            border-color: ${palette('error', 0)};
          }
          &:active {
            border-color: ${palette('error', 0)};
          }
        }
      }
      .error-text {
        color: ${palette('error', 0)};
      }
      .feedback-sent {
        text-align: center;
        > svg {
          margin-bottom: 12px;
        }
        .form-title {
          text-align: left;
          color: ${palette('text', 3)};
          font-family: 'Rubik', sans-serif;
          margin-bottom: 16px;
          font-style: normal;
          font-weight: 500;
          font-size: 17px;
          line-height: 20px;
          letter-spacing: 0.607143px;
        }
        p {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          text-align: center;
          letter-spacing: 0.5px;
          color: ${palette('text', 3)};
        }
        .form-title {
          text-align: center;
          margin-bottom: 10px;
        }
        .btns-wrapper {
          justify-content: center;
        }
      }
    }

    & > button {
      display: none;
    }

    .ant-modal-header {
      background: unset;
      border: none;
      padding: 0;
      margin: 0;
      margin-bottom: 16px;

      & > div {
        font-family: 'Rubik', sans-serif;
        font-size: 17px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.61px;
        text-align: center;
        color: ${palette('queryBuilder', 3)};
      }
    }
    .ant-modal-body {
      padding: 0;

      & > div:last-of-type {
        margin-bottom: 0;
      }
      & > p {
        font-family: 'Rubik', sans-serif;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        text-align: center;
        color: ${palette('queryBuilder', 3)};
        margin-bottom: 16px;
        & > .text-accent {
          font-size: 14px;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.5px;
          text-align: center;
          color: ${palette('queryBuilder', 3)};
          font-weight: 500;
          color: ${palette('backgrounds', 4)};
        }
      }
    }
    .ant-modal-footer {
      border: none;
      padding: 0;
      margin-top: 16px;

      & > div > button.ant-btn {
        min-width: 71px;
        height: 30px;
        border-radius: 3px;
        background-color: ${palette('error', 3)};
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        text-align: center;
        color: ${palette('error', 0)};
        border: none;
      }

      & > div > button.ant-btn-primary {
        min-width: 71px;
        height: 30px;
        border-radius: 3px;
        background-color: blue;
        font-family: 'Rubik', sans-serif;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        color: ${palette('secondary', 1)};
        border: none;
      }
    }
  }

  &.sign-up-mode {
    max-width: 1050px;
    width: max-content !important;
    .ant-modal-content {
      width: 100%;
    }

    .cards-wrapper {
      display: flex;
      justify-content: space-around;
    }
    ${generalMediaSettings.lessThan('xl')`
      max-width: calc(100vw - 16px);
   `}
    ${generalMediaSettings.lessThan('md')`
      .ant-modal-content {
         padding: 32px 16px;
      }
      .cards-wrapper {
        display: flex;
        justify-content: space-around;
        align-items: stretch;
        flex-wrap: wrap;
        > div {
          &:nth-child(2) {
            margin-top: 16px;
          }
        }
      }
   `}
  }
`;

export default ModalWrapper;
