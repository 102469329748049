import {analytics} from "../redux/firestore";

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */

function checkData(response, url, options) {
  if (response.error) {
    analytics.logEvent('exception', {
      description: `Server Error: ${response.error}`,
      fatal: true,
    });
  }

  if (options.method === 'GET' && url.includes('query')) {
    analytics.logEvent('view_search_results', {
      search_term: url,
    });
  }

  return response;
}

function processError(err) {
  const error = new Error(err.message);
  error.code = err.code;
  error.response = err.response;
  throw error;
}

export default function request(url, options) {
  options.mode = 'cors';

  if (options.method === 'GET' && url.includes('query')) {
    analytics.logEvent('search', {
      search_term: url,
    });
  }

  return fetch(url, options)
    .then(response => response.json())
    .then(response => checkData(response, url, options))
    .catch(processError);
}
