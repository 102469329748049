import { all, call, fork, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import { reduxSagaFirebase } from '../../redux/firestore';

export function* sendMyProfileInfo() {
  yield takeEvery(actions.SEND_PROFILE_INFO, function*({ payload }) {
    try {
      const { disclaimer, companyId } = payload;

      yield call(
        reduxSagaFirebase.firestore.setDocument,
        `companies_user_data/${companyId}`,
        {
          disclaimer,
        },
        { merge: true },
      );
    } catch (error) {
      console.error('error', error);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(sendMyProfileInfo)]);
}
