import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { createFirestoreInstance } from 'redux-firestore';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import createRootReducer from './reducers';
import rootSaga from './sagas';
import { rrfConfig } from '../settings';
import { firebase } from './firestore';

const sagaMiddleware = createSagaMiddleware();
const history = createBrowserHistory();

export default function configureStore(preloadedState) {
  return createStore(
    createRootReducer(history),
    preloadedState,
    compose(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        thunk,
        sagaMiddleware,
      ),
    ),
  );
}

const store = configureStore();

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

sagaMiddleware.run(rootSaga);
export { store, history, rrfProps };
