import { all, takeEvery, put, fork } from 'redux-saga/effects';
import { callApi } from '../../helpers/api';
import actions from './actions';

export function* signupRequest() {
  yield takeEvery(actions.SIGNUP_REQUEST, function*({ payload }) {

    try {
      const result = yield callApi('users/sign-up', 'POST', { ...payload, role: 'provider' });

      if (result.status === 'success') {
        yield put(actions.signupSuccess({ signType: result.data }));
      } else {
        const { error } = result;

        console.log('error message', error);

        yield put(actions.signupError({ error }));
      }
    } catch (e) {
      yield put(actions.signupError({ error: e }));
    }
  });
}

export function* autoSuggestions() {
  yield takeEvery(actions.AUTO_SUGGESTIONS, function*({ payload }) {
    try {
      const { filter } = payload;

      const response = yield callApi(`companies/companies-suggestion?input=${filter}`, 'GET');

      if (response.status === 'success') {
        const { output } = response;

        const suggestions = output.map(el => {
          return { value: el.companyName, text: el.companyName, key: el.companyId, type: el.companyType };
        });

        yield put(actions.autoSuggestionsSuccess({ suggestions }));
      }
    } catch (error) {
      console.warn(error);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(signupRequest), fork(autoSuggestions)]);
}
