import { all, takeEvery, put, fork } from 'redux-saga/effects';
import { callApi } from '../../helpers/api';
import resetPasswordActions from './actions';

export function* resetPasswordRequest() {
  yield takeEvery('RESET_PASSWORD_REQUEST', function*({ payload }) {
    const result = yield callApi('users/reset-password', 'POST', payload);

    if (result.status === 'success') {
      yield put(resetPasswordActions.resetPasswordSuccess());
      yield put(resetPasswordActions.clearState());
    } else if (result.status === 'error') {
      yield put(resetPasswordActions.resetPasswordError(result));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(resetPasswordRequest)]);
}
