import styled from 'styled-components';
import { palette } from 'styled-theme';
import { transition, borderRadius, boxShadow } from '../../settings/style-util';
import WithDirection from '../../settings/withDirection';
import { mediaSettings, generalMediaSettings } from '../../settings/basicStyle';

const TopbarWrapper = styled.div`
    &.dark-bg {
        background: ${palette('backgrounds', 2)};
    }

    &.blue-bg {
        background: ${palette('backgrounds', 4)};
    }

    position: fixed;
    height: 70px;
    width: 100%;
    z-index: 99;

    .fixed-btn {
        display: none;
        bottom: 20px;
        right: 20px;
        position: fixed;
        background: #4787FE;
        align-items: center;
        justify-content: center;
        box-shadow: 0 5px 20px rgba(29, 42, 68, 0.15);
        border-radius: 50%;
        width: 50px;
        height: 50px;
        cursor: pointer;
        transition: 0.4s all ease;

        &:hover {
            box-shadow: 0 5px 20px rgba(29, 42, 68, 0.4);
        }
    }

    .isomorphicTopbar {
        display: flex;
        justify-content: space-between;
        max-width: 1220px;
        width: 100%;
        left: 50%;
        padding: 0;
        transform: translateX(-50%);
        z-index: 1000;
        background: transparent;
        ${transition()};

        .isoLeft {
            display: flex;
            align-items: center;

            @media only screen and (max-width: 767px) {
                margin: ${props => (props['data-rtl'] === 'rtl' ? '0 0 0 20px' : '0 20px 0 0')};
            }

            .isoLogoWrapper {
                font-family: 'Montserrat', sans-serif;
                font-size: 25px;
                font-weight: 600;
                letter-spacing: 0.8px;

                a.logo-text {
                    color: ${palette('secondary', 1)};
                }
            }
        }

        .isoCenter {
            display: flex;
            align-items: center;

            .isoBtnWrapper {
                display: flex;
                align-items: center;
                font-family: 'Rubik', sans-serif;

                a {
                    position: relative;
                    border-radius: 3px;
                    line-height: normal;
                    display: flex;
                    padding: 5px 8px;
                    font-size: 14px;
                    font-family: 'Rubik', sans-serif;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    letter-spacing: 0.61px;
                    margin: 0 5px;
                    color: ${palette('secondary', 1)};

                    svg {
                        path {
                            fill: ${palette('secondary', 1)};
                        }
                    }

                    .ai-badge {
                        position: absolute;
                        right: 0;
                        top: 0;
                        transform: translate(47%, -47%);
                        padding: 3px;
                        border-radius: 50%;
                        background: rgb(244, 231, 0);
                        font-size: 10px;
                        font-family: 'Rubik', sans-serif;
                        font-weight: 700;
                        font-stretch: normal;
                        font-style: normal;
                        letter-spacing: 0.61px;
                        color: ${palette('text', 3)};
                    }

                    .info-message {
                        position: absolute;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        height: 22px;
                        width: 22px;
                        font-size: 11px;
                        margin: 0 5px;
                        top: -11px;
                        right: -11px;
                        color: ${palette('secondary', 1)};
                        border: 1px ${palette('secondary', 1)} solid;
                        background: ${palette('error', 0)};
                    }

                    &:hover {
                        background: ${palette('hover', 0)};
                    }

                    &.bg-light {
                        &:hover {
                            background: ${palette('hover', 1)};
                        }

                        &.active {
                            background: ${palette('secondary', 1)};
                            color: ${palette('text', 0)};
                            font-weight: 500;
                        }
                    }

                    &.active {
                        background: ${palette('secondary', 1)};
                        color: ${palette('text', 0)};
                        font-weight: 500;

                        svg {
                            path {
                                fill: ${palette('text', 0)};
                            }
                        }
                    }
                }
            }
        }

        .isoRight {
            display: flex;
            align-items: center;
            margin-bottom: 0;
            cursor: default;

            .user-info-box {
                .company-credits {
                    font-family: 'Rubik', sans-serif;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    letter-spacing: 0.5px;
                    color: #ffffff;
                    opacity: 0.6;
                    text-align: right;
                    margin-right: 8px;
                }
            }

            .early-switcher {
                > p {
                    cursor: pointer;
                    margin-bottom: 0;
                    font-family: 'Rubik', sans-serif;
                    color: ${palette('text', 3)};
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    margin-right: 12px;

                    span {
                        font-family: 'Rubik', sans-serif;
                        position: relative;
                        top: 1px;
                        color: ${palette('text', 3)};
                        font-style: normal;
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 17px;
                        margin-left: 5px;
                    }
                }

                button {
                    border-radius: 25px;
                }

                display: flex;
                align-items: center;
                padding: 8px 12px;
                border-radius: 20px;
                background: ${palette('backgrounds', 9)};
            }

            .user-name {
                font-size: 14px;
                color: ${palette('text', 6)};
                float: left;
                margin-right: 8px;
                font-weight: 500;
                font-family: 'Rubik', sans-serif;

                :first-letter {
                    text-transform: uppercase
                }
            }

            .icon {
                color: ${palette('secondary', 1)};
                font-size: 24px;
                line-height: 24px;
            }

            li {
                margin-left: ${props => (props['data-rtl'] === 'rtl' ? '24px' : '0')};
                margin-right: ${props => (props['data-rtl'] === 'rtl' ? '0' : '12px')};
                cursor: pointer;
                line-height: normal;
                position: relative;
                display: inline-block;

                @media only screen and (max-width: 360px) {
                    margin-left: ${props => (props['data-rtl'] === 'rtl' ? '25px' : '0')};
                    margin-right: ${props => (props['data-rtl'] === 'rtl' ? '0' : '25px')};
                }

                &:last-child {
                    margin: 0;
                }

                .benchmark {
                    position: absolute;
                    top: -13px;
                    right: 50px;

                    &.active::before {
                        content: '';
                        position: absolute;
                        left: 7px;
                        top: 25px;
                        width: 5px;
                        height: 5px;
                        background: ${palette('secondary', 1)};
                        border-radius: 50%;
                    }
                }

                i {
                    font-size: 24px;
                    color: ${palette('text', 0)};
                    line-height: 1;
                }

                .isoIconWrapper {
                    position: relative;
                    line-height: normal;

                    span {
                        font-size: 12px;
                        color: ${palette('secondary', 1)};
                        background-color: ${palette('secondary', 1)};
                        width: 20px;
                        height: 20px;
                        display: -webkit-inline-flex;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        line-height: 20px;
                        position: absolute;
                        top: -8px;
                        left: ${props => (props['data-rtl'] === 'rtl' ? 'inherit' : '10px')};
                        right: ${props => (props['data-rtl'] === 'rtl' ? '10px' : 'inherit')};
                        ${borderRadius('50%')};
                    }
                }

                &.isoMail {
                    .isoIconWrapper {
                        span {
                            background-color: ${palette('color', 0)};
                        }
                    }
                }

                &.isoNotify {
                    .isoIconWrapper {
                        span {
                            background-color: ${palette('primary', 2)};
                        }
                    }
                }

                &.isoMsg {
                    .isoIconWrapper {
                        span {
                            background-color: ${palette('color', 1)};
                        }
                    }
                }

                &.isoCart {
                    .isoIconWrapper {
                        span {
                            background-color: ${palette('color', 2)};
                        }
                    }
                }

                &.isoUser {
                    display: flex;
                    align-items: center;

                    .isoImgWrapper {
                        width: 40px;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                        background-color: transparent;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            ${borderRadius('50%')};
                            
                            &.starter {
                                border: 3px solid rgba(204, 222, 255, 1);
                            }

                            &.basic {
                                border: 3px solid rgba(204, 222, 255, 1);
                            }

                            &.pro {
                                border: 3px solid rgba(255, 221, 0, 1);
                            }

                            &.enterprise {
                                border: 3px solid rgba(56, 173, 106, 1);
                            }
                        }

                        .userActivity {
                            padding: 3px;
                            display: block;
                            background-color: ${palette('color', 3)};
                            position: absolute;
                            font-size: 9px;
                            color: #FFFFFF;
                            bottom: -4px;
                            right: -6px;
                            border: 1px solid #ffffff;
                            ${borderRadius('50%')};
                        }
                    }
                }
            }
        }

        &.webex {
            .back-btn {
                position: relative;
                width: 54px;
                height: 47px;
                border-radius: 7px;
                background: rgba(255, 255, 255, .2);
                mix-blend-mode: normal;
                cursor: pointer;

                span {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    font-size: 12px;
                    color: white;
                    transform: translate(-60%, -50%) rotate(90deg);
                }
            }
        }
    }

    .isoUserDropdown {
        .ant-popover-inner {
            .ant-popover-inner-content {
                .isoUserDropdownContent {
                    padding: 7px 0;
                    display: flex;
                    flex-direction: column;
                    position: absolute;
                    top: 0;
                    right: 0;
                    background-color: ${palette('secondary', 1)};
                    width: 220px;
                    min-width: 160px;
                    flex-shrink: 0;
                    ${borderRadius('5px')};
                    ${boxShadow('0 2px 10px rgba(0,0,0,0.2)')};
                    ${transition()};

                    .isoDropdownLink {
                        font-size: 13px;
                        color: ${palette('text', 1)};
                        line-height: 1.1;
                        padding: 7px 15px;
                        background-color: transparent;
                        text-decoration: none;
                        display: flex;
                        justify-content: flex-start;
                        ${transition()};

                        &:hover {
                            background-color: ${palette('secondary', 6)};
                        }
                    }
                }
            }
        }
    }

    .ant-popover {
        .ant-popover-inner {
            .ant-popover-inner-content {
                .isoDropdownContent {
                    display: flex;
                    flex-direction: column;
                    position: absolute;
                    top: 0;
                    right: 0;
                    background-color: ${palette('backgrounds', 9)}
                    width: 360px;
                    min-width: 160px;
                    flex-shrink: 0;
                    ${borderRadius('5px')};
                    ${boxShadow('0 2px 10px rgba(0,0,0,0.2)')};
                    ${transition()};

                    @media only screen and (max-width: 767px) {
                        width: 310px;
                    }

                    .isoDropdownHeader {
                        border-bottom: 1px solid #f1f1f1;
                        margin-bottom: 0;
                        padding: 15px 30px;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        h3 {
                            font-size: 14px;
                            font-weight: 500;
                            color: ${palette('text', 0)};
                            text-align: center;
                            text-transform: uppercase;
                            margin: 0;
                        }
                    }

                    .isoDropdownBody {
                        width: 100%;
                        height: 300px;
                        overflow-y: auto;
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 10px;
                        background-color: ${palette('grayscale', 6)};

                        .isoDropdownListItem {
                            padding: 15px 30px;
                            flex-shrink: 0;
                            display: flex;
                            flex-direction: column;
                            text-decoration: none;
                            width: 100%;
                            ${transition()};

                            &:hover {
                                background-color: ${palette('grayscale', 3)};
                            }

                            .isoListHead {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                margin-bottom: 5px;
                            }

                            h5 {
                                font-size: 13px;
                                font-weight: 500;
                                color: ${palette('text', 0)};
                                margin-top: 0;
                            }

                            p {
                                font-size: 12px;
                                font-weight: 400;
                                color: ${palette('text', 2)};
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                            }

                            .isoDate {
                                font-size: 11px;
                                color: ${palette('grayscale', 1)};
                                flex-shrink: 0;
                            }
                        }
                    }

                    .isoViewAllBtn {
                        font-size: 13px;
                        font-weight: 500;
                        color: ${palette('text', 2)};
                        padding: 10px 15px 20px;
                        display: flex;
                        text-decoration: none;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        ${transition()};

                        &:hover {
                            color: ${palette('primary', 0)};
                        }
                    }

                    .isoDropdownFooterLinks {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 10px 30px 20px;

                        a {
                            font-size: 13px;
                            font-weight: 500;
                            color: ${palette('text', 0)};
                            text-decoration: none;
                            padding: 10px 20px;
                            line-height: 1;
                            border: 1px solid ${palette('border', 1)};
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            ${transition()};

                            &:hover {
                                background-color: ${palette('primary', 0)};
                                border-color: ${palette('primary', 0)};
                                color: ${palette('text', 6)};
                            }
                        }

                        h3 {
                            font-size: 14px;
                            font-weight: 500;
                            color: ${palette('text', 0)};
                            line-height: 1.3;
                        }
                    }

                    &.withImg {
                        .isoDropdownListItem {
                            display: flex;
                            flex-direction: row;

                            .isoImgWrapper {
                                width: 35px;
                                height: 35px;
                                overflow: hidden;
                                margin-right: 15px;
                                display: -webkit-inline-flex;
                                display: inline-flex;
                                align-items: center;
                                justify-content: center;
                                flex-shrink: 0;
                                background-color: transparent;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }

                            .isoListContent {
                                width: 100%;
                                display: flex;
                                flex-direction: column;

                                .isoListHead {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    margin-bottom: 10px;
                                }

                                h5 {
                                    margin-bottom: 0;
                                    padding-right: 15px;
                                }

                                .isoDate {
                                    font-size: 11px;
                                    color: ${palette('grayscale', 1)};
                                    flex-shrink: 0;
                                }

                                p {
                                    white-space: normal;
                                    line-height: 1.5;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.topbarMail {
            .ant-popover-inner {
                .ant-popover-inner-content {
                    .isoDropdownContent {
                        @media only screen and (max-width: 519px) {
                            right: -170px;
                        }
                    }
                }
            }
        }

        &.topbarMessage {
            .ant-popover-inner {
                .ant-popover-inner-content {
                    .isoDropdownContent {
                        @media only screen and (max-width: 500px) {
                            right: -69px;
                        }
                    }
                }
            }
        }

        &.topbarNotification {
            .ant-popover-inner {
                .ant-popover-inner-content {
                    .isoDropdownContent {
                        @media only screen and (max-width: 500px) {
                            right: -120px;
                        }
                    }
                }
            }
        }

        &.topbarAddtoCart {
            .ant-popover-inner {
                .ant-popover-inner-content {
                    .isoDropdownContent {
                        @media only screen and (max-width: 465px) {
                            right: -55px;
                        }

                        .isoDropdownHeader {
                            margin-bottom: 0;
                        }

                        .isoDropdownBody {
                            background-color: ${palette('grayscale', 6)};
                        }
                    }
                }
            }
        }
    }

    .mobile {
        display: none;
        align-items: center;

        .hide-user {
            visibility: hidden;
            opacity: 0;
        }

        .isoLogoWrapper {
            position: absolute;
            left: 50%;
            top: 50%;
            margin-top: -18px;
            margin-left: -57px;
            display: flex;
            line-height: normal;
        }

        .user-box {
            margin-top: 16px;
            padding-top: 32px;
        }

        .userAvatar {
            width: 35px;
            min-width: 35px;
            height: 35px;
            overflow: hidden;
            border-radius: 50%;
            display: flex;
            align-items: center;
            background: ${palette('backgrounds', 0)};

            img {
                max-width: 100%;
            }

            &.hide-el {
                display: none;
            }
        }

        .nav-icon {
            width: 22px;
            height: 18px;
            position: relative;
            transform: rotate(0deg);
            transition: .5s ease-in-out;
            cursor: pointer;
            outline: 0;
        }

        .nav-icon span {
            display: block;
            position: absolute;
            height: 2px;
            width: 100%;
            background: ${palette('text', 6)}
            border-radius: 9px;
            opacity: 1;
            left: 0;
            transform: rotate(0deg);
            transition: .25s ease-in-out;
        }

        .nav-icon span:nth-child(1) {
            top: 0px;
            transform-origin: left center;
        }

        .nav-icon span:nth-child(2) {
            top: 8px;
            transform-origin: left center;
        }

        .nav-icon span:nth-child(3) {
            top: 16px;
            transform-origin: left center;
        }

        .nav-icon.open span:nth-child(1) {
            transform: rotate(45deg);
            top: 1px;
            left: 0;
        }

        .nav-icon.open span:nth-child(2) {
            width: 0%;
            opacity: 0;
        }

        .nav-icon.open span:nth-child(3) {
            transform: rotate(-45deg);
            top: 17px;
            left: 0;
        }

        .mob-back-btn {
            width: 35px;
            height: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: rgba(255, 255, 255, .3);
            mix-blend-mode: normal;
            border-radius: 7px;
        }

        .mob-menu {
            position: absolute;
            left: 0;
            top: 120px;
            padding: 16px 16px 124px 16px;
            display: flex;
            flex-direction: column;
            width: 100%;
            overflow-y: scroll;
            height: calc(100vh - 70px);
            background: ${palette('backgrounds', 28)};
            visibility: hidden;
            opacity: 0;
            transition: all ease .2s;

            &.open {
                top: 70px;
                visibility: visible;
                opacity: 1;
            }

            .nav-it {
                width: 100%;
                padding: 20px;
                background: #F5F9FF;
                border-radius: 8px;
                font-family: 'Rubik', sans-serif;
                color: ${palette('text', 3)};
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 21px;
                text-align: center;
                letter-spacing: 0.642857px;
                margin-bottom: 16px;

                .icon {
                    position: relative;
                    top: 2px;
                    margin-right: 7px;
                }

                .userAvatar {
                    margin-right: 15px;
                }

                &.logout {
                    background: ${palette('error', 3)};

                    .icon {
                        color: ${palette('error', 4)};
                    }
                }

                &.user-info {
                    display: flex;
                    padding-left: 15px;
                    align-items: center;
                }
            }

            &.top-bar {
                .nav-it {
                    text-align: left;
                    padding-left: 30%;

                    .icon {
                        position: relative;
                        top: 2px;
                        margin-right: 7px;
                    }

                    .userAvatar {
                        margin-right: 15px;
                    }

                    &.logout {
                        background: ${palette('error', 3)};

                        .icon {
                            color: ${palette('error', 4)};
                        }
                    }

                    &.user-info {
                        display: flex;
                        padding-left: 15px;
                        align-items: center;
                    }
                }
            }
        }
    }

    &.dark-bg {
        .mob-menu {
            .user-box {
                border-top: 1px solid ${palette('backgrounds', 2)};
            }

            .nav-it {
                .icon {
                    color: ${palette('backgrounds', 2)};
                }

                &.user-info {
                    background: ${palette('backgrounds', 2)};
                    color: ${palette('text', 6)};
                }

                &.active {
                    border: 2px solid ${palette('text', 3)};
                    font-weight: 600;
                    background: ${palette('backgrounds', 29)};
                        // color: ${palette('text', 6)};
                }
            }
        }
    }

    &.blue-bg {
        .mob-menu {
            .user-box {
                border-top: 1px solid ${palette('backgrounds', 4)};
            }

            .nav-it {
                .icon {
                    color: ${palette('backgrounds', 4)};
                }

                &.user-info {
                    background: ${palette('backgrounds', 4)};
                    color: ${palette('text', 6)};
                }

                &.active {
                    border: 2px solid ${palette('text', 3)};
                    font-weight: 600;
                    background: ${palette('backgrounds', 29)};
                        // background: ${palette('backgrounds', 4)};
                        // color: ${palette('text', 6)};
                }
            }
        }
    }

    ${mediaSettings.lessThan('tablet')`
      .isoRight {
        .benchmark {
            display: none!important;
        }
      }
  `}

    ${mediaSettings.lessThan('desktop')`
      .isomorphicTopbar {
        padding-left: 20px;
        padding-right: 20px;
      }
  `}

    ${mediaSettings.lessThan('tablet')`
      .isoCenter {
        // display: none!important;
      }
      .isoRight {
        .benchmark {
            display: none!important;
        }
      }
  `}

    ${generalMediaSettings.lessThan('lg')`
    .isomorphicTopbar {
      padding-left: 15px;
      padding-right: 15px;
    }
    
    .mobile {
      display: flex;
    }
  
    .desktop {
      display: none;
    }
  `}

`;

export default WithDirection(TopbarWrapper);
