import styled from 'styled-components';
import { palette } from 'styled-theme';

const AppHolder = styled.div`
  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 16px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .trigger:hover {
    color: ${palette('primary', 0)};
  }

  .ant-layout-sider-collapsed .anticon {
    font-size: 16px;
  }

  .ant-layout-sider-collapsed .nav-text {
    display: none;
  }

  .ant-layout {
    background: ${palette('secondary', 1)};

    &.isoContentMainLayout {
      overflow: auto;
      overflow-x: hidden;
      @media only screen and (min-width: 768px) and (max-width: 1220px) {
        width: calc(100% - 80px);
        flex-shrink: 0;
      }

      @media only screen and (max-width: 767px) {
        width: 100%;
        flex-shrink: 0;
      }
    }
  }

  .isoLayoutContent {
    width: 100%;
    padding: 35px;
    background-color: ${palette('backgrounds', 9)}
    border: 1px solid ${palette('border', 0)};
    height: 100%;
  }

  .isomorphicLayout {
    width: calc(100% - 240px);
    flex-shrink: 0;
    overflow-x: hidden !important;

    @media only screen and (max-width: 767px) {
      width: 100%;
    }

    @media only screen and (min-width: 768px) and (max-width: 1220px) {
      width: calc(100% - 80px);
    }
  }

  .ant-layout-footer {
    font-size: 13px;
    
    @media (max-width: 767px) {
      padding: 10px 20px;
    }
  }

  button {
    border-radius: 0;
  }

  .small-screen-disclaimer {
    display: none;
    width: 100%;
    height: 100%;

    .screen-disclaimer-header {
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: ${palette('backgrounds', 4)};
      margin-bottom: 90px;
    }

    .screen-disclaimer-content {
      padding: 0 20px;
      text-align: center;
      font-family: 'Rubik', sans-serif;
      font-size: 17px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.86px;
      color: ${palette('text', 0)};
      img {
        max-width: 100%;
        margin-bottom: 14px;
      }
    }
  }

  @media screen and (max-width: 992px) {
    .small-screen-disclaimer {
      display: block;
      padding-top: 10vh;
    }
    .desktop {
      //display: none;
    }
  }

`;

export default AppHolder;
