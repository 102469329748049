import logo from '../../image/logo.svg';

const theme = {};

theme.palette = {
  primary: [
    '#4482FF', // 0: Default
    '#3A78F5', // 1: Darken 4%
    '#3775F2', // 2: Darken 5%
    'rgba(68, 130, 255, 0.2)', // 3: Fade 20%
    '#4C8AFF', // 4: Lighten 3%
    'rgba(68, 130, 255, 0.75)', // 5: Fade 75%
    '#6AA8FF', // 6: Lighten 15%
    '#63A1FF', // 7: Lighten 12%
    '#3F7DFA', // 8: Darken 2%
    '#3369e7', // 9: Algolia color
    '#5896FF', // 10: Lighten 8%
    '#2b69e6', // 11:
    '#236cfe', // 12: darken 10%
    '#4d88ff', // 13: Lighten 5%
    '#c0d6ff', // 14: LightBlue
    '#54a4ff', // 15: LightBlue
  ],
  secondary: [
    '#1a1e2a', // 0: #dark topbar background
    '#ffffff', // 1: #white topbar text
    '#788195', // 2: LightBlue
    '#E4E6E9', // 3: LightBluish Darken 5%
    '#364d79', // 4:
    '#202739', // 5: DarkBlue Darken 5%
    '#f5f6f8', // 6: LighterBluish
    '#e9ebf1', // 7: DarkBluish
    '#F6F8FB', // 8: LighterBluish Lighten 2%
    '#E9EBEE', // 9: LighterBluish Darken 3%
    '#1a1a1a', // 10: Sidebar submenu select
    '#1a1e2a', // 11: #dark topbar background
  ],
  color: [
    '#FEAC01', // 0: Orange
    '#42299a', // 1: Purple
    '#F75D81', // 2: Pink
    '#7ED321', // 3: LimeGreen
    '#39435f', // 4: BlueShade
    '#FFCA28', // 5: Yellow
    '#F2BD1B', // 6: Yellow Darken 5%
    '#3b5998', // 7: Facebook
    '#344e86', // 8: Facebook Darken 5%
    '#dd4b39', // 9: Google Plus
    '#d73925', // 10: Google Plus Darken 5%
    '#e14615', // 11: Auth0
    '#ca3f13', // 12: Auth0
    '#e0364c', // 13: themeColor--AlizarinCrimson
  ],
  warning: [
    '#ffbf00', // 0: Warning
  ],
  success: [
    '#00b16a', // 0: Success
  ],
  error: [
    '#de350a', // 0: Error
    '#EC3D3A', // 1: Darken 4%
    '#FF5B58', // 2: Lighten 8%
    '#f8eeee', // 3: light red
    '#DF3838', // 4:
  ],
  grayscale: [
    '#888888', // 0: GreyShade
    '#c1c1c1', // 1: GreyDark
    '#a0a0a0', // 2: Grey
    '#f1f1f1', // 3: GreyAlt
    '#F3F3F3', // 4: GreyLight
    '#fafafa', // 5: DarkWhite
    '#F9F9F9', // 6: DarkerWhite
    '#fcfcfc', // 7: #fff Darken 1%
    '#eeeeee', // 8:
    '#fbfbfb', // 9:
    '#f5f5f5', // 10:
    '#f7f8f9', // 11: today-highlight-bg
    '#9d9d9d', // 12:
    '#a7a7a7', // 13:
    '#B2B2B2', // 14:
  ],
  text: [
    '#4787fe', // 0: Heading
    '#595959', // 1: HeadingLight
    '#979797', // 2: Text
    '#121415', // 3: TextDark
    '#6a6c6a', // 4: Heading Lighten 22%
    '#4c96ff', // 5: HeadingLight 80%
    '#ffffff', // 6: White
    '#38ad6a', // 7: Green
    '#9d9d9d', // 8: Grey
    '#888888', // 9: Intensive grey
    '#0e1215', // 10: Grey
    '#02081d', // 11: Dark
    '#102215', // 12: Dark
    '#131415', // 13: dark-grey
    '#2b2e30', // 14: grey
    '#111315', // 15: almost black
    '#939393', // 16: middle grey
    '#e47d31', // 17: orange
    '#000000', // 18: black
    '#212121', // 19
    '#eef2fc', // 20
    '#df3838', // 21
    '#C4C4C4', // 22
    '#E3CC00', // 23
    '#CBDFFF', // 24
    '#DD4B4B', // 25
    '#F78E40', // 26
  ],
  border: [
    '#e9e9e9', // 0: Border
    '#d8d8d8', // 1: BorderDark
    '#ebebeb', // 2: BorderLight
    '#d3d3d3', // 3:
    'rgba(228, 228, 228, 0.65)', // 4:
  ],
  charts: [
    '#4787fe', // 0: Blue
    '#fadb14', // 1: Pink
    '#38ad6a', // 2: Green
    '#f78e40', // 3: Orange
    '#9570fd', // 4: Purp
    '#73CFFF', // 5: LightBlue
  ],
  buttons: [
    '#4787fe', // 0: Blue
    '#f5f9ff', // 1: Grey
    '#f2f2f2', // 2: Grey 2
    '#6AA8FF', // 3: Lighten 15%
    '#bbd6ff', // 4:
    '#ffdd00', // 5: sunflower-yellow
    '#fcf7f7', // 6: delete light red
    '#db433e', // 7: delete red
    '#38AD6A', // 8: Green
    '#F8EEEE', // 9: light red
  ],

  hover: [
    '#3c72d7', // 0: Top buttons
  ],

  backgrounds: [
    '#f5f9ff', // 0: LightBlue
    '#e1ffee', // 1: LightGreen
    '#001529', // 2: Dark
    '#c0d6ff', // 3: Skeleton
    '#4787fe', // 4: Blue
    '#DBDBDB', // 5: LightGrey
    '#38ad6a', // 6: Green
    'rgba(255, 255, 255, 0.9)', // 7:
    '#c9dafc', // 8:
    '#ffffff', // 9
    '#F2F2F2', // 10
    '#47c7fe', // 11
    '#ffcd80', // 12
    '#265cda', // 13
    '#f6f7f8', // 14
    '#dddddd', // 15
    '#cccccc', // 16
    '#f1f3f6', // 17
    '#eef2fc', // 18
    '#F78E40', // 19
    '#EEE31E', // 20
    '#2F5161', // 21
    '#E9F2FF', // 22
    '#FFEDED', // 23
    '#FFFFE1', // 24
    '#E1FFEE', // 25
    '#CBDFFF', // 26
    '#E0EAFA', // 27
    '#FFFFFF', // 28
    '#DFECFF', // 29
  ],

  borders: [
    '#4787fe', // 0: dodger-blue
  ],

  gradients: [
    'rgba(52, 132, 255, 0.3)', // 0: Opacity white
    '#3385ff', // 1: Blue
    'rgba(29, 42, 68, 0.12)', // 2: Dark shadow
    '#9ac3ff', // 3: Light blue
    '#4787fe', // 4: Blue
  ],

  calendar: [
    '#905', // 0:
    '#690', // 1:
    '#a67f59', // 2:
    '#07a', // 3:
    '#dd4a68', // 4:
    '#e90', // 5:
  ],

  shadows: [
    'rgba 29 42 68, 0.12', // 0: Box shadow
  ],

  queryBuilder: [
    '#f5f9ff', // 0 - background (ice-blue)
    '#b1cdff', // 1 - field button background (baby-blue)
    '#4787fe', // 2 - field button background (dodger-blue)
    '#131415', // 3 - text color (dark-grey)
    '#888888', // 4 - clear all button (warm-grey)
  ],

  quotes: [
    '#4787fe', // 0 - blue
    '#38ad6a', // 1 - green
    '#ff9a00', // 2 - orange
    '#de350a', // 3 - red
    '#bdd6ff', // 4 - light blue
    '#d9d9d9', // 5 - gray,
    '#b5cfff', // 6 - medium blue
    '#70a1fe', // 7 - darker blue
    '#eddc40', // 8 - yellow star
  ],
};

theme.fonts = {
  secondary: '"Rubik", sans-serif',
  primary: 'Montserrat, sans-serif',
  pre: 'Consolas, Liberation Mono, Menlo, Courier, monospace',
};

theme.assets = {
  logo,
};

export default theme;
