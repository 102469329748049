import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import { reduxSagaFirebase } from '../../redux/firestore';

export function* forgotPasswordRequest() {
  yield takeEvery('FORGOT_PASSWORD_REQUEST', function* forgotPasswordRequest({ payload }) {
    const { email } = payload;

    try {
      yield call(reduxSagaFirebase.auth.sendPasswordResetEmail, email);
      yield put(actions.forgotPasswordSuccess());
    } catch (e) {
      yield put(actions.forgotPasswordError({ error: e }));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(forgotPasswordRequest)]);
}
