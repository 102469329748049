const COMPONENT = 'PACKS';

const actions = {
  LOAD_REQUEST: `${COMPONENT}_LOAD_REQUEST`,
  LOAD_SUCCESS: `${COMPONENT}_LOAD_SUCCESS`,
  LOAD_ERROR: `${COMPONENT}_LOAD_ERROR`,

  LOAD_ANNIE_REQUEST: `${COMPONENT}_LOAD_ANNIE_REQUEST`,
  LOAD_ANNIE_SUCCESS: `${COMPONENT}_LOAD_ANNIE_SUCCESS`,
  LOAD_ANNIE_ERROR: `${COMPONENT}_LOAD_ANNIE_ERROR`,

  DELETE_PACK_REQUEST: `${COMPONENT}_DELETE_PACK_REQUEST`,
  DELETE_PACK_SUCCESS: `${COMPONENT}_DELETE_PACK_SUCCESS`,
  DELETE_PACK_ERROR: `${COMPONENT}_DELETE_PACK_ERROR`,

  CLEAR: `${COMPONENT}_CLEAR`,

  loadRequest: data => ({
    type: actions.LOAD_REQUEST,
    payload: data,
  }),
  loadSuccess: data => ({
    type: actions.LOAD_SUCCESS,
    payload: data,
  }),
  loadError: data => ({
    type: actions.LOAD_ERROR,
    payload: data,
  }),

  loadAnnieRequest: data => ({
    type: actions.LOAD_ANNIE_REQUEST,
    payload: data,
  }),
  loadAnnieSuccess: data => ({
    type: actions.LOAD_ANNIE_SUCCESS,
    payload: data,
  }),
  loadAnnieError: data => ({
    type: actions.LOAD_ANNIE_ERROR,
    payload: data,
  }),

  deletePackRequest: data => ({
    type: actions.DELETE_PACK_REQUEST,
    payload: data,
  }),
  deletePackSuccess: data => ({
    type: actions.DELETE_PACK_SUCCESS,
    payload: data,
  }),
  deletePackError: data => ({
    type: actions.DELETE_PACK_ERROR,
    payload: data,
  }),

  clear: () => ({
    type: actions.CLEAR,
  }),
};
export default actions;
