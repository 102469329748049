import { useState, useEffect } from 'react';
import { db } from '../redux/firestore';
import { getAuthData } from '../helpers/auth';

const usePlanAndUserRules = () => {
  const [planData, setPlanData] = useState(null);
  const [userRules, setUserRules] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const { claims } = getAuthData();
    const { companyId } = claims;

    if (!companyId) {
      setError(new Error('Company ID not found in localStorage'));
      setLoading(false);
      return;
    }

    const planDocPath = `/companies/${companyId}/license/plan`;

    const unsubscribePlan = db.doc(planDocPath).onSnapshot(
      doc => {
        let data = doc.data();
        if (!data) {
          data = { type: 'basic' };
        }
        setPlanData(data);
        if (data && data.type) {
          const unsubscribeUserRules = db
            .collection('user_rules')
            .doc(`sub_${data.type}`)
            .onSnapshot(
              userDoc => {
                setUserRules(userDoc.data());
                setLoading(false);
              },
              err => {
                setError(err);
                setLoading(false);
              },
            );

          return () => unsubscribeUserRules();
        }
        setLoading(false);
      },
      err => {
        setError(err);
        setLoading(false);
      },
    );

    // Cleanup plan listener when component unmounts
    return () => unsubscribePlan();
  }, []);

  return { planData, userRules, loading, error };
};

export default usePlanAndUserRules;
