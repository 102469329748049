import { all, put, takeEvery, fork } from 'redux-saga/effects';
import actions from './actions';
import RestApi from '../../helpers/api';
import settings from '../../settings';

const api = new RestApi('packs/delete-pack ');

export function* deletePackRequest() {
  yield takeEvery(actions.DELETE_PACK_REQUEST, function*({ payload }) {
    const { userId, packId, companyDocumentId } = payload;

    try {
      const response = yield api.deleteItemCustom(`${settings.apiAiUrl}`, {
        userId,
        packId,
        companyDocumentId,
      });

      if (response.status === 'success') {
        // yield put(
        //   actions.loadAnnieSuccess({
        //     annieLoading: false,
        //   }),
        // );
      } else {
        console.log('loadRiskRequest response', response.error);
      }
    } catch (e) {
      console.log('loadTimelineRequest', e);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(deletePackRequest)]);
}
