const COMPONENT = 'CHANGE_PASS';

const changePasswordActions = {
  CHANGE_PROPERTY: `${COMPONENT}CHANGE_PROPERTY`,
  TOUCH: `${COMPONENT}TOUCH`,
  CLEAR_STATE: `${COMPONENT}CLEAR_STATE`,
  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_ERROR: 'CHANGE_PASSWORD_ERROR',

  changePasswordRequest: data => ({
    type: changePasswordActions.CHANGE_PASSWORD_REQUEST,
    payload: data,
  }),

  changeProperty: data => ({
    type: changePasswordActions.CHANGE_PROPERTY,
    payload: data,
  }),

  touch: data => ({
    type: changePasswordActions.TOUCH,
    payload: data,
  }),

  changePasswordSuccess: data => ({
    type: changePasswordActions.CHANGE_PASSWORD_SUCCESS,
    payload: data,
  }),

  changePasswordError: data => ({
    type: changePasswordActions.CHANGE_PASSWORD_ERROR,
    payload: data,
  }),

  clearState: () => ({
    type: changePasswordActions.CLEAR_STATE,
  }),
};
export default changePasswordActions;
