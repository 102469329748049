const COMPONENT = 'MARKET_RESEARCH';

const actions = {
  LOAD: `${COMPONENT}_LOAD`,
  LOAD_SUCCESS: `${COMPONENT}_LOAD_SUCCESS`,
  LOAD_ERROR: `${COMPONENT}_LOAD_ERROR`,
  SEND_PROFILE_INFO: `${COMPONENT}_SEND_PROFILE_INFO`,

  load: data => ({
    type: actions.LOAD,
    payload: data,
  }),

  loadSuccess: data => ({
    type: actions.LOAD_SUCCESS,
    payload: data,
  }),

  loadError: data => ({
    type: actions.LOAD_ERROR,
    payload: data,
  }),

  sendProfileInfo: data => ({
    type: actions.SEND_PROFILE_INFO,
    payload: data,
  }),
};

export default actions;
