const COMPONENT = 'SIGNUP_PUBLIC';

const signupActions = {
  CHANGE_PROPERTY: `${COMPONENT}CHANGE_PROPERTY`,
  GET_TOKEN_REQUEST: `${COMPONENT}GET_TOKEN_REQUEST`,
  GET_TOKEN_SUCCESS: `${COMPONENT}GET_TOKEN_SUCCESS`,
  GET_TOKEN_ERROR: `${COMPONENT}GET_TOKEN_ERROR`,
  TOUCH: `${COMPONENT}TOUCH`,
  CLEAR_STATE: `${COMPONENT}CLEAR_STATE`,
  SWITCH_VIEW: `${COMPONENT}SWITCH_VIEW`,
  LOAD_SCREEN: `${COMPONENT}LOAD_SCREEN`,
  SIGNUP_REQUEST: `${COMPONENT}SIGNUP_REQUEST`,
  SIGNUP_SUCCESS: `${COMPONENT}SIGNUP_SUCCESS`,
  SIGNIN_START: `${COMPONENT}SIGNIN_START`,
  SIGNUP_ERROR: `${COMPONENT}SIGNUP_ERROR`,
  AUTO_SUGGESTIONS: `${COMPONENT}AUTO_SUGGESTIONS`,
  AUTO_SUGGESTIONS_SUCCESS: `${COMPONENT}AUTO_SUGGESTIONS_SUCCESS`,
  SET_COMPANY_TYPE: `${COMPONENT}SET_COMPANY_TYPE`,

  autoSuggestions: data => ({
    type: signupActions.AUTO_SUGGESTIONS,
    payload: data,
  }),

  autoSuggestionsSuccess: data => ({
    type: signupActions.AUTO_SUGGESTIONS_SUCCESS,
    payload: data,
  }),
  changeProperty: data => ({
    type: signupActions.CHANGE_PROPERTY,
    payload: data,
  }),
  switchView: () => ({
    type: signupActions.SWITCH_VIEW,
  }),
  loadScreen: () => ({
    type: signupActions.LOAD_SCREEN,
  }),
  touch: data => ({
    type: signupActions.TOUCH,
    payload: data,
  }),
  getToken: data => ({
    type: signupActions.GET_TOKEN_REQUEST,
    payload: data,
  }),
  getTokenSuccess: data => ({
    type: signupActions.GET_TOKEN_SUCCESS,
    payload: data,
  }),
  getTokenError: data => ({
    type: signupActions.GET_TOKEN_ERROR,
    payload: data,
  }),
  signup: data => ({
    type: signupActions.SIGNUP_REQUEST,
    payload: data,
  }),
  signupSuccess: data => ({
    type: signupActions.SIGNUP_SUCCESS,
    payload: data,
  }),
  signinStart: data => ({
    type: signupActions.SIGNIN_START,
    payload: data,
  }),
  signupError: data => ({
    type: signupActions.SIGNUP_ERROR,
    payload: data,
  }),

  setCompanyType: data => ({
    type: signupActions.SET_COMPANY_TYPE,
    payload: data,
  }),

  clearState: () => ({
    type: signupActions.CLEAR_STATE,
  }),
};
export default signupActions;
