const COMPONENT = 'BROKERS';

const actions = {
  LOAD: `${COMPONENT}_LOAD`,
  LOAD_SUCCESS: `${COMPONENT}_LOAD_SUCCESS`,
  LOAD_ERROR: `${COMPONENT}_LOAD_ERROR`,
  CLEAR_STATE: `${COMPONENT}_CLEAR_STATE`,

  load: data => ({
    type: actions.LOAD,
    payload: data,
  }),

  loadSuccess: data => ({
    type: actions.LOAD_SUCCESS,
    payload: data,
  }),

  loadError: data => ({
    type: actions.LOAD_ERROR,
    payload: data,
  }),

  clearState: () => ({
    type: actions.CLEAR_STATE,
  }),
};

export default actions;
