import { all, put, takeEvery, fork } from 'redux-saga/effects';
import moment from 'moment';
import actions from './actions';

import RestApi from '../../../helpers/api';

const api = new RestApi('risk-tracker/rt-sentiment-group-item');

export function* loadRiskRequest() {
  yield takeEvery(actions.LOAD_RISK_REQUEST, function*({ payload }) {
    const { sentimentGroupId, date, companyId, scoreId, isEarly = '' } = payload;

    try {
      const response = yield api.load({
        itemId: sentimentGroupId,
        date: moment(date, 'YYYYMM').format('YYYYMM'),
        companyId,
        isEarly,
      });

      if (response.status === 'success') {
        // eslint-disable-next-line prefer-const
        let { cards, pies, risk, diagrams, diagrams_1, diagrams_6, historicalChartView } = response.data;

        cards = cards.find(el => el.id === scoreId);
        pies = pies.find(el => el.id === scoreId);
        yield put(
          actions.loadRiskSuccess({
            card: cards,
            piesData: pies,
            risk,
            diagrams: diagrams[0].lines,
            diagrams_1: (diagrams_1 && diagrams_1[0].lines) || null,
            diagrams_6: (diagrams_6 && diagrams_6[0].lines) || null,
            historicalChartView,
          }),
        );
      } else {
        console.log('response error', response.error);
      }
    } catch (e) {
      console.log('loadRiskRequest', e);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(loadRiskRequest)]);
}
