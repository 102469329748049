import { all, put, takeEvery, fork } from 'redux-saga/effects';
import RestApi, { callApi } from '../../../helpers/api';
import actions from './actions';

const api = new RestApi('products/all');

export function* loadProductsRequest() {
  yield takeEvery(actions.LOAD_PRODUCTS_REQUEST, function*({ payload }) {
    const { column, order, page, pageSize, filter } = payload;

    const params = {
      page,
      pageSize,
      column,
      order,
      filter,
    };

    if (order) {
      params.order = order;
    }

    const result = yield api.load(params);

    if (result.status === 'success') {
      const { data, count } = result;

      yield put(actions.loadProductsSuccess({ data, count, page }));
    } else if (result.status === 'error') {
      yield put(actions.loadProductsError(result));
    }
  });
}

export function* autoSuggestions() {
  yield takeEvery(actions.AUTO_SUGGESTIONS, function*({ payload }) {
    try {
      const reqString = payload.filter;

      const response = yield callApi(`companies/auto-suggestion?input=${reqString}`, 'GET');

      if (response.status === 'success') {
        const { output } = response;
        yield put(actions.autoSuggestionsSuccess({ data: output }));
      }
    } catch (error) {
      console.warn(error);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(loadProductsRequest), fork(autoSuggestions)]);
}
