import actions from './actions';
import { createReducer } from '../../helpers/reducer';

// ------------------------------
// Initial State
// ------------------------------
const initState = {
  bundle: null,
};

// ------------------------------
// Action Handlers
// ------------------------------
function onLoadBundleSuccess(state, { payload }) {
  const { bundle } = payload;

  return {
    ...state,
    bundle,
  };
}

// ------------------------------
// Exported reducer function.
// ------------------------------
export default createReducer(initState, {
  [actions.LOAD_BUNDLE_SUCCESS]: onLoadBundleSuccess,
});
