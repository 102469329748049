import { all, takeEvery, fork } from 'redux-saga/effects';
import firebase from 'firebase';
import actionsAuth from '../../redux/auth/actions';
import actions from './actions';
import RestApi, { callApi } from '../../helpers/api';
import { getAuthData, saveAuthData } from '../../helpers/auth';
import { rollbar } from '../../redux/rollbar';
import { store } from '../../redux/store';
import welcomeActions from '../Welcome/actions';

const api = new RestApi('users/close-early-data-popup');

export function* sendFeedback() {
  yield takeEvery(actions.SEND_FEEDBACK, function*({ payload }) {
    const { companyId, feedbackText, browser, url, email, role } = payload;

    try {
      const response = yield callApi('feedbacks/create-feedback', 'POST', {
        companyId,
        feedbackText,
        browser,
        url,
        email,
        role,
      });

      if (response.status === 'success') {
        console.log('sendFeedback response status: error');
      } else if (response.status === 'error') {
        console.log('sendFeedback response status: error');
      }
    } catch (e) {
      console.log('sendFeedback error', e);
    }
  });
}

export function* setDataMode() {
  yield takeEvery(actions.SET_DATA_MODE, function*() {
    try {
      const response = yield api.createItem({});

      if (response.status === 'success') {
        firebase.auth().onAuthStateChanged(function(user) {
          if (user) {
            user.getIdTokenResult(true).then(function(idTokenResult) {
              const authData = getAuthData() || {};
              authData.claims = idTokenResult.claims;
              authData.idToken = idTokenResult.token;
              saveAuthData(authData);
              const { email, role, user_id } = idTokenResult.claims;

              if (rollbar) {
                rollbar.configure({
                  payload: {
                    person: {
                      id: user_id,
                      username: role,
                      email,
                    },
                  },
                });
              }

              store.dispatch(actionsAuth.loginSuccess(authData));
              store.dispatch(welcomeActions.clearState());
            });
          }
        });
      } else if (response.status === 'error') {
        console.log('setDataMode response status: error');
      }
    } catch (e) {
      console.log('setDataMode error', e);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(setDataMode), fork(sendFeedback)]);
}
