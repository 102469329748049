import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import qs from 'query-string';
import IntlMessages from '../../components/utility/intlMessages';
import authAction from '../../redux/auth/actions';
import settings, { anilineCompanyIds } from '../../settings';

const { logout, slideOut } = authAction;

const TopbarUser = ({ toggleNavig }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const { name = '', companyId, mainCompanyId } = useSelector(({ Auth: { claims } }) => {
    return claims || {};
  });

  const ssoToken = useSelector(({ Auth: { ssoToken } }) => ssoToken || '');
  const isSlideIn = anilineCompanyIds.some(company => company === companyId);
  const isSlideOut = !isSlideIn && anilineCompanyIds.some(company => company === mainCompanyId);

  const onLogout = () => {
    dispatch(logout());
    setVisible(!visible);
  };

  const onSlideIn = () => {
    history.push('/impersonate');
    setVisible(!visible);
  };

  const onSlideOut = () => {
    dispatch(slideOut());
    setVisible(!visible);
  };

  const onSupport = () => {
    const url = `${settings.communityUrl}/category/4/support-feedback?token=${ssoToken}`;

    window.open(url);
  };

  const onCommunity = () => {
    const url = `${settings.communityUrl}?token=${ssoToken}`;
    window.open(url);
  };

  return (
    <div className="user-box">
      <div className="nav-it user-info">
        <div className="userAvatar">
          <img
            className={isSlideOut ? 'impersonate' : ''}
            alt={name}
            src={`https://eu.ui-avatars.com/api/?${qs.stringify({ name })}`}
          />
        </div>
        <span className="user-name">{name}</span>
      </div>
      <div
        onClick={() => {
          toggleNavig();
          onCommunity();
        }}
        className="nav-it"
      >
        <span role="button" tabIndex={0} className="isoDropdownLink">
          <span className="icon icon-community" /> <IntlMessages id="topbar.community" />
        </span>
      </div>
      <div
        onClick={() => {
          toggleNavig();
          onSupport();
        }}
        className="nav-it"
      >
        <span role="button" tabIndex={-1} className="isoDropdownLink">
          <span className="icon icon-help-circle" /> <IntlMessages id="topbar.support" />
        </span>
      </div>
      {isSlideIn && (
        <div
          onClick={() => {
            toggleNavig();
            onSlideIn();
          }}
          className="nav-it"
        >
          <span role="button" tabIndex={-3} className="isoDropdownLink">
            <span className="icon icon-p-impersonate" /> Impersonate
          </span>
        </div>
      )}
      {isSlideOut && (
        <div
          onClick={() => {
            toggleNavig();
            onSlideOut();
          }}
          className="nav-it"
        >
          <span role="button" tabIndex={-4} className="isoDropdownLink">
            <span className="icon icon-p-impersonate-exit" /> Exit
          </span>
        </div>
      )}
      <div
        onClick={() => {
          toggleNavig();
          onLogout();
        }}
        className="nav-it logout"
      >
        <span role="button" tabIndex={-5} className="isoDropdownLink">
          <span className="icon log-out icon-p-log-out" /> <IntlMessages id="topbar.logout" />
        </span>
      </div>
    </div>
  );
};

export default TopbarUser;
