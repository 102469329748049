import actions from './actions';
import { createReducer } from '../../helpers/reducer';
import { keyBy } from '../../helpers/core';

function validate({ attr, value = '' }) {
  let error = '';

  if (attr === 'email') {
    const re = /^[^\s@]+@[^\s@]+.[^\s@]+$/;
    if (!re.test(value)) {
      error = 'Please enter a valid email';
    }
  }

  return error;
}

// ------------------------------
// Initial State
// ------------------------------
const initState = {
  email: {
    value: '',
    error: validate({ attr: 'email' }),
    name: 'email',
    touched: false,
  },
  emailSent: false,
  loading: false,
  error: '',
};

// ------------------------------
// Action Handlers
// ------------------------------
function onLoadSuccess(state, { payload }) {
  const { emailSent } = payload;

  return {
    ...state,
    emailSent,
    error: '',
    loading: false,
  };
}

function onLoadError(state, { payload }) {
  return {
    ...state,
    error: payload.error,
    isLoading: false,
  };
}

function onTouch(state, { payload }) {
  const { props } = payload;
  const propsArray = props.map(prop => state[prop]);

  const updatedState = keyBy(
    propsArray,
    prop => prop.name,
    prop => ({ ...prop, touched: true }),
  );

  return { ...state, ...updatedState };
}

function onChangeProperty(state, { payload }) {
  const { attr, value } = payload;

  state[attr] = {
    value,
    error: validate({ attr, value, state }),
    name: attr,
    touched: true,
  };

  return { ...state };
}

function onLoad(state) {
  return {
    ...state,
    error: '',
    loading: true,
  };
}

function onClearState(state) {
  return {
    ...state,
    ...initState,
  };
}

// ------------------------------
// Exported reducer function.
// ------------------------------
export default createReducer(initState, {
  [actions.CHANGE_PROPERTY]: onChangeProperty,
  [actions.TOUCH]: onTouch,
  [actions.LOAD]: onLoad,
  [actions.LOAD_SUCCESS]: onLoadSuccess,
  [actions.LOAD_ERROR]: onLoadError,
  [actions.CLEAR_STATE]: onClearState,
});
