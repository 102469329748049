const actions = {
  SET_WELCOME_BLOCK_OPEN: 'SET_WELCOME_BLOCK_OPEN',
  CHANGE_TELL_ABOUT_PROPERTY: 'CHANGE_TELL_ABOUT_PROPERTY',
  SET_SURVEY_DATA: 'SET_SURVEY_DATA',
  SEND_SURVEY_DATA: 'SEND_SURVEY_DATA',
  CLEAR_STATE: 'WELCOME_CLEAR_STATE',

  setWelcomeBlockOpen: data => ({
    type: actions.SET_WELCOME_BLOCK_OPEN,
    payload: data,
  }),
  changeProperty: data => ({
    type: actions.CHANGE_TELL_ABOUT_PROPERTY,
    payload: data,
  }),
  setSurveyData: data => ({
    type: actions.SET_SURVEY_DATA,
    payload: data,
  }),
  sendSurveyData: data => ({
    type: actions.SEND_SURVEY_DATA,
    payload: data,
  }),

  clearState: () => ({
    type: actions.CLEAR_STATE,
  }),
};

export default actions;
