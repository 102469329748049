const COMPONENT = 'RESET_PASS';

const resetPasswordActions = {
  CHANGE_PROPERTY: `${COMPONENT}CHANGE_PROPERTY`,
  TOUCH: `${COMPONENT}TOUCH`,
  CLEAR_STATE: `${COMPONENT}CLEAR_STATE`,
  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_ERROR: 'RESET_PASSWORD_ERROR',
  THROW_ERROR: 'THROW_ERROR',

  resetPasswordRequest: data => ({
    type: resetPasswordActions.RESET_PASSWORD_REQUEST,
    payload: data,
  }),

  changeProperty: data => ({
    type: resetPasswordActions.CHANGE_PROPERTY,
    payload: data,
  }),

  touch: data => ({
    type: resetPasswordActions.TOUCH,
    payload: data,
  }),

  resetPasswordSuccess: data => ({
    type: resetPasswordActions.RESET_PASSWORD_SUCCESS,
    payload: data,
  }),

  resetPasswordError: data => ({
    type: resetPasswordActions.RESET_PASSWORD_ERROR,
    payload: data,
  }),

  clearState: () => ({
    type: resetPasswordActions.CLEAR_STATE,
  }),

  throwError: data => ({
    type: resetPasswordActions.THROW_ERROR,
    payload: data,
  }),
};
export default resetPasswordActions;
