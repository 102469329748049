import { all, put, takeEvery, fork } from 'redux-saga/effects';
import actions from './actions';
import RestApi from '../../helpers/api';
import settings from '../../settings';

const api = new RestApi('chat-gpt/swot-detail-info');
const apiAnnie = new RestApi('chat-gpt/ask-annie');
const apiDelAnnieChat = new RestApi('chat-gpt/chat-collection');

export function* loadRequest() {
  yield takeEvery(actions.LOAD_REQUEST, function*({ payload }) {
    const { swotDetailId } = payload;

    try {
      const response = yield api.load({
        swotDetailId,
      });

      if (response.status === 'success') {
        yield put(
          actions.loadSuccess({
            swotDetail: response.data,
          }),
        );
      } else {
        console.log('loadRiskRequest response', response.error);
      }
    } catch (e) {
      console.log('loadRequest', e);
    }
  });
}

export function* loadAnnieRequest() {
  yield takeEvery(actions.LOAD_ANNIE_REQUEST, function*({ payload }) {
    const { question, companyId, userId, chatId, swotDetailId, swotType } = payload;

    try {
      const response = yield apiAnnie.createItemCustom(`${settings.apiAiUrl}`, {
        question,
        companyId,
        userId,
        chatId,
        swotDetailId,
        swotType,
      });

      if (response.status === 'success') {
        yield put(
          actions.loadAnnieSuccess({
            annieLoading: false,
          }),
        );
      } else {
        console.log('loadRiskRequest response', response.error);
      }
    } catch (e) {
      console.log('loadTimelineRequest', e);
    }
  });
}

export function* deleteAnnieChatRequest() {
  yield takeEvery(actions.DELETE_ANNIE_CHAT_REQUEST, function*({ payload }) {
    const { chatId, userId } = payload;

    try {
      const response = yield apiDelAnnieChat.deleteItemCustom(`${settings.apiAiUrl}`, {
        chatId,
        userId,
      });

      if (response.status === 'success') {
        console.log(chatId, 'deleted');
      } else {
        console.log('loadRiskRequest response', response.error);
      }
    } catch (e) {
      console.log('loadTimelineRequest', e);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(loadRequest), fork(loadAnnieRequest), fork(deleteAnnieChatRequest)]);
}
