import { all, put, takeEvery, fork } from 'redux-saga/effects';
import actions from './actions';

import RestApi from '../../../helpers/api';

const api = new RestApi('risk-tracker/crt-sentiment-group-item');
const apiRiskDate = new RestApi('risk-tracker/crt-item');

const apiUCRT = new RestApi('risk-tracker/ucrt-sentiment-group-item');
const apiItemUCRT = new RestApi('risk-tracker/ucrt-item');

export function* loadRiskRequest() {
  yield takeEvery(actions.LOAD_RISK_REQUEST, function*({ payload }) {
    const { sentimentGroupId, date, companyId, scoreId, reportId, isEarly = false } = payload;

    try {
      let response;
      let response1;

      if (date) {
        response = yield api.load({
          itemId: sentimentGroupId,
          date,
          companyId,
          reportId,
        });

        response1 = yield apiRiskDate.load({
          reportId,
          date,
          companyId,
        });
      } else {
        response = yield apiUCRT.load({
          itemId: sentimentGroupId,
          reportId,
        });

        response1 = yield apiItemUCRT.load({
          companyId,
          reportId,
        });
      }

      if (response.status === 'success' && response1.status === 'success') {
        // eslint-disable-next-line prefer-const
        let { cards, pies, risk, diagrams, diagrams_1, diagrams_6, historicalChartView } = response.data;
        const { publicationDate, reportName, reportDate, scores = null, scores_alt = null } = response1.data;

        const dateScores =
          // eslint-disable-next-line no-nested-ternary
          scores && isEarly ? scores_alt.flatMap(el => el.items) : scores ? scores.flatMap(el => el.items) : null;

        cards = cards.find(el => el.id === scoreId);
        pies = pies.find(el => el.id === scoreId);
        yield put(
          actions.loadRiskSuccess({
            card: cards,
            piesData: pies,
            risk,
            dateScore: dateScores ? dateScores.find(el => el.id === scoreId) : null,
            diagrams: diagrams[0].lines,
            diagrams_1: (diagrams_1 && diagrams_1[0].lines) || null,
            diagrams_6: (diagrams_6 && diagrams_6[0].lines) || null,
            historicalChartView,
            publicationDate,
            reportName,
            reportDate,
          }),
        );
      } else {
        console.log('response error', response.error);
      }
    } catch (e) {
      console.log('loadRiskRequest', e);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(loadRiskRequest)]);
}
