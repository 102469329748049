const COMPONENT = 'PROVIDER';

const profileActions = {
  SEND_PROFILE_INFO: `${COMPONENT}_SEND_PROFILE_INFO`,

  sendProfileInfo: data => ({
    type: profileActions.SEND_PROFILE_INFO,
    payload: data,
  }),
};
export default profileActions;
