import { all, takeEvery, put, fork } from 'redux-saga/effects';
import { callApi } from '../../helpers/api';
import changePasswordActions from './actions';

export function* changePasswordRequest() {
  yield takeEvery('CHANGE_PASSWORD_REQUEST', function*({ payload }) {
    const result = yield callApi('users/change-password', 'put', payload);
    if (result.status === 'success') {
      yield put(changePasswordActions.changePasswordSuccess());
      yield put(changePasswordActions.clearState());
    } else {
      yield put(changePasswordActions.changePasswordError(result));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(changePasswordRequest)]);
}
