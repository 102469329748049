import { all, fork, put, take, takeEvery } from 'redux-saga/effects';
import { v4 } from 'uuid';
import { channel } from 'redux-saga';
import actions from './actions';
import RestApi, { callApi } from '../../helpers/api';
import { storage } from '../../redux/firestore';

const api = new RestApi('quotes');

export function* load(data) {
  try {
    const { payload } = data;
    const { quoteId } = payload;

    const response = yield api.loadItem(quoteId);

    if (response.status === 'success') {
      const { data } = response;

      yield put(
        actions.loadSuccess({
          data,
        }),
      );
    } else if (response.status === 'error') {
      const { error } = response.data;

      yield put(actions.loadError(error));
    }
  } catch (error) {
    console.warn(error);
  }
}

export function* sendMessage({ payload }) {
  try {
    const { quoteId, ...message } = payload;

    yield callApi(`quotes/${quoteId}/comment`, 'POST', {
      ...message,
    });
  } catch (error) {
    console.warn('error', error);
  }
}

export function* memberAccept({ payload }) {
  try {
    const { quoteId, responseText, responseSummary, responseattachments } = payload;
    const responseAttachments =
      responseattachments && responseattachments.length
        ? responseattachments.map(el => {
            return { link: el.link, name: el.name };
          })
        : [];

    const result = yield callApi(`quotes/member/accept/${quoteId}`, 'POST', {
      responseText,
      responseSummary,
      responseAttachments,
    });

    if (result.status === 'success') {
      yield put(
        actions.memberAcceptSuccess({
          quoteId,
          responsetext: responseText,
          responsesummary: responseSummary,
          responseattachments: responseAttachments,
        }),
      );
    }
  } catch (error) {
    console.warn('error', error);
  }
}

export function* providerRespond({ payload }) {
  try {
    const { quoteId, responseText, responseSummary, responseattachments } = payload;

    const responseAttachments =
      responseattachments && responseattachments.length
        ? responseattachments.map(el => {
            return { link: el.link, name: el.name };
          })
        : [];

    const result = yield callApi(`quotes/provider/respond/${quoteId}`, 'POST', {
      responseText,
      responseSummary,
      responseAttachments,
    });

    if (result.status === 'success') {
      yield put(
        actions.providerRespondSuccess({
          quoteId,
          responsetext: responseText,
          responsesummary: responseSummary,
          responseattachments: responseAttachments,
        }),
      );
    }
  } catch (error) {
    console.warn('error', error);
  }
}

export function* providerReject({ payload }) {
  try {
    const { quoteId, responseText, responseSummary, responseattachments } = payload;

    const responseAttachments =
      responseattachments && responseattachments.length
        ? responseattachments.map(el => {
            return { link: el.link, name: el.name };
          })
        : [];

    const result = yield callApi(`quotes/provider/reject/${quoteId}`, 'POST', {
      responseText,
      responseSummary,
      responseAttachments,
    });

    if (result.status === 'success') {
      yield put(
        actions.providerRejectSuccess({
          quoteId,
          responsetext: responseText,
          responsesummary: responseSummary,
          responseattachments: responseAttachments,
        }),
      );
    }
  } catch (error) {
    console.warn('error', error);
  }
}

export function* memberReject({ payload }) {
  try {
    const { quoteId, responseText, responseSummary, responseattachments } = payload;

    const responseAttachments =
      responseattachments && responseattachments.length
        ? responseattachments.map(el => {
            return { link: el.link, name: el.name };
          })
        : [];

    const result = yield callApi(`quotes/member/reject/${quoteId}`, 'POST', {
      responseText,
      responseSummary,
      responseAttachments,
    });

    if (result.status === 'success') {
      yield put(
        actions.memberRejectSuccess({
          quoteId,
          responsetext: responseText,
          responsesummary: responseSummary,
          responseattachments: responseAttachments,
        }),
      );
    }
  } catch (error) {
    console.warn('error', error);
  }
}

export const fileChannel = channel();

export function* uploadFile() {
  yield takeEvery(actions.FILE_UPLOADING, function*({ payload }) {
    try {
      const { file, quoteId, type } = payload;
      if (type === 'file') {
        const id = v4();
        const name = `${file.name}`;
        const filename = `${quoteId}-${id}`;
        Object.defineProperty(file, 'name', {
          writable: true,
          value: filename,
        });

        const uploadTask = storage.ref(`/qq-attachments/${quoteId}/${file.name}`).put(file);

        uploadTask.on(
          'state_changed',
          snapshot => {
            const progressPercentage = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            fileChannel.put(actions.changeProperty({ attr: 'progress', value: progressPercentage }));
          },
          error => console.log('uploadTask ===>>', error),
          () => {
            storage
              .ref(`/qq-attachments/${quoteId}/`)
              .child(file.name)
              .getDownloadURL()
              .then(link => {
                fileChannel.put(actions.fileUploadSuccess({ name, link, quoteId, type }));
              });
          },
        );
      }
    } catch (e) {
      console.log('error', e);
      yield put(actions.fileUploadError(e));
    }
  });
}

export function* watchFileChannel() {
  while (true) {
    const action = yield take(fileChannel);
    yield put(action);
  }
}
export default function* rootSaga() {
  yield all([
    fork(uploadFile),
    fork(watchFileChannel),
    takeEvery(actions.SEND_MESSAGE, sendMessage),
    takeEvery(actions.LOAD, load),
    takeEvery(actions.MEMBER_ACCEPT, memberAccept),
    takeEvery(actions.PROVIDER_RESPOND, providerRespond),
    takeEvery(actions.PROVIDER_REJECT, providerReject),
    takeEvery(actions.MEMBER_REJECT, memberReject),
  ]);
}
