import actions from './actions';
import { createReducer } from '../../helpers/reducer';

// ------------------------------
// Initial State
// ------------------------------
const initState = {
  data: null,
  isPublished: false,
  executiveSummary: undefined,
  dateScores: undefined,
  loading: false,
  historicalChartView: 3,
  updating: false,
  validation: false,
  publishing: false,
  saveActive: true,
  created: undefined,
  updated: undefined,
  rangeErrorId: undefined,
  isVisible: false,
  confirm: false,
  isPrivate: false,
  reportId: undefined,
  reportName: undefined,
  reportDate: undefined,
  isDefTour: false,
  dateValues: {
    startDate: '',
    endDate: '',
    description: '',
  },
};

// ------------------------------
// Action Handlers
// ------------------------------
function onLoadRiskSuccess(state, { payload }) {
  const {
    data,
    executiveSummary,
    reportName,
    isPublished,
    updated,
    created,
    reportDate,
    isPrivate,
    dateScores,
  } = payload;

  return {
    ...state,
    data,
    executiveSummary,
    dateScores,
    isPublished,
    reportName,
    reportDate,
    updated,
    created,
    isPrivate,
  };
}

function onClear() {
  return {
    initState,
  };
}

// ------------------------------
// Exported reducer function.
// ------------------------------
export default createReducer(initState, {
  [actions.LOAD_RISK_SUCCESS]: onLoadRiskSuccess,
  [actions.CLEAR]: onClear,
});
