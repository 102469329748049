import actions from './actions';
import { createReducer } from '../../helpers/reducer';

// ------------------------------
// Initial State
// ------------------------------
const initState = {
  companiesList: [],
  annieAnswer: '',
  annieLoading: 'wait',
  buyCreditsProcess: 'wait',
};

// ------------------------------
// Action Handlers
// ------------------------------
function onLoadCompaniesSuccess(state, { payload }) {
  const { companiesList } = payload;

  return {
    ...state,
    companiesList,
  };
}

function onLoadCompaniesError(state, { payload }) {
  const { companiesList } = payload;

  return {
    ...state,
    companiesList,
  };
}

function deleteHistoryCompanyRequest(state) {
  return {
    ...state,
  };
}

function onLoadBuyCreditSuccess(state) {
  return {
    ...state,
    buyCreditsProcess: 'success',
  };
}

function onLoadBuyCreditRequest(state) {
  return {
    ...state,
    buyCreditsProcess: 'pending',
  };
}

function deleteHistoryCompanySuccess(state) {
  return {
    ...state,
    // annieAnswer,
    // annieLoading: false,
  };
}
function deleteHistoryCompanyError(state) {
  return {
    ...state,
  };
}

function onChangeCreditsProcess(state, { payload }) {
  const { buyCreditsProcess } = payload;

  return {
    ...state,
    buyCreditsProcess,
  };
}

function onClear() {
  return {
    ...initState,
  };
}

// ------------------------------
// Exported reducer function.
// ------------------------------
export default createReducer(initState, {
  [actions.LOAD_COMPANIES_SUCCESS]: onLoadCompaniesSuccess,
  [actions.LOAD_COMPANIES_ERROR]: onLoadCompaniesError,

  [actions.LOAD_BUY_CREDIT_SUCCESS]: onLoadBuyCreditSuccess,
  [actions.LOAD_BUY_CREDIT_REQUEST]: onLoadBuyCreditRequest,

  [actions.CHANGE_CREDITS_PROCESS]: onChangeCreditsProcess,

  [actions.DELETE_HISTORY_COMPANY_REQUEST]: deleteHistoryCompanyRequest,
  [actions.DELETE_HISTORY_COMPANY_SUCCESS]: deleteHistoryCompanySuccess,
  [actions.DELETE_HISTORY_COMPANY_ERROR]: deleteHistoryCompanyError,

  [actions.CLEAR]: onClear,
});
