import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router';
import App from './containers/App/App';
import asyncComponent from './helpers/AsyncFunc';

const SIGN_IN_ROUTE = '/signin';
const SIGN_UP_ROUTE = '/signup';
const SIGN_UP_PUBLIC_ROUTE = '/sign-up';
const FORGOT_PASSWORD = '/forgotpassword';
const SET_PASSWORD = '/setpassword';
const RESET_PASSWORD = '/resetpassword';
const COMPANY_PREVIEW = '/my-company-preview';
const WEBEX = '/webex';

const RestrictedRoute = ({ component: Component, history, ...rest }) => {
  if (
    history.location.pathname === SIGN_IN_ROUTE ||
    history.location.pathname === SIGN_UP_ROUTE ||
    history.location.pathname === FORGOT_PASSWORD ||
    history.location.pathname === SET_PASSWORD ||
    history.location.pathname === RESET_PASSWORD ||
    history.location.pathname === WEBEX
  ) {
    return null;
  }

  return <Route {...rest} render={props => <Component {...props} />} />;
};

const PublicRoutes = ({ history }) => {
  return (
    <ConnectedRouter history={history} noInitialPop>
      <Switch>
        <Route exact path="/" component={asyncComponent(() => import('./containers/Signin/Signin'))} />
        <Route path={WEBEX} component={asyncComponent(() => import('./containers/WebexPage/WebexPage'))} />
        <Route path={SIGN_IN_ROUTE} component={asyncComponent(() => import('./containers/Signin/Signin'))} />
        <Route path={SIGN_UP_ROUTE} component={asyncComponent(() => import('./containers/SignupPublic/Signup'))} />

        <Route
          path={SIGN_UP_PUBLIC_ROUTE}
          component={asyncComponent(() => import('./containers/SignupPublic/Signup'))}
        />
        <Route path={COMPANY_PREVIEW} component={asyncComponent(() => import('./containers/CompanyPreview/Company'))} />
        <Route
          path={FORGOT_PASSWORD}
          component={asyncComponent(() => import('./containers/ForgotPassword/ForgotPassword'))}
        />
        <Route
          path={RESET_PASSWORD}
          component={asyncComponent(() => import('./containers/ResetPassword/ResetPassword'))}
        />
        <Route path={SET_PASSWORD} component={asyncComponent(() => import('./containers/SetPassword/SetPassword'))} />
        <RestrictedRoute history={history} component={App} />
      </Switch>
    </ConnectedRouter>
  );
};

export default PublicRoutes;
