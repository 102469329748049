const COMPONENT = 'RISK';

const actions = {
  LOAD_RISK_REQUEST: `${COMPONENT}LOAD_RISK_REQUEST`,
  LOAD_RISK_SUCCESS: `${COMPONENT}LOAD_RISK_SUCCESS`,
  LOAD_TIMELINE_REQUEST: `${COMPONENT}_LOAD_TIMELINE_REQUEST`,
  LOAD_TIMELINE_SUCCESS: `${COMPONENT}_LOAD_TIMELINE_SUCCESS`,
  SET_FILTER: `${COMPONENT}SET_FILTER`,
  SWITCH_CHART_VIEW: `${COMPONENT}SWITCH_CHART_VIEW`,
  CLEAR: `${COMPONENT}CLEAR`,
  LOAD_ERROR: `${COMPONENT}_LOAD_ERROR`,

  loadRiskRequest: data => ({
    type: actions.LOAD_RISK_REQUEST,
    payload: data,
  }),

  loadRiskSuccess: data => ({
    type: actions.LOAD_RISK_SUCCESS,
    payload: data,
  }),

  loadTimelineRequest: data => ({
    type: actions.LOAD_TIMELINE_REQUEST,
    payload: data,
  }),

  loadTimelineSuccess: data => ({
    type: actions.LOAD_TIMELINE_SUCCESS,
    payload: data,
  }),

  switchChartView: data => ({
    type: actions.SWITCH_CHART_VIEW,
    payload: data,
  }),

  setFilter: data => ({
    type: actions.SET_FILTER,
    payload: data,
  }),

  loadError: data => ({
    type: actions.LOAD_ERROR,
    payload: data,
  }),

  clear: () => ({
    type: actions.CLEAR,
  }),
};
export default actions;
