const COMPONENT = 'ALL_PROVIDERS';

const actions = {
  LOAD_PRODUCTS_REQUEST: `${COMPONENT}LOAD_PRODUCTS_REQUEST`,
  LOAD_PRODUCTS_SUCCESS: `${COMPONENT}LOAD_PRODUCTS_SUCCESS`,
  LOAD_PRODUCTS_ERROR: `${COMPONENT}LOAD_PRODUCTS_ERROR`,
  CHANGE_PROPERTY: `${COMPONENT}CHANGE_PROPERTY`,
  SELECT: `${COMPONENT}SELECT`,
  SET_IDS: `${COMPONENT}SET_IDS`,
  CLEAR_STATE: `${COMPONENT}CLEAR_STATE`,
  AUTO_SUGGESTIONS: `${COMPONENT}AUTO_SUGGESTIONS`,
  AUTO_SUGGESTIONS_SUCCESS: `${COMPONENT}AUTO_SUGGESTIONS_SUCCESS`,

  loadProductsRequest: data => ({
    type: actions.LOAD_PRODUCTS_REQUEST,
    payload: data,
  }),

  loadProductsSuccess: data => ({
    type: actions.LOAD_PRODUCTS_SUCCESS,
    payload: data,
  }),

  select: data => ({
    type: actions.SELECT,
    payload: data,
  }),

  setIds: data => ({
    type: actions.SET_IDS,
    payload: data,
  }),

  changeProperty: data => ({
    type: actions.CHANGE_PROPERTY,
    payload: data,
  }),

  loadProductsError: data => ({
    type: actions.LOAD_PRODUCTS_ERROR,
    payload: data,
  }),

  clearState: () => ({
    type: actions.CLEAR_STATE,
  }),

  autoSuggestions: data => ({
    type: actions.AUTO_SUGGESTIONS,
    payload: data,
  }),

  autoSuggestionsSuccess: data => ({
    type: actions.AUTO_SUGGESTIONS_SUCCESS,
    payload: data,
  }),
};
export default actions;
