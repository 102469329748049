import { all, put, takeEvery, fork } from 'redux-saga/effects';
import actions from './actions';
import RestApi from '../../helpers/api';

const api = new RestApi('risk-tracker/crt-sentiment-group-item');
const apiRiskDate = new RestApi('risk-tracker/crt-item');

const apiUCRT = new RestApi('risk-tracker/ucrt-sentiment-group-item');
const apiUCRTItem = new RestApi('risk-tracker/ucrt-item');

export function* loadRiskRequest() {
  yield takeEvery(actions.LOAD_RISK_REQUEST, function*({ payload }) {
    const { sentimentGroupId, date, companyId, reportId } = payload;

    let response;
    let response1;

    try {
      if (date) {
        response = yield api.load({
          itemId: sentimentGroupId,
          date,
          companyId,
          reportId,
        });

        response1 = yield apiRiskDate.load({
          date,
          companyId,
          reportId,
        });
      } else {
        response = yield apiUCRT.load({
          itemId: sentimentGroupId,
          reportId,
        });

        response1 = yield apiUCRTItem.load({
          reportId,
        });
      }

      if (response.status === 'success') {
        const {
          cards,
          historicalOverview,
          diagrams,
          diagrams_1,
          diagrams_6,
          historicalChartView,
          risk,
        } = response.data;
        const { isPublished = false, publicationDate, reportName, reportDate } = response1.data;

        yield put(
          actions.loadRiskSuccess({
            cards: cards.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1)),
            historicalOverview,
            lines: diagrams[0].lines,
            diagrams,
            diagrams_1,
            diagrams_6,
            historicalChartView,
            isPublished,
            publicationDate,
            reportName,
            reportDate,
            risk,
          }),
        );
      } else if (response.status === 'error') {
        console.log('loadRiskRequest response status: error');
      }
    } catch (e) {
      console.log('loadRiskRequest error', e);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(loadRiskRequest)]);
}
