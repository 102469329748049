const COMPONENT = 'RISK_PREVIEW';

const actions = {
  LOAD_RISK_REQUEST: `${COMPONENT}LOAD_RISK_REQUEST`,
  LOAD_RISK_SUCCESS: `${COMPONENT}LOAD_RISK_SUCCESS`,
  LOAD_TIMELINE_REQUEST: `${COMPONENT}_LOAD_TIMELINE_REQUEST`,
  LOAD_TIMELINE_SUCCESS: `${COMPONENT}_LOAD_TIMELINE_SUCCESS`,
  SET_FILTER: `${COMPONENT}SET_FILTER`,
  CLEAR: `${COMPONENT}CLEAR`,
  LOAD_ERROR: `${COMPONENT}_LOAD_ERROR`,
  LOADING: `${COMPONENT}_LOADING`,

  PUBLISH_RISK_REQUEST: `${COMPONENT}_PUBLISH_RISK_REQUEST`,
  PUBLISH_RISK_SUCCESS: `${COMPONENT}_PUBLISH_RISK_SUCCESS`,
  PUBLISH_RISK_VALIDATION: `${COMPONENT}_PUBLISH_RISK_VALIDATION`,

  LOAD_SHARE_LIST_REQUEST: `${COMPONENT}_LOAD_SHARE_LIST_REQUEST`,
  LOAD_SHARE_LIST_SUCCESS: `${COMPONENT}_LOAD_SHARE_LIST_SUCCESS`,

  UPDATE_SHARE_LIST_REQUEST: `${COMPONENT}_UPDATE_SHARE_LIST_REQUEST`,
  UPDATE_SHARE_LIST_SUCCESS: `${COMPONENT}_UPDATE_SHARE_LIST_SUCCESS`,

  SWITCH_LIST_VISIBILITY: `${COMPONENT}_SWITCH_LIST_VISIBILITY`,

  SWITCH_SHARE_STATUS: `${COMPONENT}_SWITCH_SHARE_STATUS`,

  loadRiskRequest: data => ({
    type: actions.LOAD_RISK_REQUEST,
    payload: data,
  }),

  switchShareStatus: data => ({
    type: actions.SWITCH_SHARE_STATUS,
    payload: data,
  }),

  loadShareListRequest: data => ({
    type: actions.LOAD_SHARE_LIST_REQUEST,
    payload: data,
  }),

  loadShareListSuccess: data => ({
    type: actions.LOAD_SHARE_LIST_SUCCESS,
    payload: data,
  }),

  updateShareListRequest: data => ({
    type: actions.UPDATE_SHARE_LIST_REQUEST,
    payload: data,
  }),

  updateShareListSuccess: data => ({
    type: actions.UPDATE_SHARE_LIST_SUCCESS,
    payload: data,
  }),

  loading: data => ({
    type: actions.LOADING,
    payload: data,
  }),

  publishRiskRequest: data => ({
    type: actions.PUBLISH_RISK_REQUEST,
    payload: data,
  }),

  publishRiskSuccess: data => ({
    type: actions.PUBLISH_RISK_SUCCESS,
    payload: data,
  }),

  publishRiskValidation: data => ({
    type: actions.PUBLISH_RISK_VALIDATION,
    payload: data,
  }),

  loadRiskSuccess: data => ({
    type: actions.LOAD_RISK_SUCCESS,
    payload: data,
  }),

  loadTimelineRequest: data => ({
    type: actions.LOAD_TIMELINE_REQUEST,
    payload: data,
  }),

  loadTimelineSuccess: data => ({
    type: actions.LOAD_TIMELINE_SUCCESS,
    payload: data,
  }),

  setFilter: data => ({
    type: actions.SET_FILTER,
    payload: data,
  }),

  switchListVisibility: () => ({
    type: actions.SWITCH_LIST_VISIBILITY,
  }),

  loadError: data => ({
    type: actions.LOAD_ERROR,
    payload: data,
  }),

  clear: () => ({
    type: actions.CLEAR,
  }),
};
export default actions;
