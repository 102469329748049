import { all, takeEvery, put, fork } from 'redux-saga/effects';
import actions from './actions';
import RestApi from '../../helpers/api';

const api = new RestApi('users/');

export function* usersRequest() {
  yield takeEvery('USERS_REQUEST', function*({ payload }) {
    const { page, limit, column, filter, count } = payload;
    const desc = payload.order === 'descend' ? 'DESC' : '';
    const order = payload.order === 'ascend' ? 'ASC' : desc;
    const params = {
      count,
      page,
      limit,
      column,
      filter,
    };

    if (order) {
      params.order = order;
    }

    const response = yield api.load(params);

    if (response.status === 'success') {
      const { users, count } = response.data;

      yield put(
        actions.loadUsersSuccess({
          users,
          count,
        }),
      );
    } else if (response.status === 'error') {
      const { error } = response.data;

      yield put(actions.loadUsersError(error));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(usersRequest)]);
}
