import actions from './actions';
import { createReducer } from '../../../helpers/reducer';

// ------------------------------
// Initial State
// ------------------------------
const initState = {
  historicalOverview: '',
  card: null,
  piesData: null,
  risk: null,
  risks: null,
  diagrams: null,
  loading: false,
  publicationDate: undefined,
  reportName: undefined,
};

// ------------------------------
// Action Handlers
// ------------------------------
function onLoadRiskSuccess(state, { payload }) {
  const {
    card,
    piesData,
    risk,
    diagrams,
    diagrams_1,
    diagrams_6,
    historicalChartView,
    publicationDate,
    reportName,
  } = payload;

  return {
    ...state,
    card,
    piesData,
    risk,
    diagrams,
    diagrams_1,
    diagrams_6,
    historicalChartView,
    publicationDate,
    reportName,
  };
}

function onSwitchChartView(state, { payload }) {
  const { historicalChartView } = payload;

  return {
    ...state,
    historicalChartView,
  };
}

function onLoadError(state) {
  return {
    ...state,
    isLoading: false,
  };
}

function onClear(state) {
  return {
    ...state,
    keywords: [],
  };
}

// ------------------------------
// Exported reducer function.
// ------------------------------
export default createReducer(initState, {
  [actions.LOAD_RISK_SUCCESS]: onLoadRiskSuccess,
  [actions.SWITCH_CHART_VIEW]: onSwitchChartView,
  [actions.LOAD_ERROR]: onLoadError,
  [actions.CLEAR]: onClear,
});
