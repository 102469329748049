import actions from './actions';
import { createReducer } from '../../helpers/reducer';

// ------------------------------
// Initial State
// ------------------------------
const initState = {
  bundle: {},
  historicalOverview: '',
  historicalChartView: 3,
  lines: null,
  diagrams: null,
  diagrams_1: null,
  diagrams_6: null,
  isPublished: false,
  risks: null,
  cards: null,
  companies: [],
  keywords: [],
  loading: false,
  publicationDate: undefined,
  reportName: undefined,
  reportDate: undefined,
  risk: '',
};

// ------------------------------
// Action Handlers
// ------------------------------
function onLoadRiskSuccess(state, { payload }) {
  const {
    historicalOverview,
    cards,
    lines,
    diagrams,
    diagrams_1,
    diagrams_6,
    isPublished,
    publicationDate,
    reportName,
    reportDate,
    historicalChartView,
    risk,
  } = payload;

  return {
    ...state,
    historicalOverview,
    cards,
    diagrams,
    diagrams_1,
    diagrams_6,
    historicalChartView,
    lines,
    isPublished,
    publicationDate,
    reportName,
    reportDate,
    risk,
  };
}

function onLoadTimelineSuccess(state, { payload }) {
  const { risks } = payload;

  return {
    ...state,
    risks,
  };
}

function onSetFilter(state, { payload }) {
  const { keywords } = payload;

  return {
    ...state,
    keywords,
  };
}

function onLoadError(state) {
  return {
    ...state,
    isLoading: false,
  };
}

function onSwitchChartView(state, { payload }) {
  const { historicalChartView } = payload;

  return {
    ...state,
    historicalChartView,
  };
}

function onClear() {
  return {
    initState,
  };
}

// ------------------------------
// Exported reducer function.
// ------------------------------
export default createReducer(initState, {
  [actions.LOAD_RISK_SUCCESS]: onLoadRiskSuccess,
  [actions.LOAD_TIMELINE_SUCCESS]: onLoadTimelineSuccess,
  [actions.SET_FILTER]: onSetFilter,
  [actions.LOAD_ERROR]: onLoadError,
  [actions.SWITCH_CHART_VIEW]: onSwitchChartView,
  [actions.CLEAR]: onClear,
});
