import React from 'react';
import { Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import qs from 'query-string';
import { detect } from 'detect-browser';

import asyncComponent from '../../helpers/AsyncFunc';

const browser = detect();

const routesProvider = [
  {
    path: '',
    component: asyncComponent(() => import('../dashboard')),
  },
  {
    path: 'my-company',
    component: asyncComponent(() => import('../Provider/Provider')),
  },
  {
    path: 'home',
    component: asyncComponent(() => import('../Home/Home')),
  },
  {
    path: 'content-packs',
    component: asyncComponent(() => import('../ContentPacks/ContentPacks')),
  },
  {
    path: 'company-report',
    component: asyncComponent(() => import('../Report/Report')),
  },
  {
    path: 'user-management',
    component: asyncComponent(() => import('../UserManagement/UserManagement')),
  },
  {
    path: 'content-packs/pack/:packId',
    component: asyncComponent(() => import('../ContentPack/ContentPack')),
  },
  {
    path: 'content-packs/templates/:templateId',
    component: asyncComponent(() => import('../TemplatePack/TemplatePack')),
  },
  {
    path: 'content-packs/settings/:packId',
    component: asyncComponent(() => import('../PackSettings/PackSettings')),
  },
  {
    path: 'content-packs/create-pack',
    component: asyncComponent(() => import('../PackCreate/PackCreate')),
  },
  {
    path: 'content-packs/pack/:packId/company/:companyId',
    component: asyncComponent(() => import('../ContentPackCompany/ContentPackCompany')),
  },
  {
    path: 'content-packs/pack/:packId/company/:companyId/question/:questionId',
    component: asyncComponent(() => import('../PackCompanyQuestion/PackCompanyQuestion')),
  },
  {
    path: 'help',
    component: asyncComponent(() => import('../Help/Help')),
  },
  {
    path: 'aniline-benchmark',
    component: asyncComponent(() => import('../Benchmark/Benchmark')),
  },
  {
    path: 'report-pdf-view/:companyId',
    component: asyncComponent(() => import('../PrintView/PrintView')),
  },
  {
    path: 'score/:scoreId',
    component: asyncComponent(() => import('../Company/Score/Score')),
  },
  {
    path: 'analysis-recommendation/:analysisId',
    component: asyncComponent(() => import('../Analysis/Analysis')),
  },
  {
    path: 'swot-detail/:swotDetailId',
    component: asyncComponent(() => import('../SwotItem/SwotItem')),
  },
  {
    path: 'score-custom/:scoreId',
    component: asyncComponent(() => import('../Company/ScoreCustom/ScoreCustom')),
  },
  {
    path: 'sub-score/:scoreId',
    component: asyncComponent(() => import('../Company/SubScore/SubScore')),
  },
  {
    path: 'score-ag/:scoreId',
    component: asyncComponent(() => import('../Company/ScoreEmpty/ScoreEmpty')),
  },
  {
    path: 'risk-editor/:companyId',
    component: asyncComponent(() => import('../RiskEditor/RiskEditor')),
  },
  {
    path: 'risk-custom/:companyId',
    component: asyncComponent(() => import('../RiskCustom/RiskCustom')),
  },
  {
    path: 'risk/:companyId',
    component: asyncComponent(() => import('../Risk/Risk')),
  },
  {
    path: 'risk-preview/:companyId',
    component: asyncComponent(() => import('../RiskPreview/RiskPreview')),
  },
  {
    path: 'risk-ag/:companyId',
    component: asyncComponent(() => import('../RiskEmpty/RiskEmpty')),
  },
  {
    path: 'my-company/add-new-service',
    component: asyncComponent(() => import('../Provider/ProductsAndServices/AddNewService')),
  },
  {
    path: 'my-company/products-and-services/:productId',
    component: asyncComponent(() => import('../Provider/ProductsAndServices/Product/Product')),
  },
  {
    path: 'company/:companyId',
    component: asyncComponent(() => import('../Company/Company')),
  },
  {
    path: 'company/quote/:quoteId',
    component: asyncComponent(() => import('../InvisibleCompany/Company')),
  },
  {
    path: 'provider/:companyId',
    component: asyncComponent(() => import('../Provider/Provider')),
  },
  {
    path: 'provider/products-and-services/:productId',
    component: asyncComponent(() => import('../Provider/ProductsAndServices/Product/Product')),
  },
  {
    path: 'company-profile',
    component: asyncComponent(() => import('../CompanyProfile/CompanyProfile')),
  },
  {
    path: 'providers',
    component: asyncComponent(() => import('../Companies/Companies')),
  },
  {
    path: 'product-detail/:productId',
    component: asyncComponent(() => import('../ProductDetail/ProductDetail')),
  },
  {
    path: 'changePassword',
    component: asyncComponent(() => import('../ChangePassword/ChangePassword')),
  },
  {
    path: 'market-research',
    component: asyncComponent(() => import('../MarketResearch/MarketResearch')),
  },

  {
    path: 'market-research/companies/:mrId',
    component: asyncComponent(() => import('../Companies/Companies')),
  },

  {
    path: 'market-research/providers/category',
    component: asyncComponent(() => import('../MyProviders/CategoryView/CategoryView')),
  },

  {
    path: 'lead-flow',
    component: asyncComponent(() => import('../LeadFlow/LeadFlow')),
  },
  {
    path: 'welcome',
    component: asyncComponent(() => import('../Welcome/Welcome')),
  },
  {
    path: 'lead-flow/quotes/:quoteId',
    component: asyncComponent(() => import('../QuickQuoteDetail/QuickQuoteDetail')),
  },
  {
    path: 'lead-flow/quotes',
    component: asyncComponent(() => import('../Quotes/Quotes')),
  },
  {
    path: 'market-research/providers/categories',
    exact: true,
    component: asyncComponent(() => import('../MyProviders/MyProviders')),
  },
  {
    path: 'market-research/providers/category/:categoryId(most-used|providers-in-top-companies|top)',
    exact: true,
    component: asyncComponent(() => import('../Companies/Companies')),
  },
  {
    path:
      'market-research/providers/:mrId(providers-all|distributors-all|my-competitors|partners|[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12})',
    exact: true,
    component: asyncComponent(() => import('../Companies/Companies')),
  },
  {
    path: 'market-research/providers/all',
    component: asyncComponent(() => import('../Provider/AllProviders/AllProviders')),
  },
  {
    path: 'impersonate',
    component: asyncComponent(() => import('../Companies/Companies')),
  },
];

const routesHr = [
  {
    path: '',
    component: asyncComponent(() => import('../dashboard')),
  },
  {
    path: 'bundles',
    component: asyncComponent(() => import('../Bundles/Bundles')),
  },
  {
    path: 'bundles/:id',
    component: asyncComponent(() => import('../BundleDetail/BundleDetail')),
  },
  {
    path: 'covid-bundles',
    component: asyncComponent(() => import('../CovidBundleDetail/CovidBundleDetail')),
  },
  {
    path: 'dei-bundles',
    component: asyncComponent(() => import('../DeiBundleDetail/DeiBundleDetail')),
  },
  {
    path: 'home',
    component: asyncComponent(() => import('../Home/Home')),
  },
  {
    path: 'integrity-bundles',
    component: asyncComponent(() => import('../IntBundleDetail/IntBundleDetail')),
  },
  {
    path: 'company-report',
    component: asyncComponent(() => import('../Report/Report')),
  },
  {
    path: 'my-company',
    component: asyncComponent(() => import('../Company/Company')),
  },
  {
    path: 'content-packs',
    component: asyncComponent(() => import('../ContentPacks/ContentPacks')),
  },
  {
    path: 'content-packs/pack/:packId',
    component: asyncComponent(() => import('../ContentPack/ContentPack')),
  },
  {
    path: 'content-packs/templates/:templateId',
    component: asyncComponent(() => import('../TemplatePack/TemplatePack')),
  },
  {
    path: 'content-packs/settings/:packId',
    component: asyncComponent(() => import('../PackSettings/PackSettings')),
  },
  {
    path: 'content-packs/create-pack',
    component: asyncComponent(() => import('../PackCreate/PackCreate')),
  },
  {
    path: 'content-packs/pack/:packId/company/:companyId',
    component: asyncComponent(() => import('../ContentPackCompany/ContentPackCompany')),
  },
  {
    path: 'content-packs/pack/:packId/company/:companyId/question/:questionId',
    component: asyncComponent(() => import('../PackCompanyQuestion/PackCompanyQuestion')),
  },
  {
    path: 'score/:scoreId',
    component: asyncComponent(() => import('../Company/Score/Score')),
  },
  {
    path: 'score-custom/:scoreId',
    component: asyncComponent(() => import('../Company/ScoreCustom/ScoreCustom')),
  },
  {
    path: 'score-ag/:scoreId',
    component: asyncComponent(() => import('../Company/ScoreEmpty/ScoreEmpty')),
  },
  {
    path: 'sub-score/:scoreId',
    component: asyncComponent(() => import('../Company/SubScore/SubScore')),
  },
  {
    path: 'risk-ag/:companyId',
    component: asyncComponent(() => import('../RiskEmpty/RiskEmpty')),
  },
  {
    path: 'risk/:companyId',
    component: asyncComponent(() => import('../Risk/Risk')),
  },
  {
    path: 'analysis-recommendation/:analysisId',
    component: asyncComponent(() => import('../Analysis/Analysis')),
  },
  {
    path: 'swot-detail/:swotDetailId',
    component: asyncComponent(() => import('../SwotItem/SwotItem')),
  },
  {
    path: 'help',
    component: asyncComponent(() => import('../Help/Help')),
  },
  {
    path: 'report-pdf-view/:companyId',
    component: asyncComponent(() => import('../PrintView/PrintView')),
  },
  {
    path: 'providers/all',
    component: asyncComponent(() => import('../Provider/AllProviders/AllProviders')),
  },
  {
    path: 'providers/categories',
    component: asyncComponent(() => import('../MyProviders/MyProviders')),
  },
  {
    path: 'risk-preview/:companyId',
    component: asyncComponent(() => import('../RiskPreview/RiskPreview')),
  },
  {
    path: 'risk-custom/:companyId',
    component: asyncComponent(() => import('../RiskCustom/RiskCustom')),
  },
  {
    path: 'requests',
    component: asyncComponent(() => import('../Requests/Requests')),
  },
  {
    path: 'company/:companyId',
    component: asyncComponent(() => import('../Company/Company')),
  },
  {
    path: 'provider/:companyId',
    component: asyncComponent(() => import('../Provider/Provider')),
  },
  {
    path: 'provider/products-and-services/:productId',
    component: asyncComponent(() => import('../Provider/ProductsAndServices/Product/Product')),
  },
  {
    path: 'benchmark',
    component: asyncComponent(() => import('../Companies/Companies')),
  },
  {
    path: 'aniline-benchmark',
    component: asyncComponent(() => import('../Benchmark/Benchmark')),
  },
  {
    path: 'market-research/companies/:mrId',
    component: asyncComponent(() => import('../Companies/Companies')),
  },
  {
    path: 'impersonate',
    component: asyncComponent(() => import('../Companies/Companies')),
  },
  {
    path: 'welcome',
    component: asyncComponent(() => import('../Welcome/Welcome')),
  },
  {
    path: 'requests/quotes',
    component: asyncComponent(() => import('../Quotes/Quotes')),
  },
  {
    path: 'requests/quick-quotes',
    component: asyncComponent(() => import('../QuickQuote/QuickQuote')),
  },
  {
    path: 'lead-flow/quotes/:quoteId',
    component: asyncComponent(() => import('../QuickQuoteDetail/QuickQuoteDetail')),
  },
  {
    path: 'requests/quotes/:quoteId',
    component: asyncComponent(() => import('../QuickQuoteDetail/QuickQuoteDetail')),
  },
  {
    path: 'providers/category',
    component: asyncComponent(() => import('../MyProviders/CategoryView/CategoryView')),
  },
  {
    path: 'providers/category/:categoryId',
    component: asyncComponent(() => import('../Companies/Companies')),
  },
];

const routesWebex = [
  {
    path: 'company/:companyId',
    component: asyncComponent(() => import('../Company/Company')),
  },
  {
    path: 'aniline-benchmark',
    component: asyncComponent(() => import('../Benchmark/Benchmark')),
  },
  {
    path: 'provider/:companyId',
    component: asyncComponent(() => import('../Provider/Provider')),
  },
  {
    path: 'score/:scoreId',
    component: asyncComponent(() => import('../Company/Score/Score')),
  },
  {
    path: 'benchmark',
    component: asyncComponent(() => import('../Companies/Companies')),
  },
  {
    path: 'webex-welcome',
    component: asyncComponent(() => import('../WebexSplash/WebexSplash')),
  },
  {
    path: 'webex-help',
    component: asyncComponent(() => import('../WebexHelp/WebexHelp')),
  },
];

const AppRouter = props => {
  const SIGN_IN_ROUTE = '/signin';
  const { idToken, claims } = useSelector(state => state.Auth);
  const { role } = claims || {};
  const isLoggedIn = idToken !== null;
  const { style } = props;
  const location = useLocation();
  const history = useHistory();
  const { search, pathname } = location;
  const { type, ...rest } = qs.parse(search);
  rest.pathname = pathname;
  const url = Object.assign(rest, { type });

  const [counter, setCounter] = React.useState(20);

  React.useEffect(() => {
    if (counter > 0 && role === 'webex') {
      setTimeout(() => setCounter(counter - 1), 1000);
    }

    if (counter === 0 && role === 'webex') {
      const isUserExist = localStorage.getItem('isUserExist');

      if (isUserExist === 'false') {
        history.push({
          pathname: '/webex-welcome',
        });
      }
    }
  }, [counter]);

  if (!isLoggedIn) {
    if (browser.os === 'Android OS') {
      return (
        <Redirect
          to={{
            pathname: '/webex',
            search: qs.stringify(url),
          }}
        />
      );
    }
    switch (browser && browser.name) {
      case 'chrome':
      case 'opera':
      case 'firefox':
      case 'safari':
      case 'ios':
      case 'edge':
        return (
          <Redirect
            to={{
              pathname: SIGN_IN_ROUTE,
              search: qs.stringify(url),
            }}
          />
        );

      default:
        return (
          <Redirect
            to={{
              pathname: '/webex',
              search: qs.stringify(url),
            }}
          />
        );
    }
  }

  let routes;

  switch (role) {
    case 'hr':
      routes = routesHr;
      break;
    case 'webex':
      routes = routesWebex;
      break;
    default:
      routes = routesProvider;
  }

  return (
    <div style={style}>
      {routes.map(singleRoute => {
        const { path, exact, ...otherProps } = singleRoute;
        return <Route exact={exact !== false} key={path} path={`/${path}`} {...otherProps} />;
      })}
    </div>
  );
};

export default AppRouter;
