import { all, takeEvery, put } from 'redux-saga/effects';
import queryString from 'query-string';
import actions from './actions';
import { callApi } from '../../helpers/api';

export function* load(data) {
  try {
    const { payload } = data;
    const { page, pageSize, query, column, order, type, customerOf, companyId } = payload;

    const params = {
      page,
      pageSize,
      query,
      column,
      order,
      type,
      customerOf,
      products: type !== 'broker',
    };

    if (order) {
      params.order = order;
    }

    const queryStr = queryString.stringify(params);

    const response = yield callApi(`companies/brokers-products/${companyId}?${queryStr || ''}`, 'GET');

    if (response.status === 'success') {
      const { data } = response;

      yield put(
        actions.loadSuccess({
          data,
        }),
      );
    } else if (response.status === 'error') {
      const { error } = response.data;

      yield put(actions.loadError(error));
    }
  } catch (error) {
    console.warn(error);
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD, load)]);
}
