import actions from './actions';
import { createReducer } from '../../helpers/reducer';

// ------------------------------
// Initial State
// ------------------------------
const initState = {
  company: null,
  error: '',
};

// ------------------------------
// Action Handlers
// ------------------------------
function onLoadCompanySuccess(state, { payload }) {
  const { data } = payload;

  return {
    ...state,
    company: data,
  };
}

// ------------------------------
// Exported reducer function.
// ------------------------------
export default createReducer(initState, {
  [actions.LOAD_COMPANY_SUCCESS]: onLoadCompanySuccess,
});
