import actions from './actions';
import { createReducer } from '../../helpers/reducer';

// ------------------------------
// Initial State
// ------------------------------
const initState = {
  users: [],
  count: 0,
  error: '',
  loading: false,
};

// ------------------------------
// Action Handlers
// ------------------------------
function onLoadUsersSuccess(state, { payload }) {
  const { count, users } = payload;

  return {
    ...state,
    users,
    count,
    error: '',
    loading: false,
  };
}

function onLoadUsersError(state, { payload }) {
  return {
    ...state,
    error: payload.error,
    isLoading: false,
  };
}

function onLoadUsers(state) {
  return {
    ...state,
    error: '',
    loading: true,
  };
}

// ------------------------------
// Exported reducer function.
// ------------------------------
export default createReducer(initState, {
  [actions.LOAD_USERS_SUCCESS]: onLoadUsersSuccess,
  [actions.LOAD_USERS_ERROR]: onLoadUsersError,
  [actions.USERS_REQUEST]: onLoadUsers,
});
