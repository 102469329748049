const COMPONENT = 'ANALYSIS';

const actions = {
  LOAD_REQUEST: `${COMPONENT}_LOAD_REQUEST`,
  LOAD_SUCCESS: `${COMPONENT}_LOAD_SUCCESS`,
  LOAD_ERROR: `${COMPONENT}_LOAD_ERROR`,

  CLEAR: `${COMPONENT}_CLEAR`,

  loadRequest: data => ({
    type: actions.LOAD_REQUEST,
    payload: data,
  }),
  loadSuccess: data => ({
    type: actions.LOAD_SUCCESS,
    payload: data,
  }),
  loadError: data => ({
    type: actions.LOAD_ERROR,
    payload: data,
  }),

  clear: () => ({
    type: actions.CLEAR,
  }),
};
export default actions;
