const COMPONENT = 'INT_BUNDLE_DETAIL';

const actions = {
  LOAD_PRODUCTS_REQUEST: `${COMPONENT}LOAD_PRODUCTS_REQUEST`,
  LOAD_PRODUCTS_SUCCESS: `${COMPONENT}LOAD_PRODUCTS_SUCCESS`,
  LOAD_BUNDLES_REQUEST: `${COMPONENT}LOAD_BUNDLES_REQUEST`,
  LOAD_BUNDLES_SUCCESS: `${COMPONENT}LOAD_BUNDLES_SUCCESS`,
  SET_FILTER: `${COMPONENT}SET_FILTER`,
  CLEAR: `${COMPONENT}CLEAR`,
  LOAD_ERROR: `${COMPONENT}_LOAD_ERROR`,
  SET_BUNDLE: `${COMPONENT}_SET_BUNDLE`,

  loadBundlesRequest: data => ({
    type: actions.LOAD_BUNDLES_REQUEST,
    payload: data,
  }),

  loadBundlesSuccess: data => ({
    type: actions.LOAD_BUNDLES_SUCCESS,
    payload: data,
  }),

  loadProductsRequest: data => ({
    type: actions.LOAD_PRODUCTS_REQUEST,
    payload: data,
  }),

  loadProductsSuccess: data => ({
    type: actions.LOAD_PRODUCTS_SUCCESS,
    payload: data,
  }),

  setBundle: data => ({
    type: actions.SET_BUNDLE,
    payload: data,
  }),

  setFilter: data => ({
    type: actions.SET_FILTER,
    payload: data,
  }),

  loadError: data => ({
    type: actions.LOAD_ERROR,
    payload: data,
  }),

  clear: () => ({
    type: actions.CLEAR,
  }),
};
export default actions;
