import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import imgCons from './image/construction.png';
import { firebase } from './redux/firestore';
import { clearAuthData } from './helpers/auth';

const Container = styled.div`
  padding-top: 32px;
  color: rgb(18, 20, 21);
  font-family: Rubik, sans-serif;
  .main-error-page {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 800px;
    .error-title {
      text-align: center;
    }
  }
  .error-subtitle {
    margin-bottom: 1rem;
  }
  li,
  p {
    color: rgb(18, 20, 21);
    font-family: Rubik, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.6px;
    margin-bottom: 1rem;
    span,
    a {
      color: #4787fe;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  a {
    &:hover {
      text-decoration: underline;
    }
  }
  .ant-btn {
    margin-left: 10px;
    border-radius: 3px;
    background-color: #4787fe;
    border: 0;
    outline: 0;
    padding: 9px 20px;
    height: auto;
    &:hover,
    &:active,
    &:focus {
      opacity: 0.9;
      background-color: #4787fe;
    }

    span {
      font-family: 'Rubik', sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      letter-spacing: 0.5px;
      color: #ffffff;
    }
  }
`;

const ErrorFallback = () => {
  const onLogout = () => {
    firebase.auth().signOut();
    clearAuthData();
    window.location.replace('/');
  };

  return (
    <Container>
      <div className="main-error-page">
        <img src={imgCons} alt="construction" style={{ width: '100%', maxWidth: '400px' }} />
        <h1 className="error-title">
          Woops! <br />
          Something went wrong :(
        </h1>
        <h2 className="error-subtitle">We recommend trying the following steps:</h2>
        <ol>
          <li>
            Refresh the page: Sometimes, a simple page&nbsp;
            <span tabIndex={0} role="button" onClick={() => window.location.reload()}>
              refresh
            </span>
            &nbsp; can resolve minor issues.
          </li>
          <li>
            Clear browser cache: Clearing your browser&apos;s cache can help eliminate any stored data that might be
            causing the problem.
          </li>
          <li>Check your internet connection: Make sure you have a stable internet connection.</li>
          <li>
            Try a different browser or device: Sometimes, the issue may be browser-specific, so attempting to access the
            website or application using a different browser or device can help diagnose the problem.
          </li>
          <li>
            If the problem persists,&nbsp;
            <span tabIndex={0} role="button" onClick={onLogout}>
              log out
            </span>
            &nbsp; of your account and log back in.
          </li>
        </ol>
        <p>
          If none of these steps work, you may need to contact the support team{' '}
          <a href="mailto:support@aniline.io">support@aniline.io</a> for further assistance.
        </p>

        <Button onClick={() => window.location.replace('/my-company')}>Try Home page</Button>
      </div>
    </Container>
  );
};

export default ErrorFallback;
