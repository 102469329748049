import actions from './actions';
import { createReducer } from '../../../helpers/reducer';

// ------------------------------
// Initial State
// ------------------------------
const initState = {
  risks: null,
  reviews: null,
  total: undefined,
  loading: false,
};

// ------------------------------
// Action Handlers
// ------------------------------
function onLoadRiskRequest(state) {
  return {
    ...state,
    loading: true,
  };
}

function onLoadRiskSuccess(state, { payload }) {
  const { reviews, total } = payload;

  return {
    ...state,
    reviews,
    total,
    loading: false,
  };
}

function onSwitchChartView(state, { payload }) {
  const { historicalChartView } = payload;

  return {
    ...state,
    historicalChartView,
  };
}

function onLoadTimelineSuccess(state, { payload }) {
  const { risks } = payload;

  return {
    ...state,
    risks,
  };
}

function onLoadError(state) {
  return {
    ...state,
    isLoading: false,
  };
}

function onClear() {
  return {
    initState,
  };
}

// ------------------------------
// Exported reducer function.
// ------------------------------
export default createReducer(initState, {
  [actions.LOAD_RISK_REQUEST]: onLoadRiskRequest,
  [actions.LOAD_RISK_SUCCESS]: onLoadRiskSuccess,
  [actions.LOAD_TIMELINE_SUCCESS]: onLoadTimelineSuccess,
  [actions.LOAD_ERROR]: onLoadError,
  [actions.SWITCH_CHART_VIEW]: onSwitchChartView,
  [actions.CLEAR]: onClear,
});
