import actions from './actions';
import { createReducer } from '../../../../helpers/reducer';

// ------------------------------
// Initial State
// ------------------------------
const initState = {
  imagelink: null,
  productname: null,
  categoryid: undefined,
  categoryname: undefined,
  subcategoryname: undefined,
  selectIsTouched: false,
  keyword: null,
  keywords: [],
  defaultKeywords: [],
  heading: null,
  description: null,
  modal: false,
  imageIsVisible: false,
  isEditable: false,
  error: '',
  loading: false,
  progress: 0,
  fileIsUploading: false,
  fileIsUploaded: false,
  files: [],
  videos: [],
  deletedVideos: [],
  deletedFiles: [],
  videoUrl: null,
  attachmentError: null,
  videoError: null,
  carrier: {
    companyid: null,
    companyname: null,
    companylogo: null,
  },
};

// ------------------------------
// Action Handlers
// ------------------------------
function onLoadProductSuccess(state, { payload }) {
  const { data } = payload;
  console.log('data', data);
  const { imagelink, categoryid, categoryname, subcategoryname, productname, keywords, heading, description, carrier } = data;
  const { companyid, companyname, companylogo } = carrier;

  return {
    ...state,
    imagelink,
    productname,
    categoryid,
    categoryname,
    subcategoryname,
    keyword: null,
    keywords,
    heading,
    description,
    error: '',
    loading: false,
    carrier: {
      companyid,
      companyname,
      companylogo,
    },
  };
}

function onLoadProductError(state, { payload }) {
  return {
    ...state,
    error: payload.error,
    loading: false,
  };
}

function onLoadProduct(state) {
  return {
    ...state,
    error: '',
    loading: true,
  };
}

function onChangeProperty(state, { payload }) {
  const { attr, value, field } = payload;

  if (field) {
    state[field][attr] = value;
  } else {
    state[attr] = value;
  }

  return { ...state };
}

function onClearServiceState(state) {
  return {
    ...state,
    imagelink: null,
    productname: null,
    categoryid: undefined,
    categoryname: undefined,
    subcategoryname: undefined,
    selectIsTouched: false,
    keyword: null,
    keywords: [],
    defaultKeywords: [],
    heading: null,
    description: null,
    error: '',
    attachmentError: null,
    videoError: null,
    loading: false,
    isEditable: false,
    progress: 0,
    fileUploadStarted: false,
    fileIsUploaded: false,
    videoUrl: null,
    files: [],
    videos: [],
    deletedVideos: [],
    deletedFiles: [],
  };
}

function onFileUploading(state) {
  return {
    ...state,
    fileIsUploading: true,
    error: '',
  };
}

function onFileUploadError(state, { payload }) {
  return {
    ...state,
    error: payload.error,
    fileIsUploading: false,
  };
}

function onFileUploadSuccess(state, { payload }) {
  const file = payload;

  return {
    ...state,
    fileIsUploading: false,
    uploaded: true,
    attachmentError: null,
    videoError: null,
    error: '',
    files: [...state.files, file],
  };
}

function onAddVideoLink(state, { payload }) {
  return {
    ...state,
    videos: [...state.videos, payload],
  };
}

function onLoadAttachmentsSuccess(state, { payload }) {
  const { files, videos } = payload;
  const filesData = files || [];
  const videosData = videos || [];

  return {
    ...state,
    files: filesData,
    videos: videosData,
    loading: false,
    uploaded: false,
    attachmentError: null,
    videoError: null,
    videoUrl: null,
    error: '',
  };
}

function onLoadAttachmentsError(state, { payload }) {
  return {
    ...state,
    error: payload.error,
    loading: false,
  };
}

function onLoadAttachment(state) {
  return {
    ...state,
    error: '',
    loading: true,
  };
}

function onDeleteAttachment(state, { payload }) {
  const { attachmentid, index, type } = payload;

  if (type === 'file') {
    const deletedFiles = attachmentid ? [...state.deletedFiles, attachmentid] : state.deletedFiles;
    state.files.splice(index, 1);

    return {
      ...state,
      deletedFiles,
    };
  }

  if (type === 'video') {
    const deletedVideos = attachmentid ? [...state.deletedVideos, attachmentid] : state.deletedVideos;
    state.videos.splice(index, 1);

    return {
      ...state,
      deletedVideos,
    };
  }
}

// ------------------------------
// Exported reducer function.
// ------------------------------
export default createReducer(initState, {
  [actions.LOAD_PRODUCT_SUCCESS]: onLoadProductSuccess,
  [actions.LOAD_PRODUCT_ERROR]: onLoadProductError,
  [actions.LOAD_PRODUCT_REQUEST]: onLoadProduct,
  [actions.CHANGE_PROPERTY]: onChangeProperty,
  [actions.CLEAR_SERVICE_STATE]: onClearServiceState,
  [actions.FILE_UPLOADING]: onFileUploading,
  [actions.FILE_UPLOAD_ERROR]: onFileUploadError,
  [actions.FILE_UPLOAD_SUCCESS]: onFileUploadSuccess,
  [actions.ADD_VIDEO_LINK]: onAddVideoLink,
  [actions.LOAD_ATTACHMENTS_SUCCESS]: onLoadAttachmentsSuccess,
  [actions.LOAD_ATTACHMENTS_ERROR]: onLoadAttachmentsError,
  [actions.LOAD_ATTACHMENTS]: onLoadAttachment,
  [actions.DELETE_ATTACHMENT]: onDeleteAttachment,
});
