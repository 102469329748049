import { all, put, takeEvery, fork } from 'redux-saga/effects';
import moment from 'moment';
import actions from './actions';
import RestApi from '../../helpers/api';

const api = new RestApi('risk-tracker/rt-sentiment-group-item');
const apiTimeline = new RestApi('risk-tracker/rt-timeline');
const apiRiskDate = new RestApi('risk-tracker/rt-item');

export function* loadRiskRequest() {
  yield takeEvery(actions.LOAD_RISK_REQUEST, function*({ payload }) {
    try {
      const { sentimentGroupId, date, companyId, isEarly } = payload;

      const response = yield api.load({
        itemId: sentimentGroupId,
        date: moment(date, 'YYYYMM').format('YYYYMM'),
        companyId,
        isEarly,
      });

      const response1 = yield apiRiskDate.load({
        date: moment(date, 'YYYYMM').format('YYYYMM'),
        companyId,
      });

      if (response.status === 'success') {
        const { cards, historicalOverview, diagrams, diagrams_1, diagrams_6, historicalChartView } = response.data;
        const { isPublished = false } = response1.data;

        yield put(
          actions.loadRiskSuccess({
            cards: cards.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1)),
            historicalOverview,
            lines: diagrams[0].lines,
            diagrams,
            diagrams_1,
            diagrams_6,
            historicalChartView,
            isPublished,
          }),
        );
      } else if (response.status === 'error') {
        console.log('loadRiskRequest response status: error');
      }
    } catch (e) {
      console.log('loadRiskRequest error', e);
    }
  });
}

export function* loadTimelineRequest() {
  yield takeEvery(actions.LOAD_TIMELINE_REQUEST, function*({ payload }) {
    const { companyId } = payload;

    try {
      const response = yield apiTimeline.load({
        companyId,
      });

      if (response.status === 'success') {
        const risks = response.data;

        yield put(
          actions.loadTimelineSuccess({
            risks,
          }),
        );
      } else {
        console.log('loadRiskRequest response', response.error);
      }
    } catch (e) {
      console.log('loadTimelineRequest', e);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(loadRiskRequest), fork(loadTimelineRequest)]);
}
