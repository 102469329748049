import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import moment from 'moment';
import profileActions from './actions';
import { reduxSagaFirebase } from '../../redux/firestore';
import settings from '../../settings';

import RestApi, { callApi } from '../../helpers/api';

const api = new RestApi('risk-tracker/rt-sentiment-group');
const apiRiskDate = new RestApi('risk-tracker/rt-item');
const apiTimeline = new RestApi('risk-tracker/rt-timeline');
const apiCustomTimeline = new RestApi('risk-tracker/crt-timeline');
const apiCRTDateReports = new RestApi('risk-tracker/crt-date-reports');
const apiAnalysisList = new RestApi('chat-gpt/swots-list');

const apiSwot = new RestApi('chat-gpt/company-swot');
const apiCredit = new RestApi('chat-gpt/use-credit');
const apiAnnie = new RestApi('chat-gpt/ask-annie');
const apiDelAnnieChat = new RestApi('chat-gpt/chat-collection');

const apiBuyCredit = new RestApi('packs/set-extra-credits');

export function* sendMyProfileInfo() {
  yield takeEvery(profileActions.SEND_PROFILE_INFO, function*({ payload }) {
    try {
      const { disclaimer, companyId } = payload;

      yield call(
        reduxSagaFirebase.firestore.setDocument,
        `companies_user_data/${companyId}`,
        {
          disclaimer,
        },
        { merge: true },
      );
    } catch (error) {
      console.error('error', error);
    }
  });
}

export function* loadRiskRequest() {
  yield takeEvery(profileActions.LOAD_RISK_REQUEST, function*({ payload }) {
    const { date, companyId, isEarly } = payload;

    try {
      const response = yield api.load({
        date: moment(date, 'YYYYMM').format('YYYYMM'),
        companyId,
      });

      const response1 = yield apiRiskDate.load({
        date: moment(date, 'YYYYMM').format('YYYYMM'),
        companyId,
        isEarlyData: isEarly || false,
      });

      if (response.status === 'success' && response1.status === 'success') {
        const { data } = response1;

        const selectReportSet = (arr, condition) => {
          if (!condition) {
            return arr.filter(el => !el.isEarlyData);
          }

          return arr;
        };

        yield put(
          profileActions.loadRiskSuccess({
            executiveSummary: !data.length ? data.executiveSummary : undefined,
            provReportsList: data.length ? selectReportSet(data, isEarly) : null,
            data: Object.entries(response.data).map(el => ({ ...el[1], id: el[0] })),
          }),
        );
      } else {
        console.log('loadRiskRequest response', response.error);
        console.log('loadRiskRequest response1', response1.error);
      }
    } catch (e) {
      console.log('loadRiskRequest', e);
    }
  });
}

export function* loadCustomListRiskRequest() {
  yield takeEvery(profileActions.LOAD_CUSTOM_LIST_RISK_REQUEST, function*({ payload }) {
    const { date, companyId, isEarly = null } = payload;

    try {
      const response = yield apiCRTDateReports.load({
        date: moment(date, 'YYYYMM').format('YYYYMM'),
        companyId,
      });

      if (response.status === 'success') {
        console.log('response.data ======>', response.data);
        yield put(
          profileActions.loadCustomListRiskSuccess({
            reportsList:
              // eslint-disable-next-line no-nested-ternary
              isEarly && response.data
                ? response.data.filter(el => el.isEarlyData)
                : response.data
                ? response.data.filter(el => !el.isEarlyData)
                : null,
          }),
        );
      } else {
        console.log('loadRiskRequest response', response.error);
      }
    } catch (e) {
      console.log('loadRiskRequest', e);
    }
  });
}

export function* loadTimelineRequest() {
  yield takeEvery(profileActions.LOAD_TIMELINE_REQUEST, function*({ payload }) {
    const { companyId } = payload;

    try {
      const response = yield apiTimeline.load({
        companyId,
      });

      if (response.status === 'success') {
        const risks = response.data;

        yield put(
          profileActions.loadTimelineSuccess({
            risks,
          }),
        );
      } else {
        console.log('loadRiskRequest response', response.error);
      }
    } catch (e) {
      console.log('loadTimelineRequest', e);
    }
  });
}

export function* loadCustomTimelineRequest() {
  yield takeEvery(profileActions.LOAD_CUSTOM_TIMELINE_REQUEST, function*({ payload }) {
    const { companyId } = payload;

    try {
      const response = yield apiCustomTimeline.load({
        companyId,
      });

      if (response.status === 'success') {
        const customRisks = response.data || null;

        yield put(
          profileActions.loadCustomTimelineSuccess({
            customRisks,
          }),
        );
      } else {
        console.log('loadRiskRequest response', response.error);
      }
    } catch (e) {
      console.log('loadTimelineRequest', e);
    }
  });
}

export function* loadAnalysisRequest() {
  yield takeEvery(profileActions.LOAD_ANALYSIS_REQUEST, function*({ payload }) {
    const { companyId } = payload;

    try {
      const response = yield apiAnalysisList.load({
        companyId,
      });

      if (response.status === 'success') {
        yield put(
          profileActions.loadAnalysisSuccess({
            analysis: response.data,
          }),
        );
      } else {
        console.log('loadRiskRequest response', response.error);
      }
    } catch (e) {
      console.log('loadTimelineRequest', e);
    }
  });
}

export function* loadAnnieRequest() {
  yield takeEvery(profileActions.LOAD_ANNIE_REQUEST, function*({ payload }) {
    const { question, companyId, chatId, userId, swotType, swotDetailId } = payload;

    try {
      const response = yield apiAnnie.createItemCustom(`${settings.apiAiUrl}`, {
        question,
        companyId,
        chatId,
        userId,
        swotType,
        swotDetailId,
      });

      // eslint-disable-next-line no-empty
      if (response.status === 'success') {
      } else {
        console.log('loadRiskRequest response', response.error);
      }
    } catch (e) {
      console.log('loadTimelineRequest', e);
    }
  });
}

export function* deleteAnnieChatRequest() {
  yield takeEvery(profileActions.DELETE_ANNIE_CHAT_REQUEST, function*({ payload }) {
    const { chatId, userId } = payload;

    try {
      const response = yield apiDelAnnieChat.deleteItemCustom(`${settings.apiAiUrl}`, {
        chatId,
        userId,
      });

      if (response.status === 'success') {
        console.log(chatId, 'deleted');
      } else {
        console.log('loadRiskRequest response', response.error);
      }
    } catch (e) {
      console.log('loadTimelineRequest', e);
    }
  });
}

export function* loadSwotRequest() {
  yield takeEvery(profileActions.LOAD_SWOT_REQUEST, function*({ payload }) {
    const { companyId, userId } = payload;

    try {
      const response = yield apiSwot.loadCustom(`${settings.apiAiUrl}`, {
        companyId,
        userId,
      });

      if (response.status === 'success') {
        const {
          swotDetails,
          swotRecommendations,
          swotReadiness,
          condition,
          creditsStay,
          creditsLimit,
          isIndexed = false,
        } = response.data;

        yield put(
          profileActions.loadSwotSuccess({
            isIndexed,
            swotDetails,
            swotRecommendations,
            swotReadiness,
            condition,
            creditsStay,
            creditsLimit,
          }),
        );
      } else {
        console.log('loadRiskRequest response', response.error);
      }
    } catch (e) {
      console.log('loadRiskRequest', e);
    }
  });
}

export function* loadCreditRequest() {
  yield takeEvery(profileActions.LOAD_CREDIT_REQUEST, function*({ payload }) {
    const { companyId, userId } = payload;

    try {
      const response = yield apiCredit.createItemCustom(`${settings.apiAiUrl}`, {
        companyId,
        userId,
      });

      if (response.status === 'success') {
        try {
          const response = yield apiSwot.loadCustom(`${settings.apiAiUrl}`, {
            companyId,
            userId,
          });

          if (response.status === 'success') {
            const {
              swotDetails,
              swotRecommendations,
              swotReadiness,
              condition,
              creditsStay,
              creditsLimit,
              isIndexed = false,
            } = response.data;

            yield put(
              profileActions.loadSwotSuccess({
                isIndexed,
                swotDetails,
                swotRecommendations,
                swotReadiness,
                condition,
                creditsStay,
                creditsLimit,
              }),
            );
          } else {
            console.log('loadRiskRequest response', response.error);
          }
        } catch (e) {
          console.log('loadRiskRequest', e);
        }
      } else {
        console.log('loadRiskRequest response', response.error);
      }
    } catch (e) {
      console.log('loadRiskRequest', e);
    }
  });
}

export function* loadBuyCreditRequest() {
  yield takeEvery(profileActions.LOAD_BUY_CREDIT_REQUEST, function*({ payload }) {
    const { companyId, userId, price = 10, count = 5 } = payload;

    try {
      const response = yield apiBuyCredit.createItemCustom(`${settings.apiAiUrl}`, {
        price,
        count,
      });
      // const response = yield callApi('chat-gpt/buy-credits', 'POST', {
      //   companyId,
      //   userId,
      //   price,
      //   count,
      // });

      if (response.status === 'success') {
        try {
          const response = yield apiSwot.loadCustom(`${settings.apiAiUrl}`, {
            companyId,
            userId,
          });

          if (response.status === 'success') {
            yield put(profileActions.loadBuyCreditSuccess());

            const {
              swotDetails,
              swotRecommendations,
              swotReadiness,
              condition,
              creditsStay,
              creditsLimit,
            } = response.data;

            yield put(
              profileActions.loadSwotSuccess({
                swotDetails,
                swotRecommendations,
                swotReadiness,
                condition,
                creditsStay,
                creditsLimit,
              }),
            );
          } else {
            console.log('loadSwotSuccess response', response.error);
          }
        } catch (e) {
          console.log('loadSwotSuccess', e);
        }
      } else {
        console.log('loadBuyCreditRequest response', response.error);
      }
    } catch (e) {
      console.log('loadBuyCreditRequest', e);
    }
  });
}

export function* createSwotRequest() {
  yield takeEvery(profileActions.CREATE_SWOT_REQUEST, function*({ payload }) {
    const { companyId } = payload;

    try {
      const response = yield callApi('chat-gpt/generate-swot', 'POST', {
        companyId,
      });

      if (response.status === 'success') {
        try {
          const response = yield apiAnalysisList.load({
            companyId,
          });

          if (response.status === 'success') {
            yield put(
              profileActions.loadAnalysisSuccess({
                analysis: response.data,
              }),
            );
          } else {
            console.log('loadRiskRequest response', response.error);
          }
        } catch (e) {
          console.log('loadTimelineRequest', e);
        }
      } else {
        console.log('loadRiskRequest response', response.error);
      }
    } catch (e) {
      console.log('loadTimelineRequest', e);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(loadAnalysisRequest),
    fork(loadBuyCreditRequest),
    fork(loadCreditRequest),
    fork(loadSwotRequest),
    fork(createSwotRequest),
    fork(loadAnnieRequest),
    fork(sendMyProfileInfo),
    fork(loadRiskRequest),
    fork(loadTimelineRequest),
    fork(loadCustomTimelineRequest),
    fork(loadCustomListRiskRequest),
    fork(deleteAnnieChatRequest),
  ]);
}
