import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import RestApi from '../../helpers/api';
import { reduxSagaFirebase } from '../../redux/firestore';

const api = new RestApi('companies/my-company-stats');

export function* load() {
  try {
    const response = yield api.load();

    if (response.status === 'success') {
      const { data } = response;

      yield put(actions.loadSuccess(data));
    } else if (response.status === 'error') {
      const { error } = response.data;

      yield put(actions.loadError(error));
    }
  } catch (error) {
    console.warn(error);
  }
}

export function* sendMyProfileInfo(data) {
  try {
    const {
      payload: { marketResearchDisclaimer, companyId },
    } = data;

    yield call(
      reduxSagaFirebase.firestore.setDocument,
      `companies_user_data/${companyId}`,
      {
        marketResearchDisclaimer,
      },
      { merge: true },
    );
  } catch (error) {
    console.error('error', error);
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD, load), takeEvery(actions.SEND_PROFILE_INFO, sendMyProfileInfo)]);
}
