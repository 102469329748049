import actions from './actions';
import { createReducer } from '../../../helpers/reducer';

// ------------------------------
// Initial State
// ------------------------------
const initState = {
  products: null,
  subcategoryid: '',
  productsIds: [],
  page: 1,
  filter: '',
  count: 0,
  error: '',
  loading: false,
  suggestions: [],
};

// ------------------------------
// Action Handlers
// ------------------------------
function onLoadProductsSuccess(state, { payload }) {
  const { count, data, page } = payload;
  const products = page === 1 ? data : [...state.products, ...data];

  return {
    ...state,
    products,
    count,
    error: '',
    loading: false,
  };
}

function onSelect(state, { payload }) {
  const { subcategoryid } = payload;

  return {
    ...state,
    subcategoryid,
  };
}

function onClearState() {
  return { ...initState };
}

function onSetIds(state, { payload }) {
  const { productsIds } = payload;

  return {
    ...state,
    productsIds,
  };
}

function onChangeProperty(state, { payload }) {
  const { filter } = payload;

  return {
    ...state,
    filter,
    subcategoryid: '',
    productsIds: [],
  };
}

function onLoadProductsError(state, { payload }) {
  return {
    ...state,
    error: payload.error,
    isLoading: false,
  };
}

function onLoadProducts(state, { payload }) {
  const { page } = payload;
  return {
    ...state,
    page,
    error: '',
    loading: true,
  };
}

function onLoadSuggestionsSuccess(state, { payload }) {
  const { data } = payload;
  return {
    ...state,
    suggestions: data,
    error: '',
    loading: true,
  };
}

// ------------------------------
// Exported reducer function.
// ------------------------------
export default createReducer(initState, {
  [actions.LOAD_PRODUCTS_SUCCESS]: onLoadProductsSuccess,
  [actions.LOAD_PRODUCTS_ERROR]: onLoadProductsError,
  [actions.LOAD_PRODUCTS_REQUEST]: onLoadProducts,
  [actions.CHANGE_PROPERTY]: onChangeProperty,
  [actions.SELECT]: onSelect,
  [actions.SET_IDS]: onSetIds,
  [actions.CLEAR_STATE]: onClearState,
  [actions.AUTO_SUGGESTIONS_SUCCESS]: onLoadSuggestionsSuccess,
});
