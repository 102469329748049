import { all, put, takeEvery, fork } from 'redux-saga/effects';
import actions from './actions';
import RestApi from '../../helpers/api';

const api = new RestApi('risk-tracker/crt-sentiment-group');
const apiRiskDate = new RestApi('risk-tracker/crt-item');

const apiGroup = new RestApi('risk-tracker/ucrt-sentiment-group');
const apiItem = new RestApi('risk-tracker/ucrt-item');

const separatorKey = '###';
const separatorVal = `<div class="clear-both" />
                <div class="page-break-after" style={{ 'page-break-after': 'always' }} />
                <div class="clear-both" style={{ clear: 'both!important' }} />`;

export function* loadRiskRequest() {
  yield takeEvery(actions.LOAD_RISK_REQUEST, function*({ payload }) {
    const { date, companyId, reportId } = payload;

    try {
      const response = yield api.load({
        date,
        companyId,
        reportId,
      });

      const response1 = yield apiRiskDate.load({
        date,
        companyId,
        reportId,
      });

      if (response.status === 'success' && response1.status === 'success') {
        const { executiveSummary, isPublished, updated, created } = response1.data;

        const dataValues = Object.values(response.data);
        const dataIds = Object.keys(response.data);

        const data = dataValues.map((el, i) => {
          return {
            ...el,
            cards: el.cards.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1)),
            id: dataIds[i],
          };
        });

        yield put(
          actions.loadRiskSuccess({
            data,
            executiveSummary: executiveSummary.replace(separatorKey, separatorVal),
            isPublished,
            updated,
            created,
            isPrivate: false,
          }),
        );
      } else {
        console.log('loadRiskRequest response', response.error);
        console.log('loadRiskRequest response1', response1.error);
      }
    } catch (e) {
      console.log('loadRiskRequest', e);
    }
  });
}

export function* loadUCRTRequest() {
  yield takeEvery(actions.LOAD_UCRT_REQUEST, function*({ payload }) {
    const { reportId, isEarly = null } = payload;

    try {
      const response = yield apiGroup.load({
        reportId,
      });

      const response1 = yield apiItem.load({
        reportId,
      });

      if (response.status === 'success' && response1.status === 'success') {
        const {
          executiveSummary,
          isPublished,
          updated,
          created,
          isPrivate,
          reportName,
          reportDate,
          scores = null,
          scores_alt = null,
        } = response1.data;

        const dataValues = Object.values(response.data);
        const dataIds = Object.keys(response.data);

        const data = dataValues.map((el, i) => {
          return {
            ...el,
            cards: el.cards.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1)),
            id: dataIds[i],
          };
        });

        yield put(
          actions.loadRiskSuccess({
            data,
            executiveSummary: executiveSummary.replace(separatorKey, separatorVal),
            dateScores: isEarly ? scores_alt : scores,
            isPublished,
            updated,
            created,
            isPrivate,
            reportName,
            reportDate,
          }),
        );
      } else {
        console.log('loadRiskRequest response', response.error);
        console.log('loadRiskRequest response1', response1.error);
      }
    } catch (e) {
      console.log('loadRiskRequest', e);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(loadRiskRequest), fork(loadUCRTRequest)]);
}
