import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import { Debounce } from 'react-throttle';
import WindowResizeListener from 'react-window-size-listener';
import { ThemeProvider } from 'styled-components';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import authAction from '../../redux/auth/actions';
import appActions from '../../redux/app/actions';
import Topbar from '../Topbar/Topbar';
import AppRouter from './AppRouter';
import { siteConfig, themeConfig } from '../../settings';
import themes from '../../settings/themes';
import { rrfProps } from '../../redux/store';
import AppHolder from './commonStyle';
import './global.css';

const { Content, Footer } = Layout;
const { logout } = authAction;
const { toggleAll } = appActions;

export class App extends Component {
  chekUrl = pathname => {
    if (pathname.includes('bundles') || pathname.includes('quick-quotes')) {
      return false;
    }
    return true;
  };

  render() {
    const { url } = this.props.match;
    const { height } = this.props;
    const appHeight = window.innerHeight;
    const { pathname } = this.props.history.location;
    return (
      <ThemeProvider theme={themes[themeConfig.theme]}>
        <ReactReduxFirebaseProvider {...rrfProps}>
          <AppHolder>
            <Layout className={this.chekUrl(pathname) ? 'desktop' : ''}>
              <style>
                {`
                // .desktop.ant-layout {
                //   height: ${appHeight}px;
                // }
                @media print {
                  .desktop.ant-layout{height: auto;}
                  }
                `}
              </style>
              <Debounce time="1000" handler="onResize">
                <WindowResizeListener
                  onResize={windowSize => this.props.toggleAll(windowSize.windowWidth, windowSize.windowHeight)}
                />
              </Debounce>
              <Topbar url={url} />
              <Layout style={{ flexDirection: 'row', overflowX: 'hidden' }}>
                <Layout className="isoContentMainLayout">
                  <style>
                    {`
                .isoContentMainLayout {
                  height: ${height}px;
                }
                @media print {
                  .isoContentMainLayout{height: auto;}
                  .isomorphicContent {padding: '0';}
                  }
                `}
                  </style>
                  <Content
                    className="isomorphicContent"
                    style={{
                      flexShrink: '0',
                      padding: '70px 0 0',
                      position: 'relative',
                    }}
                  >
                    <AppRouter url={url} />
                  </Content>

                  <Footer
                    style={{
                      background: '#ffffff',
                      textAlign: 'center',
                      borderTop: '1px solid #ededed',
                    }}
                  >
                    {siteConfig.footerText}
                  </Footer>
                </Layout>
              </Layout>
            </Layout>
          </AppHolder>
        </ReactReduxFirebaseProvider>
      </ThemeProvider>
    );
  }
}

export default connect(
  state => ({
    auth: state.Auth,
    height: state.App.height,
  }),
  { logout, toggleAll },
)(App);
