import actions from './actions';
import { createReducer } from '../../helpers/reducer';

// ------------------------------
// Initial State
// ------------------------------
const initState = {
  swotDetail: null,
  annieAnswer: '',
  annieLoading: 'wait',
};

// ------------------------------
// Action Handlers
// ------------------------------
function onLoadSuccess(state, { payload }) {
  const { swotDetail } = payload;

  return {
    ...state,
    swotDetail,
  };
}

function deletePackRequest(state) {
  return {
    ...state,
  };
}
function deletePackSuccess(state, { payload }) {
  return {
    ...state,
    // annieAnswer,
    // annieLoading: false,
  };
}
function deletePackError(state) {
  return {
    ...state,
  };
}

function onClear() {
  return {
    ...initState,
  };
}

// ------------------------------
// Exported reducer function.
// ------------------------------
export default createReducer(initState, {
  [actions.LOAD_SUCCESS]: onLoadSuccess,

  [actions.DELETE_PACK_REQUEST]: deletePackRequest,
  [actions.DELETE_PACK_SUCCESS]: deletePackSuccess,
  [actions.DELETE_PACK_ERROR]: deletePackError,

  [actions.CLEAR]: onClear,
});
