const COMPONENT = 'BUNDLE_DETAIL';

const actions = {
  LOAD_BUNDLE_REQUEST: `${COMPONENT}LOAD_BUNDLE_REQUEST`,
  LOAD_BUNDLE_SUCCESS: `${COMPONENT}LOAD_BUNDLE_SUCCESS`,
  LOAD_COMPANIES_REQUEST: `${COMPONENT}LOAD_COMPANIES_REQUEST`,
  LOAD_COMPANIES_SUCCESS: `${COMPONENT}LOAD_COMPANIES_SUCCESS`,
  SET_FILTER: `${COMPONENT}SET_FILTER`,
  CLEAR: `${COMPONENT}CLEAR`,
  LOAD_ERROR: `${COMPONENT}_LOAD_ERROR`,

  loadBundleRequest: data => ({
    type: actions.LOAD_BUNDLE_REQUEST,
    payload: data,
  }),

  loadBundleSuccess: data => ({
    type: actions.LOAD_BUNDLE_SUCCESS,
    payload: data,
  }),

  loadCompaniesRequest: data => ({
    type: actions.LOAD_COMPANIES_REQUEST,
    payload: data,
  }),

  loadCompaniesSuccess: data => ({
    type: actions.LOAD_COMPANIES_SUCCESS,
    payload: data,
  }),

  setFilter: data => ({
    type: actions.SET_FILTER,
    payload: data,
  }),

  loadError: data => ({
    type: actions.LOAD_ERROR,
    payload: data,
  }),

  clear: () => ({
    type: actions.CLEAR,
  }),
};
export default actions;
