import actions from './actions';
import { createReducer } from '../../helpers/reducer';

// ------------------------------
// Initial State
// ------------------------------
const initState = {
  swotDetail: null,
  annieAnswer: '',
  annieLoading: 'wait',
};

// ------------------------------
// Action Handlers
// ------------------------------
function onLoadSuccess(state, { payload }) {
  const { swotDetail } = payload;

  return {
    ...state,
    swotDetail,
  };
}

function onLoadError(state) {
  return {
    ...state,
  };
}

function onLoadAnnieRequest(state) {
  return {
    ...state,
    annieLoading: true,
    annieAnswer: '',
  };
}

function onLoadAnnieSuccess(state, { payload }) {
  const { annieAnswer } = payload;

  return {
    ...state,
    annieAnswer,
    annieLoading: false,
  };
}

function onClear() {
  return {
    ...initState,
  };
}

// ------------------------------
// Exported reducer function.
// ------------------------------
export default createReducer(initState, {
  [actions.LOAD_SUCCESS]: onLoadSuccess,
  [actions.LOAD_ERROR]: onLoadError,

  [actions.LOAD_ANNIE_SUCCESS]: onLoadAnnieSuccess,
  [actions.LOAD_ANNIE_REQUEST]: onLoadAnnieRequest,

  [actions.CLEAR]: onClear,
});
