import { all, put, takeEvery, fork } from 'redux-saga/effects';
import { callApi } from '../../helpers/api';

import actions from './actions';

export function* loadRequest() {
  yield takeEvery(actions.LOAD_REQUEST, function*({ payload }) {
    try {
      const result = yield callApi('public/webex/createSignInToken', 'POST', payload);

      if (result.status === 'success') {
        const { token, isUserExist = false } = result;

        localStorage.setItem('isUserExist', `${isUserExist}`);
        yield put(actions.loadSuccess({ token }));
      } else if (result.status === 'error') {
        yield put(actions.loadSuccess({ error: result, token: null }));

        console.log('loadRiskRequest response status: error');
      }
    } catch (e) {
      console.log('loadRiskRequest error', e);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(loadRequest)]);
}
