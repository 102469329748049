const COMPONENT = 'COMPANY_PREVIEW';

const profileActions = {
  LOAD_COMPANY_REQUEST: `${COMPONENT}LOAD_COMPANY_REQUEST`,
  LOAD_COMPANY_SUCCESS: `${COMPONENT}LOAD_COMPANY_SUCCESS`,

  loadCompanyRequest: data => ({
    type: profileActions.LOAD_COMPANY_REQUEST,
    payload: data,
  }),

  loadCompanySuccess: data => ({
    type: profileActions.LOAD_COMPANY_SUCCESS,
    payload: data,
  }),
};
export default profileActions;
