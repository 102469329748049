import { cloneDeep } from 'lodash';
import { createReducer } from '../../helpers/reducer';
import authActions from '../../redux/auth/actions';
import actions from './actions';

// ------------------------------
// Initial State
// ------------------------------
const hr = {
  numberOfEmployees: { value: '' },
  payrollProvider: {
    value: '',
    rating: null,
    allowToShare: true,
  },
  recruitmentProvider: {
    value: '',
    rating: null,
    allowToShare: true,
  },
  hrisProvider: {
    value: '',
    rating: null,
    allowToShare: true,
  },
  prefferedVendors: { value: '' },
};

const provider = {
  numberOfEmployees: { value: '' },
  numberOfCustomers: {
    options: ['0 - 500', '500 - 1000', '1000 - 1500', '> 1500'],
    value: '',
  },
  isMWBE: {
    options: ['Yes', 'No'],
    value: '',
  },
  operateState: { value: '' },
};

const broker = {
  numberOfEmployees: { value: '' },
  numberOfCustomers: {
    options: ['0 - 500', '500 - 1000', '1000 - 1500', '> 1500'],
    value: '',
  },
  isMWBE: {
    options: ['Yes', 'No'],
    value: '',
  },
  operateState: { value: '' },
};

const initState = {
  isTellAboutOpen: false,
  isLeadFlowOpen: false,
  isLearnAboutOpen: false,
  isHrCommunityOpen: false,
  isMarketResearchOpen: false,
  loaded: false,
  tellAboutData: {
    hr: cloneDeep(hr),
    provider: cloneDeep(provider),
    broker: cloneDeep(broker),
  },
};

// ------------------------------
// Action Handlers
// ------------------------------
function onSetWelcomeBlockOpen(state, { payload }) {
  return {
    ...state,
    ...payload,
  };
}

function onChangeTellAboutProperty(state, { payload }) {
  const { attr, value, role, valueType } = payload;
  const currentValueObj = state.tellAboutData[role][attr];

  return {
    ...state,
    tellAboutData: {
      ...state.tellAboutData,
      [role]: {
        ...state.tellAboutData[role],
        [attr]: {
          ...currentValueObj,
          value: valueType === 'main' ? value : currentValueObj.value,
          allowToShare: valueType === 'allowToShare' ? value : currentValueObj?.allowToShare,
          rating: valueType === 'rating' ? value : currentValueObj?.rating,
        },
      },
    },
  };
}

function onSetSurveyData(state, { payload: { role, data } }) {
  return {
    ...state,
    loaded: true,
    tellAboutData: {
      ...state.tellAboutData,
      [role]: {
        ...state.tellAboutData[role],
        ...Object.keys(data).reduce((acc, key) => {
          if (typeof data[key] === 'object') {
            acc[key] = { ...state.tellAboutData[role][key], ...data[key] };
          } else {
            acc[key] = { ...state.tellAboutData[role][key], value: data[key] };
          }

          return acc;
        }, {}),
      },
    },
  };
}

function onClearState() {
  return {
    ...initState,
    tellAboutData: {
      hr: cloneDeep(hr),
      provider: cloneDeep(provider),
      broker: cloneDeep(broker),
    },
  }
}

// ------------------------------
// Exported reducer function.
// ------------------------------
export default createReducer(initState, {
  [actions.SET_WELCOME_BLOCK_OPEN]: onSetWelcomeBlockOpen,
  [actions.CHANGE_TELL_ABOUT_PROPERTY]: onChangeTellAboutProperty,
  [actions.SET_SURVEY_DATA]: onSetSurveyData,
  [authActions.LOGOUT]: () => ({
    ...initState,
    tellAboutData: {
      hr: cloneDeep(hr),
      provider: cloneDeep(provider),
      broker: cloneDeep(broker),
    },
  }),
  [actions.CLEAR_STATE]: onClearState,
});
