import { all, takeEvery, put, fork } from 'redux-saga/effects';
import { callApi } from '../../helpers/api';
import actions from './actions';

export function* signupRequest() {
  yield takeEvery('SIGNUP_REQUEST', function* signupRequest({ payload }) {
    try {
      const result = yield callApi('users/confirm', 'POST', payload);

      if (result.status === 'success') {
        yield put(actions.signupSuccess());
      } else {
        const { error } = result;

        console.log('message', error);

        yield put(actions.signupError({ error }));
      }
    } catch (e) {
      yield put(actions.signupError({ error: e }));
    }
  });
}

export function* getTokenRequest() {
  yield takeEvery(actions.GET_TOKEN_REQUEST, function* getTokenRequest({ payload }) {
    const { token } = payload;
    try {
      const result = yield callApi(`users/verification-data/${token}`, 'GET');

      if (result.status === 'success') {
        const { user, company, alreadySignedUp } = result.data;
        const { customClaims, email, uid } = user;
        const { role } = customClaims;
        const { signupData } = customClaims;
        const parsedSignupData = JSON.parse(signupData);
        const { firstName, lastName } = parsedSignupData;

        yield put(
          actions.getTokenSuccess({
            companyData: company,
            role,
            alreadySignedUp,
            formData: [
              { email },
              { uid },
              { firstName },
              { lastName },
              { companyName: parsedSignupData.company.companyname },
            ],
          }),
        );
      } else {
        const { error } = result;

        console.log('message', error);

        yield put(actions.getTokenError({ error }));
      }
    } catch (e) {
      // yield put(actions.getTokenError({ error: e }));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(signupRequest), fork(getTokenRequest)]);
}
